import React, {useEffect, useRef, useState} from "react";
import {useSubheader} from "@metronic/layout";
import {Alert, Button, Card, Form, Spinner} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import AlertMessage from "@components/AlertMessage";
import * as Yup from "yup";
import {getInputClasses} from "@utils/getInputclasses";
import * as XLSX from "xlsx";
import {uploadExcel} from "../../services/programmingsService";

const Programmings = () => {
  const subheader = useSubheader();
  const [fileError, setFileError] = useState(null);
  const fileInputRef = useRef(null);

  const expectedColumns = [
    "Ramo", "CdD", "Oficina", "SaludWeb", "NumAtencion", "NumFolio",
    "MontoAut-Dictamenes", "NumDictamenes", "Poliza", "NumCertif", "ICD", "Cpt",
    "Segment", "Siniestro", "FechaReg", "Mes", "Anio", "FecIngreso",
    "Procede", "RfcPrest", "Prestador", "RfcMedTrat", "MedTratante", "RfcMedAse",
    "MedAsesor", "TipoAut", "TipoIngreso", "Diagnost", "Procedim", "Asegurado",
    "FecNac", "SubRamo", "EstatusMed", "Obvservac", "Reclamac", "CveAgte",
    "NomAgte", "Telefono", "TipoEspec", "Estado del Prestador", "Contratante", "Días de Hospitalización",
    "fol-llamada", "FechIngreso", "HoraIngr", "cveEstatus", "Estatus", "Centro",
    "TipoPrestador", "TEL_MOVIL_RES", "TEL_FIJO_RES", "TEL_MOVIL_AFECTADO_RES", "TEL_FIJO_AFECTADO_RES",
    "NÚMERO_DE_RECLAMACION", "Regional", "Celula", "Polizas_VIP", "Fecha Vencida", "Poliza Keralty",
    "Llave Mas Care", "Auto. Mas Care", "ID AMBULATORIO", "id Enf", "OBSERVACIONES DICTAMEN",
    "OBSERVACIONES RECLAMACION"
  ];

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
        .required("El campo es requerido")
        .test(
            "fileType",
            "El archivo debe ser un documento Excel (.xls, .xlsx)",
            value => {
              if (!value) {
                return false;
              }
              return (
                  value.type === "application/vnd.ms-excel" ||
                  value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              );
            }
        )
        .test(
            "fileContent",
            "El archivo no contiene las columnas requeridas",
            async value => {
              if (!value) {
                return false;
              }
              return await validateLayoutExcelFile(value);
            }
        )
  });

  useEffect(() => {
    subheader.setTitle("Programaciones")
  }, []);

  const handleSubmitExcel = (values, { setStatus, setSubmitting, resetForm }) => {
    const formData = new FormData();
    formData.append('file', values.file);

    try {
      uploadExcel(formData).then( response => {
        setStatus({success: response.data.status === 200, message: response.data.message});
      })
    } catch (error) {
      setStatus({ success: false, message: error.message });
    }

    fileInputRef.current.value = null;
    resetForm();
    setSubmitting(false);
  }

  const validateLayoutExcelFile = async file => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
      const headers = worksheet[0].map(header => header.trim());
      const missingColumns = expectedColumns.filter(col => !headers.includes(col.trim()));
      if (missingColumns.length > 0) {
        setFileError(`Faltan las siguientes columnas: ${missingColumns.join(", ")}`);
        return false;
      } else {
        setFileError(null);
        return true;
      }
    } catch (error) {
      return false;
    }
  }

  return (
      <>
        <Alert
            variant="success"
            className="text-center"
            style={{ fontSize: "16px" }}
        >
          Programaciones
        </Alert>

        <Card>
          <Card.Body>
            <Formik initialValues={{file: null}}
                    onSubmit={handleSubmitExcel}
                    validationSchema={validationSchema}
                    enableReinitialize={true}>
              {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  status,
                  setFieldValue,
                  isSubmitting
                }) => (
                    <>
                      <AlertMessage {...status} />
                      {fileError && <Alert variant="danger">{fileError}</Alert>}
                      <Form onSubmit={handleSubmit}>
                        <Form.Group>
                          <Form.Label>Selecciona Archivo Excel</Form.Label>
                            <Form.Control
                                type="file"
                                id="file"
                                name="file"
                                label="Seleccionar archivo"
                                ref={fileInputRef}
                                onChange={event => {
                                  setFieldValue("file", event.currentTarget.files[0]);
                                }}
                                className={`${getInputClasses(touched, errors, "file")}`}
                            />

                          <ErrorMessage name="file">
                            { msg => (
                                <Form.Control.Feedback type="invalid">
                                  {msg}
                                </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>

                        <Button variant="primary" type="submit" disabled={isSubmitting}>
                          {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                          Subir documento
                        </Button>
                      </Form>
                    </>
                )}
            </Formik>
          </Card.Body>
        </Card>
      </>
  );
};

export default Programmings;
