import React from "react";
/*import {
  MixedWidget4,
  BaseTablesWidget1,
  BaseTablesWidget2,
  BaseTablesWidget6,
  StatsWidget11,
  StatsWidget10,
  ListsWidget8,
  ListsWidget10,
  ListsWidget14,
  AdvanceTablesWidget9,
} from "../widgets";*/

export function Demo3Dashboard() {
  return (
    <>
      {/* begin::Dashboard */}
      {/* end::Dashboard */}
    </>
  );
}
