import axios from "axios";

export const GET_ALL = "concierges";
export const SAVE = "concierges";
export const UPDATE = "concierges";
export const DELETE = "concierges";

export let getAllConcierges = function (status) {
  return axios.get(`${GET_ALL}`);
}

export let saveConcierge = function (data) {
  return axios.post(SAVE, data);
}

export function updateConcierge(data, id) {
  return axios.put(`${UPDATE}/${id}`, data);
}

export function destroyConcierge(id) {
  return axios.delete(`${DELETE}/${id}`);
}
