/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useJwt } from "react-jwt";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import axios from "axios";

export function Routes() {
    const {isAuthorized, token,userId} = useSelector(
        ({auth, token, userId}) => ({
            isAuthorized: auth.user != null,
            token: auth.authToken,
            userId: auth.user?.idUser,
        }),
        shallowEqual
    );

    const [tokenIsInvalid, setTokenIsInvalid] = useState(false);

    const { isExpired } = useJwt(token);
    if (typeof userId !== "undefined") {
        axios.get("auth/verify", {
            params: {
                token: token,
                userId: userId,
            }
        }).then(response => setTokenIsInvalid(false))
        .catch(err => setTokenIsInvalid(true));
    }

    if  ((isExpired || tokenIsInvalid) && typeof token !== "undefined") {
        localStorage.clear();
        localStorage.setItem('persist:atcplus-auth', '');
        //history.push("/logout");
        return (
            <>
                <Route>
                    <AuthPage />
                </Route>
                <Route path="/error" component={ErrorsPage}/>
                <Route path="/logout" component={Logout}/>

                <Redirect exact to="/logout"/>
                <Redirect exact from="/logout" to="/auth/login"/>
            </>
        );
    }

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
