import React,{useState,useEffect} from "react";
import {useSubheader} from "@metronic/layout";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';
import {getAll, destroy} from "@services/hospitalsService";
import FormHospital from "./FormHospital"

const HospitalsList = (props) => {
    const subheader = useSubheader();

    const initialHospitalState = { hospitalContact: "", hospitalName: "", hospitalPhone: "", hospitalEmail: "", hospitalExt: "", hospitalEst: "" };

    const [hospitals, setHospitals] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [hospital, setHospital] = useState(initialHospitalState);

    useEffect(() => {
		getHospitals();

        subheader.setTitle("Listado de Hospitales");
	}, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const getHospitals = () => getAll().then(data => setHospitals(data.data));
    const handleDeleteHospital = (e, hospitalId) => {
        e.preventDefault();
        destroy(hospitalId).then(response => {
            getHospitals();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStoreHospital = (e) => {
        handleShow();
        setHospital(initialHospitalState);
        setShouldUpdate(false);
    }
    const handleEditHospital = (e, nurse) => {
        e.preventDefault();
        setHospital({ ...nurse });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStoreHospital}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Contacto</th>
                                <th>Telefono</th>
                                <th>Ext</th>
                                <th>Email</th>
                                <th>Estado</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hospitals?.map( (hospital, idx) => (
                                <tr key={idx}>
                                    <td>{hospital.hospitalName}</td>
                                    <td>{hospital.hospitalContact}</td>
                                    <td>{hospital.hospitalPhone}</td>
                                    <td>{hospital.hospitalExt}</td>
                                    <td>{hospital.hospitalEmail}</td>
                                    <td>{hospital.hospitalEst}</td>
                                    <td>
                                        <Button variant="success" onClick={(e) => handleEditHospital(e, hospital)}>Editar</Button>{' '}
                                        <Button variant="danger" onClick={(e) => handleDeleteHospital(e, hospital.id)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormHospital showModal={showModal}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            shouldUpdate={shouldUpdate}
                            getHospitals={getHospitals}
                          hospital={hospital} />
        </>
    );
};

export default HospitalsList;
