import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Table,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import moment from "moment";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { getAllProviders, saveProvider } from "@services/surgeryProvidersService";
import { update } from "@services/hospitalizationcrud";

export const ProvidersData = ({ disabled = true, ...props }) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
            disabled={disabled}
          >
            Proveedores de cirugia
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
          <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentProvidersData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentProvidersData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center"
        style={{ fontSize: "14px" }}
      >
        Proveedores de cirugia
      </Alert>

      <Card>
        <Card.Body>
          <FormProvidersData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormProvidersData = (props) => {
  const initialValues = {
    nameProvider: props.data.nameProvider || "",
    rfcProvider: props.data.rfcProvider || "",
  };

  const { id } = props.data;
  const [proviers, setProviders] = useState([]);
  const [enableForm, setEnableForm] = useState(null);

  const handleSubmitForm = (values, { setStatus, resetForm, setSubmitting }) => {
    values.caseId = id;
    setTimeout(() => {
      saveProvider(values).then(response => {
        getProviders();
        update({ requiresProviders: enableForm ? 'Si' : 'No' }, id);
        resetForm();
      });
      setSubmitting(false);
    }, 1000);
  };

  const getProviders = () => {
    getAllProviders(id).then((response) => setProviders(response.data));
  }

  useEffect(() => {
    if (id) {
      getProviders();
    }

    if (props.data.requiresProviders === "Si") setEnableForm(true);
    else if (props.data.requiresProviders === "No" || props.data.requiresProviders === null) setEnableForm(false);

  
  }, [props.data]);

  const validationSchema = Yup.object().shape({
    nameProvider: Yup.string()
        .required("El campo es requerido"),
        rfcProvider: Yup.string()
      .required("El campo es requerido"),
  });

  return (
    <>
      <Row>
        <Col>
          <Form.Group controlId="enableForm">
            <Form.Label>Solicitud con Proveedor de Cirugia *</Form.Label>
            <div className="mb-3">
              <Form.Check
                custom
                inline
                label="SI"
                type="radio"
                value="Si"
                id="enaleFormSi"
                disabled={props.disabledAll}
                onChange={e => {
                  setEnableForm(true);
                  window.requires_providers = "Si";
                }}
                checked={enableForm === true}
                name="enableForm"
              />
              <Form.Check
                custom
                inline
                label="NO"
                type="radio"
                value="No"
                id="enaleFormNo"
                disabled={props.disabledAll}
                onChange={e => {
                  setEnableForm(false);
                  window.requires_providers = "No";
                }}
                checked={enableForm === false}
                name="enableForm"
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <i className="fa fa-question-circle h2 mr-2"></i>Agregar solo proveedores que hayan intervenido en procedimientos de cardiologia, urologia y ortopedia.
        </Col>
      </Row>

      {enableForm && <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
          submitForm,
          setFieldValue,
        }) => {
          return (
            <>
              <AlertError status={status} />
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="nameProvider">
                      <Form.Label>Nombre del proveedor *</Form.Label>
                      <Form.Control
                        type="nameProvider"
                        name="nameProvider"
                        value={values.nameProvider}
                        onChange={handleChange}
                        disabled={props.disabledAll}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "nameProvider"
                        )}`}
                      />
                      <ErrorMessage name="nameProvider">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="rfcProvider">
                      <Form.Label>RFC Proveedor </Form.Label>
                      <Form.Control
                        type="text"
                        name="rfcProvider"
                        value={values.rfcProvider}
                        onChange={handleChange}
                        disabled={props.disabledAll}
                        autoComplete="off"
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "rfcProvider"
                        )}`}
                      />
                      <ErrorMessage name="rfcProvider">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik> }

      <Table responsive striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Nombre del proveedor</th>
            <th>RFC</th>
            <th>Fecha Hora (DD/MM/AAAA HH:MM:SS)</th>
          </tr>
        </thead>
        <tbody>
          {proviers?.map( (provider, idx) => (
            <tr key={idx} hidden={provider.comment === ""}>
              <td>{provider.nameProvider}</td>
              <td>{provider.rfcProvider}</td>
              <td>{moment(provider.createdAt).format('DD/MM/YYYY HH:mm:ss A')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
