import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import GeneralFilter from "@components/GeneralFilter";
import Pagination from "react-js-pagination";
import { getCasesByStatuses } from "@services/casesService";
import GeneralTable from "@components/GeneralTable";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const CostControl = () => {
  window.currModule = "Control de Costos";
  window.assignationColumn = "feaccostos";

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    var queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    getCasesByStatuses([6], pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);

      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);

    subheader.setTitle("Control de Costos")
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Control de Costos
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[6]}
          hideInsurance={true}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/control_costos_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideAxaCategoryColumn={false}
        hidePatientStatusColumn={true}
        hideCelulaColumn ={false}
        reason="Dictum"
        reasonTitle="Estatus Dictamen"
        assignationColumn="feaccostos"
        currModule="Control de Costos"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default CostControl;
