import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner} from "react-bootstrap";
import {save, update} from "@services/rolesCrud"
import {getAll as getAllPermissions} from "@services/permissionsCrud"
import {getInputClasses} from "@utils/getInputclasses"

import AlertError from "@components/AlertError"

const FormRole = (props) => {
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimo 3 caracteres")
            .max(20, "Maximo 20 caracteres")
            .required("El campo es requerido"),
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        if (values.privilegesChecked) {
            values.privileges = values.privilegesChecked.map( privilege => {
                return {
                    idPermission: privilege
                }
            });
        }

        setTimeout(() => {
            if (props.shouldUpdate) {
                update(values).then(response => {
                    props.getRoles();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al actualizar");
                });
            } else {
                save(values).then(response => {
                    props.getRoles();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al guardar");
                });
            }
        }, 1000);
    };

    const [privileges, setPrivileges] = useState([]);

    useEffect(() => {
        getAllPermissions().then(data => setPrivileges(data.data));
    }, []);

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.shouldUpdate ? "Editar" : "Agregar"} role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{...props.role}} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleSubmit, status, isSubmitting}) => (
                            <>
                                <AlertError status={status} />
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    placeholder="Escribe el nombre"
                                                    className={`${getInputClasses(touched, errors, "name")}`} />
                                            <ErrorMessage name="name">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="privilegesChecked">
                                        <div role="group" aria-labelledby="checkbox-group">
                                            {privileges?.map( (privilege, idx) => (
                                                <label key={idx} style={{ "marginRight": "15px" }}>
                                                    <Field type="checkbox"
                                                        style={{ "marginRight": "2px" }}
                                                        name="privilegesChecked"
                                                        value={privilege.idPermission.toString()}
                                                        className={`${getInputClasses(touched, errors, "privilegesChecked")}`} />
                                                    {privilege.name}
                                                </label>
                                            ))}
                                        </div>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                                        Guardar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FormRole;