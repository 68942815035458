import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import {Link} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  userName: "",
  password: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const LoginSchema = Yup.object().shape({
    userName: Yup.string()
      .min(3, "Minimo 3 caracteres")
      .max(50, "Maximo 50 caracteres")
      .required("El campo es requerido"),
    password: Yup.string()
      .min(3, "Minimo 3 caracteres")
      .max(50, "Maximo 50 caracteres")
      .required("El campo es requerido"),
  });


  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.userName, values.password)
          .then(({ data: { token } }) => {
            let accessToken = token;
            disableLoading();
            props.login(accessToken);
          })
          .catch( err => {
            disableLoading();
            setSubmitting(false);
            setStatus(err.response?.data?.message || 'Ocurrio algun error');
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          Iniciar Sesion
        </h3>
        <p className="text-muted font-weight-bold">
          Ingresa tu usuario y password.
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
        ) : (
            <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
              <div className="alert-text ">
                Usa tu cuenta para continuar.
              </div>
            </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
              placeholder="Nombre de usuario"
              type="userName"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "userName"
              )}`}
              name="userName"
              {...formik.getFieldProps("userName")}
          />
          {formik.touched.userName && formik.errors.userName ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.userName}</div>
              </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
          ) : null}
        </div>

        <div className="form-group fv-plugins-icon-container">
          <div>
            <a href="#!" className="text-muted" onClick={handleShowModal}>
              ¿Olvidaste tu contraseña?
            </a>
          </div>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Ingresar</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}

      {/*Modal forgot password*/}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recuperar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Contenido del modal, puede ser un formulario para recuperar la contraseña */}
          <p>Su cuenta ha sido bloqueada por seguridad ingrese su email y recibirá un correo de restablecimiento.</p>
          <input type="email" placeholder="Email" className="form-control" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(null, auth.actions)(Login);
