import React from "react"

export default function AlertError(props) {
    return (
        <>
            {props.status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{props.status}</div>
                </div>
            ) : null}
        </>
    );
}