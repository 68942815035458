import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";
import { getCasesByStatuses } from "@services/casesService";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const MedicalInformation = () => {
  window.currModule = "Informacion Medica";
  window.assignationColumn = "feaimedica";

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    var queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    getCasesByStatuses([4], pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    subheader.setTitle("INFORMACIÓN MÉDICA");

    getAllCasesByStatus(paramsPagination.activePage);
    
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        INFORMACIÓN MÉDICA
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[4]}
          hideAxaCategoryId={true}
          hideCategoryVip={true}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/informacion_medica_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hideAxaCategoryColumn={true}
        hideHospitalDateTimeHireColumn={true}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        hidePolicyTypeColumn={true}
        reason="Dictum"
        reason2="Dictum"
        currModule="Informacion Medica"
        statusTitle="Estatus Mesa de Control"
        reasonTitle="Estatus Dictamen"
        assignationColumn="feaimedica"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default MedicalInformation;
