import React,{useState,useEffect} from "react";
import {useSubheader} from "@metronic/layout";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';
import {getAll, destroy} from "@services/nursesService";
import FormNurse from "./FormNurse"

const NursesList = (props) => {
    const subheader = useSubheader();

    const initialNurseState = { nurseName: "", nurseFatherLastname: "", nurseMotherLastname: "", fullName: "", category: "" };

    const [nurses, setNurses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [nurse, setNurse] = useState(initialNurseState);

    useEffect(() => {
		getNurses();

        subheader.setTitle("Listado de Enfermeras");
	}, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const getNurses = () => getAll().then(data => setNurses(data.data));
    const handleDeleteNurse = (e, nurseId) => {
        e.preventDefault();
        destroy(nurseId).then(response => {
            getNurses();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStoreNurse = (e) => {
        handleShow();
        setNurse(initialNurseState);
        setShouldUpdate(false);
    }
    const handleEditNurse = (e, nurse) => {
        e.preventDefault();
        setNurse({ ...nurse });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStoreNurse}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Categoria</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {nurses?.map( (nurse, idx) => (
                                <tr key={idx}>
                                    <td>{nurse.fullName}</td>
                                    <td>{nurse.category}</td>
                                    <td>
                                        <Button variant="success" onClick={(e) => handleEditNurse(e, nurse)}>Editar</Button>{' '}
                                        <Button variant="danger" onClick={(e) => handleDeleteNurse(e, nurse.id)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormNurse showModal={showModal}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            shouldUpdate={shouldUpdate}
                            getNurses={getNurses}
                            nurse={nurse} />
        </>
    );
};

export default NursesList;