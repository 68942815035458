import axios from "axios";

export const SUFFERINGS = "sufferings";

export const storeSuffering = (data) => {
  return axios.post(`${SUFFERINGS}`, data);
}

export const getSufferingsByCaseId = (caseId) => {
  return axios.get(`${SUFFERINGS}/${caseId}`);
}
