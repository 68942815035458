import React from "react";
import {
  Alert,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";

export const InsuranceData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos Aseguradora
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentInsuranceData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentInsuranceData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Datos Aseguradora

      </Alert>

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <b>Categoria AXA (Mesa de Control): </b>{props.data.axaCategories}
                  <br />
                  <b>Enfermera (o) Asignado (o): </b>{props.data.nurse?.fullName}
                  <br />
                  <b>Medico Asignado: </b>{props.data.doctor?.fullName}
                  <br />
                  <b>Cedula: </b>{props.data.cell?.cellDescription}
                  <br />
                  <b>Concierge Asignado: </b>{props.data.concierge?.conciergeName}
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};