import axios from "axios";
import {SAVE} from "./hospitalizationcrud";

export const PROGRAMMINGS = "programmings"
export const GET_PROGRAMMING_SURGERIES = `${PROGRAMMINGS}/search`;
export const UPLOAD_EXCEL = `${PROGRAMMINGS}/upload`;

export let getProgrammingSurgeries = function (data) {
  return axios.get(GET_PROGRAMMING_SURGERIES, {params: data});
}

export let uploadExcel = function (formData) {
  return axios.post(`${UPLOAD_EXCEL}`, formData);
}