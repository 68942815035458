import React from "react";
import {
  Alert,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import {isHospitalAtc} from "@utils/checkHospitalAtc";

export const Opinion = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Dictamen
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentOpinion {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentOpinion = (props) => {
  const formatToCurrency = amount => "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Dictamen
      </Alert>

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <b>Importe carta autorizacion: </b>{formatToCurrency(props.totalLetters)}
                  <br />
                  <b>Carta Suficiente: </b>{props.data.enoughYesNo}
                  {/* <br />
                  <b>Importe autorizado en ATC: </b>$125,000.00
                  <br />
                  <b>Folio ATC relacionado: </b>182013 */}
                  <br />
                  <b>Estatus:  </b>{props.data.statusDictum} 
                  <br />
                  <b>CARTA AUTORIZACION:  </b>{props.data.voBoLetter} 
                  <br />
                  <div hidden={isHospitalAtc(props.data)}><b>Importe Total de Desvíos: </b>${props.data.totalDeviations}</div>
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};