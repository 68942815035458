import axios from "axios";

export const BASE_ROUTE = "providers";

export let getAllProviders = function (caseId) {
  return axios.get(`${BASE_ROUTE}/${caseId}`);
}

export let saveProvider = function (data) {
  return axios.post(`${BASE_ROUTE}`, data);
}