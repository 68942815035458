export const actionTypesBinnacles = {
    FETCH_ALL_BY_CASE: "[FETCH_ALL] Action",
    FETCH_ALL_BY_CASE_PAGEABLE: "[FETCH_ALL_PAGEABLE] Action",
    GET_ALL_BY_CASE: "[GET_ALL] Action",
    STORE: "[STORE] Action",
};

export const actionsBinnacles = {
    fetchAllByCase: (caseId) => ({
        type: actionTypesBinnacles.FETCH_ALL_BY_CASE,
        payload: caseId,
    }),
    fetchAllByCasePageable: (caseId, pageNumber) => ({
        type: actionTypesBinnacles.FETCH_ALL_BY_CASE_PAGEABLE,
        payload: { caseId, pageNumber },
    }),
    getAllByCase: (binnacles) => ({
        type: actionTypesBinnacles.GET_ALL_BY_CASE,
        payload: binnacles,
    }),
    store: (data, caseId, slug, isPageable) => ({
        type: actionTypesBinnacles.STORE,
        payload: { data, caseId, slug, isPageable },
    }),
};