import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Alert,
  Button,
  Col,
  Row,
  Card,
  Form,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { MedicAssignationTraking } from "@pages/subPages/MedicAssignationTracking/MedicAssignationTracking";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { getLasMcMovementtByCase } from "@services/mcMovementsService";
import { isEmpty } from "@utils/checkObjectEmpty";
import $ from "jquery";

const ReconsiderationDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [mcMovement, setMcMovement] = useState({});

  let history = useHistory();
  const inputReasonTracing = useRef();
  const inputReasonMedicInformation = useRef();

  let submitFormMedicAssignation = null;
  let submitFormMedicAssignationErrors = null;

  const bindSubmitFormMedicAssignation = (submitForm) =>
    (submitFormMedicAssignation = submitForm);

  const bindSubmitFormMedicAssignationErrors = (errors) =>
    (submitFormMedicAssignationErrors = errors);

  useEffect(() => {
    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      getLasMcMovementtByCase(response.data.id).then((currentMcMovemet) =>
        setMcMovement(currentMcMovemet.data)
      );
    });

    subheader.setTitle("Reconsideraciones");
  }, [slug]);

  const onClickSendDictum = async () => {
    // console.log(caseRecord);
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      update({ caseStatusId: 5 }, caseRecord.id).then((response) => {
        history.push("/reconsideraciones");
      });
    }
  }

  const onClickSendTraking = async () => {
    if (inputReasonTracing.current.value === "") {
      alert("Selecciona una razon para enviar a seguimiento");
      return;
    }

    await submitFormMedicAssignation();
    if (!window.wasCommented) {
      alert("Debe ingresar un comentario");
      return;
    }
    $("#btn_save_comment").click();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      update({ caseStatusId: 3, tracingReason: inputReasonTracing.current.value }, caseRecord.id).then((response) => {
        history.push("/reconsideraciones");
      });
    }
  }
  
  const onClickSendMedicInformation = async () => {
    await submitFormMedicAssignation();
    if (isEmpty(submitFormMedicAssignationErrors)) {
      let reason_sendToMedicInformation = document.getElementById("reason_sendToMedicInformation").value;
      if (reason_sendToMedicInformation === "") {
        alert("Debe seleccionar una razon");
        return;
      }

      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      update({ caseStatusId: 4, informationReason: reason_sendToMedicInformation }, caseRecord.id).then((response) => {
        history.push("/reconsideraciones");
      });
    }
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Reconsideraciones
      </Alert>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <b>Clave: </b>
              {caseRecord.caseTicket}
              <br />
              <b>Asegurado: </b>
              {`${caseRecord.assuredName} ${caseRecord.assuredFlastname} ${caseRecord.assuredMlastname}`}
              <br />
              <b>Tipo de Ingreso: </b>
              {caseRecord.incomeType}
              <br />
              <b>Diagnostico Presuntivo: </b>
              {caseRecord.diagnosis}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <b>Folio Hospital: </b>
              {caseRecord.hospitalIden}
              <br />
              <b>Hospital: </b>
              {caseRecord.hospital?.hospitalName}
              <br />
              <b>Modelo: </b>
              {caseRecord.hospital?.model}
              <br />
              <b>Tipo de TRÁMITE: </b>
              {caseRecord.caseType}
              <br />
              <b>Folio ATC: </b>
              {caseRecord.invoiceATC}
              <br />
              <b>Tipo de folio ATC: </b>
              {caseRecord.typeInvoice}
              <br />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Accordion defaultActiveKey="0" className="mt-5">
        <MedicAssignationTraking
          otherOptions={true}
          eventKey="0"
          data={caseRecord}
          mcMovement={mcMovement}
          bindSubmitForm={bindSubmitFormMedicAssignation}
          bindErrors={bindSubmitFormMedicAssignationErrors}
        />
        <InssuredData
          eventKey="1"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={() => {}}
        />
        <HospitalData
          eventKey="2"
          data={caseRecord}
          disabledAll={true}
          bindSubmitForm={() => {}}
        />
        <AuthLetters
          showForm={false}
          hideDeleteOption={true}
          eventKey="3"
          data={caseRecord}
          disabledAll={false}
          {...props}
        />
        <Documentation eventKey="4" {...props} />
      </Accordion>
      <Accordion defaultActiveKey="5" className="mt-5">
        <Binnacle
          eventKey="5"
          data={caseRecord}
          eventStage="Mesa de Control"
          {...props}
        />
      </Accordion>

      <Row>
        <Col className="mt-5">
          <Button variant="warning" onClick={onClickSendTraking}>
            Enviar a Seguimiento
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="reason_sendToSeguimiento"
            ref={inputReasonTracing}
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Informacion Erronea">Información Errónea</option>
            <option value="Informacion Complementaria">Información Complementaria</option>
          </Form.Control>
        </Col>

        <Col className="mt-5 mr-5">
          <Button variant="danger" onClick={onClickSendMedicInformation}>
            Enviar a Informacion Medica
          </Button>

          <Form.Control
            className="mt-5 mr-3"
            as="select"
            name="reason_sendToMedicInformation"
            id="reason_sendToMedicInformation"
            ref={inputReasonMedicInformation}
          >
            <option value="">Selecciona una opcion...</option>
            <option value="Recibido">Recibido</option>
          </Form.Control>
        </Col>

        <Col>
          <Button
            className="mt-5"
            variant="success"
            onClick={onClickSendDictum}
          >
            Enviar a Dictamen
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ReconsiderationDetails;
