import React, { useState, useEffect } from "react";
import {useSubheader} from "@metronic/layout";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';
import {getAll, destroy} from "@services/permissionsCrud"
import FormPermission from "./FormPermission"

const PermissionsList = (props) => {
    const subheader = useSubheader();

    const initialPermissionState = { name: "" };

	const [permissions, setPermissions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [permission, setPermission] = useState(initialPermissionState);

    useEffect(() => {
		getPermissions();

        subheader.setTitle("Listado de Privilegios");
	}, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const getPermissions = () => getAll().then(data => setPermissions(data.data));
    const handleDeletePermission = (e, permissionId) => {
        e.preventDefault();
        destroy(permissionId).then(response => {
            getPermissions();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStorepermission = (e) => {
        handleShow();
        setPermission(initialPermissionState);
        setShouldUpdate(false);
    }
    const handleEditPermission = (e, permission) => {
        e.preventDefault();
        setPermission({ ...permission });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStorepermission}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {permissions?.map( (permission, idx) => (
                                <tr key={idx}>
                                    <td>{permission.name}</td>
                                    <td>
                                        <Button variant="success" onClick={(e) => handleEditPermission(e, permission)}>Editar</Button>{' '}
                                        <Button variant="danger" onClick={(e) => handleDeletePermission(e, permission.idPermission)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormPermission showModal={showModal}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            shouldUpdate={shouldUpdate}
                            getPermissions={getPermissions}
                            permission={permission} />
        </>
    );
};

export default PermissionsList;