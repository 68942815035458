import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Form,
  Spinner,
  Card,
  Row,
  Col,
  Table,
  Accordion,
} from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import $ from 'jquery';

import AlertError from "@components/AlertError";
import { GetUser } from "@metronic/_helpers";
import { getInputClasses } from "@utils/getInputclasses";
import { isNotEmpty } from "@utils/checkObjectEmpty";
import { update as updateCase } from "@services/hospitalizationcrud";
import CaseContext from "@contexts/CaseContext";
import BlockCaseContext from "@contexts/BlockCaseContext";
import { actionsBinnacles } from "@redux/actions/binnacles";
import Pagination from "react-js-pagination";

export const Binnacle = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Bitacora de Comentarios
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentBinnacle {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentBinnacle = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Bitacora de Comentarios
      </Alert>

      <Card>
        <Card.Body>
          <FormBinnacle {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormBinnacle = (props) => {
  const {
    data,
    eventStage,
    hideButton = false,
    hideReason = true,
    isPageable = false,
  } = props;
  const { slug } = props.match?.params || props;
  const { user: userAuth } = GetUser();
  const initialStateBinnacle= {
    comment: props.comment || "",
    event: eventStage || "",
    reason: "",
  };

  const [commentNotRequired, setCommentNotRequired] = useState(false)
  const [caseRecord, setCaseRecord] = useContext(CaseContext);
  const [, setShouldBlockCase] = useContext(BlockCaseContext);
  const [paramsPagination, setParamsPagination] = useState({
        activePage: 1,
        totalPages: null,
        itemsCountPerPage: null,
        totalItemsCount: 0,
    });

  const history = useHistory();
  const dispatch = useDispatch();
  const binnacles = useSelector(state => state.binnacles.data);
  const binnaclesContent = binnacles?.content || binnacles;

  useEffect(() => {
    if (isNotEmpty(data)) {
      callGetAllBinnacles();
    }
    loadJQuery();
  }, [data]);

  useEffect(() => {
    if (binnacles && binnacles.totalPages) {
      setParamsPagination({
        activePage: 0,
        totalPages: binnacles.totalPages,
        itemsCountPerPage: binnacles.size,
        totalItemsCount: binnacles.totalElements,
      });
    }
  }, [binnacles]);

  const callGetAllBinnacles = (pageNumber = 0) => {
    if (isPageable) {
      dispatch(actionsBinnacles.fetchAllByCasePageable(data.id, pageNumber));
    } else {
      dispatch(actionsBinnacles.fetchAllByCase(data.id));
    }
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });
    callGetAllBinnacles(pageNumber)
  }

  const loadJQuery = () => {
    $("#btn_save_comment").on('click', function(evt) {
      if (evt.originalEvent === undefined) {
        setCommentNotRequired(true);
      } else {
        setCommentNotRequired(false);
      }
    });
  }

  const onSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    values.userId = userAuth.idUser;
    values.event = eventStage || "";

    let caseType;

    if (!hideReason) {
        caseType = "Cancelado";
    } else {
        caseType = props?.data?.caseType || document.getElementById('caseType')?.value || eventStage;
    }

    const dataBinnacle = {
      ...values,
      caseType: caseType,
      axaCategories: props.data.axaCategories,
      movementsMedicalOpinion: props.data.movementsMedicalOpinion,
      complexCase: props.data.complexCase,
    };

    dispatch(actionsBinnacles.store(dataBinnacle, data.id, slug, isPageable));

    callGetAllBinnacles();
    
    resetForm();

    setShouldBlockCase(false);

    window.wasCommented = true;

    if (!hideReason) {
        updateCase({caseType: "Cancelado", caseStatusId: 10}, props.data.id).then((response) => {
            setCaseRecord({ ...caseRecord, caseType: "Cancelado", caseStatusId: 10});
            setShouldBlockCase(false);

            history.push("/cancelaciones");
        });
    }
    setSubmitting(false);
  }

  return (
      <>
          <Table responsive striped bordered hover>
              <thead>
              <tr>
                  <th>Usuario</th>
                  <th>Evento</th>
                  <th>Tipo de tramite</th>
                  <th>Fecha Hora (DD/MM/AAAA HH:MM:SS)</th>
                  <th>Comentarios</th>
              </tr>
              </thead>
              <tbody>
              {binnaclesContent?.map((binnacle, idx) => (
                  <tr key={idx} hidden={binnacle.comment.trim() === ""}
                      style={{
                        backgroundColor:
                            (props.isDifferentBackground &&
                                (binnacle.event === "Dictamen Extemporáneo" ||
                                    binnacle.event === "Seguimiento Extrahospitalario" ||
                                    binnacle.event === "Casos Finalizados Hospital"))
                                ? '#72CBA0'
                                : 'transparent'
                      }}>
                      <td>{binnacle.user?.userName}</td>
                      <td>{binnacle.event}</td>
                      <td>{binnacle.caseType}</td>
                      <td>{moment(binnacle.createdAt).format('DD/MM/YYYY HH:mm:ss A')}</td>
                      <td>{binnacle.comment}</td>
                  </tr>
              ))}
              </tbody>
          </Table>
        {isPageable && <div className="d-flex justify-content-center">
          <Pagination
              activePage={paramsPagination.activePage}
              itemsCountPerPage={paramsPagination.itemsCountPerPage}
              totalItemsCount={paramsPagination.totalItemsCount}
              pageRangeDisplayed={10}
              itemClass="page-item"
              linkClass="btn btn-light"
              onChange={handlePageChange}
          />
        </div>}

      <Divider className="mt-5 mb-5" />
      <Formik initialValues={{...initialStateBinnacle}}
              enableReinitialize={true}
              onSubmit={onSubmit}
              validationSchema={() => Yup.lazy((values) => {
                  return Yup.object().shape({
                      comment: hideReason && commentNotRequired ? null : Yup.string()
                          .required("El campo es requerido")
                          .test('required', 'El campo es requerido',
                              (item) => typeof values.comment !== 'undefined' && values.comment.trim() !== ''
                          ),
                      reason: hideReason ? null : Yup.string()
                          .required("El campo es requerido"),
                  });
              })}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
        }) => (
          <>
            <AlertError status={status} />

                      <Form onSubmit={handleSubmit}>
                          <Row hidden={props.disabledAll}>
                              <Col hidden={hideReason}>
                                  <Form.Group controlId="reason">
                                      <Form.Label>Motivo *</Form.Label>
                                      <Form.Control
                                          disabled={props.disabledAll}
                                          as="select"
                                          name="reason"
                                          value={values.document_type}
                                          onChange={handleChange}
                                          className={`${getInputClasses(
                                              touched,
                                              errors,
                                              "reason"
                                          )}`}
                                      >
                                          <option value="">SELECCIONA UNA OPCIÓN...</option>
                                          <option value="Folio Duplicado">Folio Duplicado</option>
                                          <option value="Datos Erróneos Asegurado">Datos Erróneos Asegurado</option>
                                          <option value="A petición del Asegurado">A petición del Asegurado</option>
                                          <option value="Sin Seguimiento Hospital">Sin Seguimiento Hospital</option>
                                          <option value="Error en Captura">Error en Captura</option>
                                          <option value="Otro Motivo">Otro Motivo</option>
                                      </Form.Control>
                                      <ErrorMessage name="reason">
                                          {(msg) => (
                                              <Form.Control.Feedback type="invalid">
                                                  {msg}
                                              </Form.Control.Feedback>
                                          )}
                                      </ErrorMessage>
                                  </Form.Group>
                              </Col>
                              <Col>
                                  <Form.Group controlId="comment">
                                      <Form.Label>Agrega tu Comentario *</Form.Label>
                                      <Form.Control
                                          disabled={props.disabledAll}
                                          type="text"
                                          name="comment"
                                          value={values.comment}
                                          onChange={handleChange}
                                          className={`${getInputClasses(
                                              touched,
                                              errors,
                                              "comment"
                                          )}`}
                                      />
                                      <ErrorMessage name="comment">
                                          {(msg) => (
                                              <Form.Control.Feedback type="invalid">
                                                  {msg}
                                              </Form.Control.Feedback>
                                          )}
                                      </ErrorMessage>
                                  </Form.Group>
                              </Col>
                          </Row>
                          <Button variant="primary" id="btn_save_comment" type="submit" disabled={isSubmitting}
                                  hidden={hideButton}>
                              {isSubmitting && (
                                  <Spinner
                                      as="span"
                                      animation="border"
                                      variant="dark"
                                      size="sm"
                                  />
                              )}
                              Guardar Comentario
                          </Button>

                          <Button variant="danger" type="submit" disabled={isSubmitting} hidden={hideReason}>
                              {isSubmitting && (
                                  <Spinner
                                      as="span"
                                      animation="border"
                                      variant="dark"
                                      size="sm"
                                  />
                              )}
                              Cancelar
                          </Button>
                      </Form>
                  </>
              )}
          </Formik>
      </>
  );
};
