import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert, Col, Row, Accordion, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
// import { PolicyData } from "../../insurance/AlwaysWithYou/PolicyData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import Documentation from "@pages/subPages/Documentation/Documentation";
import { MedicDataTreating } from "@pages/subPages/MedicTreatingData/MedicDataTreating";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import { InsuranceData } from "@pages/subPages/InsuranceData/InsuranceData";
import { Opinion } from "@pages/subPages/Dictum/Dictum";
import { getBySlug, update } from "@services/hospitalizationcrud";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import { getAllReviewCuts, saveReviewCuts } from "@services/reviewCutsService";
import { GetUser } from "@metronic/_helpers/PermissionHelper";
import { CostControlData } from "@pages/subPages/CostControlData/CostControlData";
import { getLasMcMovementtByCase } from "@services/mcMovementsService";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { RegistrationCaseType } from '@helpers/constants';
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import {isHospitalAtc, isNotHospitalAtc} from "@utils/checkHospitalAtc";

const UpdateProcedureDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [totalLetters, setTotalLetters] = useState(0.0);
  const [casesTypes, setCasesTypes] = useState("");
  const [atcTypeTicket, setAtcTypeTicket ] = useState("");
  const [atcTicket, setAtcTicket] =useState("");
  const [reviewCuts, setReviewCuts] = useState([]);
  const [userAuth] = useState({ ...GetUser().user });
  const [mcMovement, setMcMovement] = useState({});
  const [letterMov, setLetterMov] = useState({});
  const [shouldBlockCase, setShouldBlockCase] = useState(true);
  const [totalDeviations, setTotalDeviations] = useState(false);

  useEffect(() => {
    subheader.setTitle("Actualizacion de Tramite");

    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      //setCasesTypes(response.data.caseType);
      //setAtcTypeTicket(response.data.typeInvoice);
      //setAtcTicket(response.data.invoiceATC)
      getAllReviewCutsInit(response.data.id);
      getLasMcMovementtByCase(response.data.id).then((currentMcMovemet) =>
        setMcMovement(currentMcMovemet.data)
      );
      getLastLetterMovByCase(response.data.id).then((currentLetterMov) =>
        setLetterMov(currentLetterMov.data)
      );

      if (isNotHospitalAtc(response.data)) {
        setTotalDeviations(true);
      }
    });
  }, [slug]);

  const onSubmitCaseType = async (e) => {
    e.preventDefault();
    if ((casesTypes === null || casesTypes === "" || atcTypeTicket === "" || atcTicket ==="" || atcTypeTicket === null || atcTicket === null)
        && casesTypes !== 'Alta hospitalaria' && isHospitalAtc(caseRecord)) {
      alert("Ingresa Tipo de Tramite,  Folio ATC y Tipo de folio ATC");
    } else {
      let timestamp = await axios.get('/cases/getTimestamp');
      let request_dic = {
        caseType: casesTypes,
        typeInvoice: atcTypeTicket,
        invoiceATC: atcTicket,
      };

      const hospital_datehire = moment.utc(timestamp.data).format();

      if (RegistrationCaseType.includes(request_dic.caseType) && !caseRecord.hospitalDateHire) {
        request_dic.hospitalDateHire = hospital_datehire
      }

      update(request_dic, caseRecord.id).then((response) =>{
        $("#btn_save_comment").click();
        alert("Actualizado");
      });

      if ((casesTypes === "Revision de corte" || casesTypes === "Alta hospitalaria") && isHospitalAtc(caseRecord)) {
        await saveReviewCuts({ caseType: casesTypes, typeInvoice: atcTypeTicket, invoiceATC: atcTicket, caseId: caseRecord.id, userId: userAuth.idUser });
        getAllReviewCutsInit(caseRecord.id);
      }

      setCaseRecord({ ...caseRecord, ...request_dic });
    }
  }

  const getAllReviewCutsInit = (id) => {
    getAllReviewCuts(id).then((response) => {
      setReviewCuts(response.data);
    });
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Actualizacion de Tramite
      </Alert>

      <CaseHeader
        caseRecord={caseRecord}
        requiresTabulationSlot={<></>}
        totalDeviations={totalDeviations}
        rightHeaderSlot={
          <form className="form-horizontal mt-2" onSubmit={ e => onSubmitCaseType(e)}>
            <Row>
              <Col className="col-sm-3 mt-4">
                <b>Tipo de TRÁMITE:</b>
              </Col>
              <Col>
                <select
                  className="form-control"
                  name="caseTypeSelect"
                  value={casesTypes}
                  onChange={e => {
                    setCasesTypes(e.target.value);
                    if ((e.target.value === 'Alta hospitalaria' || e.target.value === 'Revision de corte') && isHospitalAtc(caseRecord)) {
                      setTotalDeviations(false);
                    } else {
                      setTotalDeviations(true);
                    }
                  }}
                >
                  <option value="">SELECCIONE UNA OPCIÓN....</option>
                  <option value="Alta hospitalaria">
                    Alta hospitalaria
                  </option>
                  <option value="Revision de corte">
                    Revision de corte
                  </option>
                </select>
              </Col>
              <Col>
                <Button variant="primary" type ="submit">
                  Actualizar
                </Button>
              </Col>
            </Row>
            <Row className="mt-4" hidden={totalDeviations}>
              <Col className="col-sm-3 mt-4"> 
                <b>Folio ATC: </b>
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  name="atcTicket"
                  value={atcTicket}
                  onChange={e => setAtcTicket(e.target.value)}
                >
                </input>
              </Col>
            </Row>
            <Row hidden={totalDeviations}>
              <Col className="col-sm-3 mt-4">
                <b>Tipo de folio ATC: </b>
              </Col>
              <Col className ="mt-4">
                <select
                  className="form-control"
                  name="atcTypeTicket"
                  value={atcTypeTicket}
                  onChange={e => setAtcTypeTicket(e.target.value)}
                >
                  <option value="">SELECCIONE UNA OPCIÓN....</option>
                  <option value="Alta"> Alta </option>
                  <option value="Corte">Corte</option>
                </select>
              </Col>
            </Row>
          </form>
        }
      />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <HospitalData
            icon="fa-search"
            evenKey="9"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InssuredData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <FamilyData
            icon="fa-search"
            eventKey="3"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <MedicDataTreating
            icon="fa-search"
            eventKey="4"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <InsuranceData
            icon="fa-search"
            eventKey="6"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <Opinion
            icon="fa-search"
            eventKey="7"
            data={caseRecord}
            disabledAll={true}
            totalLetters={totalLetters}
            bindSubmitForm={() => {}}
          />
          <CostControlData
            icon="fa-search"
            eventKey="1"
            data={caseRecord}
            mcMovement={mcMovement}
            letterMov={letterMov}
            totalLetters={totalLetters}
            showForm={false}
            reviewCuts={reviewCuts}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="8"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <Documentation
            icon="fa-search"
            icone="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            disabledAll={false}
            attachOptions={true}
            typesDocumentToAttach={["Estado de Cuenta Final"]}
            {...props}
          />
          <MedicalFees
              icon= "fa-pencil-alt"
              eventKey="10"
              data={caseRecord}
              {...props}
          />
        </Accordion>
        <Accordion defaultActiveKey="10" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="10"
            hideButton={false}
            data={caseRecord}
            disabledAll={false}
            eventStage="Actualizacion de Tramite"
            comment="SE ACTUALIZA TIPO DE TRAMITE"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>
    </>
  );
};

export default UpdateProcedureDetails;
