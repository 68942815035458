import React from "react"

export default function AlertMessage({ success, message }) {
  const alertTypeClass = success ? "alert-light-success" : "alert-light-danger";

  return (
      <>
        {message ? (
            <div className={`mb-10 alert alert-custom ${alertTypeClass} alert-dismissible`}>
              <div className="alert-text font-weight-bold">{message}</div>
            </div>
        ) : null}
      </>
  );
}