import React, { useState, useEffect } from "react";
import {
    Accordion,
    Alert,
} from "react-bootstrap";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import { AdminData } from "@pages/subPages/AdminData/AdminData";
import {
    getBySlug,
} from "@services/hospitalizationcrud";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";

const CompletedCasesDetails = (props) => {
    const subheader = useSubheader();

    const { slug } = props.match.params;

    const [caseRecord, setCaseRecord] = useState({});
    const [letterMov, setLetterMov] = useState({});
    const [totalLetters, setTotalLetters] = useState("0");
    const [shouldBlockCase, setShouldBlockCase] = useState(true);

    useEffect(() => {
        window.wasCommented = false;
        getBySlug(slug).then((response) => {
            setCaseRecord(response.data);
            window.requires_tabulation = response.data.requiresTabulation;
            getLastLetterMovByCase(response.data.id).then((currrentLetterMov) =>
                setLetterMov(currrentLetterMov.data)
            );
        });

        subheader.setTitle("Casos Finalizados");
    }, [slug]);

    return (
        <>
            <Alert
                variant="success"
                className="text-center"
                style={{ fontSize: "16px" }}
            >
                Casos Finalizados
            </Alert>

            <CaseHeader
                caseRecord={caseRecord}
                requiresTabulationSlot={
                    <>
                        <b>Tabulacion: </b>
                        {caseRecord.requiresTabulation}
                    </>
                }
            />

            <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
                <Accordion defaultActiveKey="0" className="mt-5">
                    <InssuredData
                        icon="fa-search"
                        eventKey="1"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={()=>{}}
                    />
                    <HospitalData
                        icon="fa-search"
                        eventKey="2"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={()=>{}}
                    />
                    <AdminData
                        icon ="fa-search"
                        data={caseRecord}
                        eventKey="0"
                        letterMov={letterMov}
                        totalLetters={totalLetters}
                        bindSubmitForm={() => {}}
                    />
                    <AuthLetters
                        icon="fa-search"
                        showForm={false}
                        hideDeleteOption={true}
                        eventKey="3"
                        data={caseRecord}
                        disabledAll={false}
                        setTotalLetters={setTotalLetters}
                        {...props}
                    />
                    <Documentation
                        icon ="fa-search"
                        eventKey="4"
                        data={caseRecord}
                        disabledAll={true}
                        {...props}
                    />
                    <MedicalFees
                        icon ="fa-search"
                        eventKey="6"
                        data={caseRecord}
                        {...props}
                    />
                </Accordion>
                <Accordion defaultActiveKey="5" className="mt-5">
                    <Binnacle
                        icon ="fa-search"
                        eventKey="5"
                        data={caseRecord}
                        disabledAll={true}
                        hideButton={true}
                        eventStage="Alta Paciente"
                        {...props}
                    />
                </Accordion>
            </BlockCaseContext.Provider>
        </>
    );
};

export default CompletedCasesDetails;
