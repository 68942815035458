export const RegistrationCaseType = Object.freeze([
    "Alta hospitalaria",
    "Alta hospitalario",
    "Alta express",
    "Reconsideración de Alta",
    "Reconsideración de Alta",
    "Reconsideración de Dictamen",
    "Reconsideracion de Dictamen",
    "Reconsideracion de dictamen",
]);

export const CaseTypeSlaFlagColumn = Object.freeze([
    "Alta hospitalaria",
    "Alta hospitalario",
    "Alta express",
    "Reconsideración de Alta",
    "Reconsideración de Alta",
]);

export const AxaCategoriesVip = Object.freeze([
    "VIP",
]);

export const AxaCategoriesOthersNotVisible = Object.freeze([
    "CUIDADO INTEGRAL",
    "VIP - CUIDADO INTEGRAL",
]);

export const PROCEDURE_TYPES = {
    FEE_TABULATION: 'Tabulación de Honorarios',
    VISA_VALIDATION_ATC: 'Visado / Validación de ATC',
    AUTHORIZATION_LETTER_MODIFICATION: 'Modificación Carta de Autorización',
};

export const STATUSES = {
    CONTROL_DESK: 2,
    MEDIC_INFORMATION: 4,
};
