//React, Bootstrap & Others
import React,{useState,useEffect} from "react";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';

//Metronic
import {useSubheader} from "@metronic/layout";

//Services
import {getAllConcierges, destroyConcierge} from "@services/conciergeService";

//Components
import FormConcierge from "./FormConcierge"

const ConciergesList = (props) => {
    const subheader = useSubheader();

    const initialConciergeState = { conciergeName: "", phone: "", mobile: "", email: "", city: "", category: "" };

    const [concierges, setConcierges] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [concierge, setConcierge] = useState(initialConciergeState);

    useEffect(() => {
        getConcierges();

        subheader.setTitle("Listado de Concierges");
    }, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const getConcierges = () => getAllConcierges().then(data => setConcierges(data.data));
    const handleDeleteNurse = (e, conciergeId) => {
        e.preventDefault();
        destroyConcierge(conciergeId).then(response => {
            getConcierges();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStoreConcierge = (e) => {
        handleShow();
        setConcierge(initialConciergeState);
        setShouldUpdate(false);
    }
    const handleEditConcierge = (e, concierge) => {
        e.preventDefault();
        setConcierge({ ...concierge });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStoreConcierge}>Agregar</Button>
                    <Table responsive>
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Categoria</th>
                            <th>Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {concierges?.map( (concierge, idx) => (
                            <tr key={idx}>
                                <td>{concierge.conciergeName}</td>
                                <td>{concierge.category}</td>
                                <td>
                                    <Button variant="success" onClick={(e) => handleEditConcierge(e, concierge)}>Editar</Button>{' '}
                                    <Button variant="danger" onClick={(e) => handleDeleteNurse(e, concierge.id)}>Eliminar</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormConcierge showModal={showModal}
                       handleShow={handleShow}
                       handleClose={handleClose}
                       shouldUpdate={shouldUpdate}
                       getConcierges={getConcierges}
                       concierge={concierge} />
        </>
    );
};

export default ConciergesList;
