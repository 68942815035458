import React, { useState, useEffect } from "react";
import {Accordion, Alert, Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSubheader } from "@metronic/layout";
import axios from "axios";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import { InssuredFile } from "@pages/subPages/InsuredFile/InssuredFile";
import { MedicalDataAdditional } from "@pages/subPages/AdditionalMedicalData/MedicalDataAdditional";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud"
import { isEmpty } from "@utils/checkObjectEmpty";
import { getLetterMovsByCase } from "@services/letterMovsService";
import moment from "moment";
import {getInputClasses} from "@utils/getInputclasses";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { getAllMedicalFees } from "@services/medicalFeesService";
import { ProvidersData } from "@pages/subPages/SurgeryProvidersData/ProvidersData";
import { RegistrationCaseType } from '@helpers/constants';
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from 'jquery';
import {INCOME_TYPE} from "../../../helpers/incomeType";
import {CASE_TYPE} from "../../../helpers/caseType";
import {STATUS} from "../../../helpers/status";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import NumberFormat from "react-number-format";

const MedicalInformationDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [lettersMov, setLettersMov] = useState([]);
  const [totalLetters, setTotalLetters] = useState(0);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);
  const [showAmountLetterSufficientModal, setShowAmountLetterSufficientModal] = useState(false);
  const [selectedOptionAmountLetterSufficient, setSelectedOptionAmountLetterSufficient] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    subheader.setTitle("INFORMACIÓN MÉDICA");

    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      setCaseRecord(response.data);
      window.requires_tabulation = response.data.requiresTabulation;
      getLetterMovsByCase(response.data.id).then((currrentLettersMov) => setLettersMov(currrentLettersMov.data));
    });
  }, [slug]);

  let history = useHistory();
  let submitFormInssuredFile = null;
  let submitFormInssuredFileErrors = null;
  let submitFormMedicalFee = null;
  let submitFormMedicalFeeErrors = null;

  const validationSchema = Yup.object().shape({
    amountAuthorizationLetter: Yup.string()
        .required("El campo es requerido"),
    hospitalizationExpenseAmount: Yup.string()
        .required("El campo es requerido"),
    requiresAdditionalTabLetter: Yup.string()
        .required("El campo es requerido"),
  });

  const bindSubmitFormInssuredFile = (submitForm) =>
    (submitFormInssuredFile = submitForm);

  const bindSubmitFormInssuredFileErrors = (errors) =>
    (submitFormInssuredFileErrors = errors);

  const bindSubmitFormMedicalFee = (submitForm) =>
      (submitFormMedicalFee = submitForm);

  const bindSubmitFormMedicalFeeErrors = (errors) =>
      (submitFormMedicalFeeErrors = errors);

  const handleModuleSendCase = (amountLetterSufficient = true) => {
    const caseType = caseRecord.caseType;
    const incomeType = caseRecord.incomeType;

    const isHospitalReportOrScheduledSurgery = incomeType === INCOME_TYPE.HOSPITAL_REPORT || INCOME_TYPE.SCHEDULED_SURGERY;

    switch (caseType) {
      case CASE_TYPE.HOSPITAL_DISCHARGE:
      case CASE_TYPE.CUT_REVIEW:
        if (amountLetterSufficient && isHospitalReportOrScheduledSurgery) {
          return STATUS.COST_CONTROL;
        } else if (!amountLetterSufficient && isHospitalReportOrScheduledSurgery) {
          return STATUS.CONTROL_DESK;
        }
        break;
      default:
        return STATUS.CONTROL_DESK;
    }
  }

  const onClickSendControlDesk = async (values, status) => {
    let statusId = STATUS.CONTROL_DESK;
    const medicalFeesResponse = await getAllMedicalFees(caseRecord.id);

    await submitFormInssuredFile();

    if (document.getElementById('si1').checked && medicalFeesResponse.data.length === 0) {
      await submitFormMedicalFee();
    }

    if (!validateRequireTabulation()) {
      alert("El campo requiere tabulacion es obligatorio");
      return;
    }

    if (isEmpty(submitFormInssuredFileErrors) && isEmpty(submitFormMedicalFeeErrors)) {
      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }

      if (typeof status !== 'undefined') {
        const isReviewCutOrHospitalDischarge = caseRecord.caseType === CASE_TYPE.HOSPITAL_DISCHARGE || caseRecord.caseType === CASE_TYPE.CUT_REVIEW;

        values.amountAuthorizationLetter = parseFloat(
            typeof values.amountAuthorizationLetter === 'string'
                ? values.amountAuthorizationLetter.replace(/,/g, '')
                : values.amountAuthorizationLetter || 0
        );

        values.hospitalizationExpenseAmount = parseFloat(
            typeof values.hospitalizationExpenseAmount === 'string'
                ? values.hospitalizationExpenseAmount.replace(/,/g, '')
                : values.hospitalizationExpenseAmount || 0
        );
        if (caseRecord.caseType === CASE_TYPE.HOSPITAL_DISCHARGE &&
            values.amountAuthorizationLetter > values.hospitalizationExpenseAmount &&
            values.requiresAdditionalTabLetter === 'si') {
          statusId = STATUS.CONTROL_DESK;
        } else if (values.amountAuthorizationLetter < values.hospitalizationExpenseAmount && isReviewCutOrHospitalDischarge) {
          statusId = STATUS.CONTROL_DESK;
        } else if (values.amountAuthorizationLetter > values.hospitalizationExpenseAmount && isReviewCutOrHospitalDischarge) {
          statusId = STATUS.COST_CONTROL;
        }
      }

      let timestamp = await axios.get('/cases/getTimestamp');
      const request_dic = {
        caseStatusId: statusId,
        requiresTabulation: window.requires_tabulation ? window.requires_tabulation : null,
        requiresProviders: window.requires_providers ? window.requires_providers : null,
      };

      if (request_dic.caseStatusId === STATUS.CONTROL_DESK) {
        request_dic.feamesaControl = moment.utc(timestamp.data).format();
      } else if (request_dic.caseStatusId === STATUS.COST_CONTROL) {
        request_dic.feaccostos = moment.utc(timestamp.data).format();
      }

      if (RegistrationCaseType.includes(document.getElementById('caseTypeOther').value) && !caseRecord.hospitalDateHire) {
        request_dic.hospitalDateHire = moment.utc(timestamp.data).format(); // Date.now();
      }

      $("#btn_save_comment").click();


      update(request_dic, caseRecord.id).then((response) => {
        history.push("/informacion_medica");
      });
    }
  }

  const onClickSave = async () => {
    const medicalFeesResponse = await getAllMedicalFees(caseRecord.id);

    await submitFormInssuredFile();

    if (document.getElementById('si1').checked && medicalFeesResponse.data.length === 0) {
      await submitFormMedicalFee();
    }

    if (!validateRequireTabulation()) {
      alert("El campo requiere tabulacion es obligatorio");
      return;
    }

    if (isEmpty(submitFormInssuredFileErrors) && isEmpty(submitFormMedicalFeeErrors)) {
      update({
        requiresTabulation: window.requires_tabulation ? window.requires_tabulation : null,
        requiresProviders: window.requires_providers ? window.requires_providers : null }, caseRecord.id);
      $("#btn_save_comment").click();
      history.push("/informacion_medica");
    }
  }

  const validateRequireTabulation = () => {
    if(document.getElementById('si1').checked || document.getElementById('no1').checked) {
      return true;
    }

    return false;
  }

  const handleAmountLetterSufficientModal = () => setShowAmountLetterSufficientModal(!showAmountLetterSufficientModal)

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Informacion Medica
      </Alert>

      <CaseHeader
        caseRecord={caseRecord}
        requiresTabulationSlot={
          <>
            <b>Tabulacion: </b>
            {caseRecord.requiresTabulation}
          </>
        }
      />

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="6" className="mt-5">
          <MedicalDataAdditional
            icon ="fa-search"
            eventKey="6"
            data={caseRecord}
            bindSubmitForm={() => {}}
            disabled={true}
          />
        </Accordion>
        <Accordion defaultActiveKey="0" className="mt-5">
          <Documentation
            icon= "fa-pencil-alt"
            eventKey="4"
            data={caseRecord}
            disabledAll={false}
            typesDocument={[
              "Aviso de Accidente", "Carta de Autorización", "Comprobante de Seguro o Póliza", "Denuncia ante MP",
              "Estado de Cuenta Parcial", "Estado de Cuenta Final", "Estudios de Gabinete", "Estudios de Laboratorio",
              "Estudios que Confirmen DX", "Historia Clínica", "Identificación", "Informe Medico Actualizad",
              "Nota de Evolución", "Nota Postquirúrgica", "Resumen Medico Actualizado", "Otro",
            ]}
            {...props}
          />
          <MedicalFees
            icon= "fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            bindSubmitForm={bindSubmitFormMedicalFee}
            bindErrors={bindSubmitFormMedicalFeeErrors}
            {...props}
          />
          <AuthLetters
            icon="fa-search"
            showForm={false}
            hideDeleteOption={true}
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={setTotalLetters}
            {...props}
          />
          <InssuredData
            icon="fa-search"
            eventKey="1"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <HospitalData
            icon="fa-search"
            eventKey="2"
            data={caseRecord}
            disabledAll={true}
            bindSubmitForm={() => {}}
          />
          <ProvidersData
            icon= "fa-pencil-alt"
            eventKey="6"
            data={caseRecord}
            disabled={caseRecord?.hospital?.captProviders !== 1}
            {...props}
          />
          <InssuredFile
            icon="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            lettersMov={lettersMov}
            totalLetters={totalLetters}
            hideAmountLetterSufficient={true}
            bindSubmitForm={bindSubmitFormInssuredFile}
            bindErrors={bindSubmitFormInssuredFileErrors}
          />
        </Accordion>
        <Accordion defaultActiveKey="5" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            disabledAll={false}
            eventStage="Informacion Medica"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>

      <Row>
        <Col>
          <Button
            className="mt-5"
            variant="success"
            onClick={async (e) => {
              if (caseRecord.caseType === CASE_TYPE.HOSPITAL_DISCHARGE || caseRecord.caseType === CASE_TYPE.CUT_REVIEW) {
                handleAmountLetterSufficientModal(e);
              } else {
                await onClickSendControlDesk();
              }
            }}
          >
            Enviar a Solicitud
          </Button>
        </Col>
        <Col className="mt-5 mr-5" onClick={onClickSave}>
          <Button variant="warning">Guardar Avance</Button>
        </Col>
      </Row>

      <Modal show={showAmountLetterSufficientModal} onHide={handleAmountLetterSufficientModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            ¿El Monto de tu Carta de Autorización cubre el Gasto de la Hospitalización?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="d-flex align-items-center">
            <Form.Check
                type="radio"
                id="radio-yes"
                label="Sí"
                name="isScheduledSurgery"
                value="Y"
                checked={selectedOptionAmountLetterSufficient === 'Y'}
                onChange={(e) => setSelectedOptionAmountLetterSufficient(e.target.value)}
            />
            <Form.Check
                className="ml-5"
                type="radio"
                id="radio-no"
                label="No"
                name="isScheduledSurgery"
                value="N"
                checked={selectedOptionAmountLetterSufficient === 'N'}
                onChange={(e) => setSelectedOptionAmountLetterSufficient(e.target.value)}
            />
          </Form.Group>

          <Formik onSubmit={onClickSendControlDesk}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  initialValues={{amountAuthorizationLetter: "", hospitalizationExpenseAmount: "", requiresAdditionalTabLetter: ""}}>
            {({values, errors, touched, handleChange, handleSubmit, status, setFieldValue, isSubmitting}) => (
                <>
                  <Form onSubmit={handleSubmit} hidden={selectedOptionAmountLetterSufficient === 'N' || selectedOptionAmountLetterSufficient === ''}>
                    <Form onSubmit={handleSubmit} hidden={selectedOptionAmountLetterSufficient === 'N' || selectedOptionAmountLetterSufficient === ''}>
                      <Form.Group controlId="amountAuthorizationLetter">
                        <Form.Label>Captura importe carta autorización</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>$</InputGroup.Text>
                          <NumberFormat
                              fixedDecimalScale={true}
                              decimalScale={2}
                              value={values.amountAuthorizationLetter}
                              onChange={handleChange}
                              name="amountAuthorizationLetter"
                              thousandSeparator={true}
                              className={`form-control ${getInputClasses(
                                  touched,
                                  errors,
                                  "amountAuthorizationLetter"
                              )}`}
                          />
                        </InputGroup>

                        <ErrorMessage name="amountAuthorizationLetter">
                          {msg => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Form>


                    <Form.Group controlId="hospitalizationExpenseAmount">
                      <Form.Label>Captura importe gasto hospitalización</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            value={values.hospitalizationExpenseAmount}
                            onChange={handleChange}
                            name="hospitalizationExpenseAmount"
                            thousandSeparator={true}
                            className={`form-control ${getInputClasses(
                                touched,
                                errors,
                                "hospitalizationExpenseAmount"
                            )}`}
                        />
                      </InputGroup>

                      <ErrorMessage name="hospitalizationExpenseAmount">
                        {msg => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>


                    <Form.Group>
                      <Form.Label>¿Requieres alguna carta de tabulacion adicional?</Form.Label>
                      <div className={`${getInputClasses(
                          touched,
                          errors,
                          "amountLetterSufficient"
                      )}`}>
                        <Form.Check
                            inline
                            type="radio"
                            id="radio-yesR"
                            label="Sí"
                            name="requiresAdditionalTabLetter"
                            value="si"
                            checked={values.requiresAdditionalTabLetter === 'si'}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value === 'si') {
                                setShowAlert(true);
                              } else {
                                setShowAlert(false);
                              }
                            }}
                        />
                        <Form.Check
                            inline
                            className="ml-5"
                            type="radio"
                            id="radio-noR"
                            label="No"
                            name="requiresAdditionalTabLetter"
                            value="no"
                            checked={values.requiresAdditionalTabLetter === 'no'}
                            onChange={e => {
                              handleChange(e);
                              if (e.target.value === 'no') {
                                setShowAlert(false);
                              } else {
                                setShowAlert(true);
                              }
                            }}
                        />
                      </div>
                      <ErrorMessage name="requiresAdditionalTabLetter">
                        {msg => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={isSubmitting} hidden={showAlert}>
                      {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                      Continuar
                    </Button>

                    <span style={{ 'color': 'red', 'fontWeight': 'bold' }} hidden={!showAlert}>
                      NOTA: Favor de capturar los datos del médico a tabular en el apartado de “HONORARIOS MEDICOS”.
                    </span>
                  </Form>
                </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button
              hidden={selectedOptionAmountLetterSufficient === 'Y'}
              variant="primary"
              onClick={onClickSendControlDesk}
          >
            Continuar
          </Button>
          <Button variant="secondary" onClick={handleAmountLetterSufficientModal}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MedicalInformationDetails;
