import axios from "axios";

export const GET_ALL = "documents";
export const GET_ALL_BY_CASE = "documents/getAllByCase";
export const SAVE = "documents";


export let getAll = function () {
    return axios.get(GET_ALL);
}

export let getAllByCase = function (caseId) {
    return axios.get(`${GET_ALL_BY_CASE}/${caseId}`);
}

export let save = function (data) {
    return axios.post(SAVE, data);
}

