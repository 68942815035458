import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner} from "react-bootstrap";
import {save, update} from "@services/nursesService";
import {getInputClasses} from "@utils/getInputclasses";

import AlertError from "@components/AlertError"

const FormNurse = (props) => {
    const validationSchema = Yup.object().shape({
        nurseName: Yup.string()
            .required("El campo es requerido"),
        nurseFatherLastname: Yup.string()
            .required("El campo es requerido"),
        nurseMotherLastname: Yup.string()
            .required("El campo es requerido"),
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        values.fullName = `${values.nurseFatherLastname} ${values.nurseMotherLastname} ${values.nurseName}`;
        setTimeout(() => {
            if (props.shouldUpdate) {
                update(values, props.nurse.id).then(response => {
                    props.getNurses();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al actualizar");
                });
            } else {
                values.isActive = 1;
                save(values).then(response => {
                    props.getNurses();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al guardar");
                });
            }
        }, 1000);
    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.shouldUpdate ? "Editar" : "Agregar"} enfermera</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{...props.nurse}} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleSubmit, status, isSubmitting}) => (
                            <>
                                <AlertError status={status} />
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="nurseName">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.nurseName}
                                                    onChange={handleChange}
                                                    name="nurseName"
                                                    placeholder="Escribe el nombre"
                                                    className={`${getInputClasses(touched, errors, "nurseName")}`} />
                                            <ErrorMessage name="nurseName">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="nurseFatherLastname">
                                        <Form.Label>Apellido paterno</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.nurseFatherLastname}
                                                    onChange={handleChange}
                                                    name="nurseFatherLastname"
                                                    placeholder="Escribe el nombre"
                                                    className={`${getInputClasses(touched, errors, "nurseFatherLastname")}`} />
                                            <ErrorMessage name="nurseFatherLastname">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="nurseMotherLastname">
                                        <Form.Label>Apellido materno</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.nurseMotherLastname}
                                                    onChange={handleChange}
                                                    name="nurseMotherLastname"
                                                    placeholder="Escribe el nombre"
                                                    className={`${getInputClasses(touched, errors, "nurseMotherLastname")}`} />
                                            <ErrorMessage name="nurseMotherLastname">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="category">
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control as="select"
                                                    value={values.category}
                                                    onChange={handleChange}
                                                    name="category"
                                                    className={`${getInputClasses(touched, errors, "category")}`}>
                                                <option value="">Selecciona una opcion...</option>
                                                <option value="VIP">VIP</option>
                                            </Form.Control>
                                            <ErrorMessage name="category">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                                        Guardar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FormNurse;
