import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button, Table, Spinner,
} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
import { getAll } from "@services/axaService";
import {AxaCategoriesOthersNotVisible, AxaCategoriesVip} from "@helpers/constants";
import {getSufferingsByCaseId, storeSuffering} from "../../services/sufferingsDataService";

export const SufferingData = (props) => {
  return (
      <>
        <Card>
          <Card.Header>
            <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey={props.eventKey}
            >
              Datos Padecimiento
            </Accordion.Toggle>
            <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
            <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
          </Card.Header>
          <Accordion.Collapse eventKey={props.eventKey}>
            <Card.Body>
              <ContentSufferingData {...props} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </>
  );
};

export const ContentSufferingData = (props) => {
  return (
      <>
        <Alert
            variant="dark"
            className="text-center"
            style={{ fontSize: "14px" }}
        >
          Datos Padecimiento
        </Alert>

        <Card>
          <Card.Body>
            <FormSufferingData {...props} />
          </Card.Body>
        </Card>
      </>
  );
};

export const FormSufferingData = (props) => {
  const [initialValues, setInitialValues] = useState ({
    clientCertificate: "",
    atentionIden: "",
    identificator: "",
    opinionIden: "",
    icd: "",
    icdDescription: "",
    cpt: "",
    cptDescription: "",
  });
  const [sufferings, setSufferings] = useState([]);

  const [axaCategories] = useState([]);
  const { id } = props.data;
  const { bindSubmitForm } = props;

  const getSufferings = async () => {
    const sufferingsResponse = await getSufferingsByCaseId(id);

    setSufferings(sufferingsResponse?.data);
  }

  const handleSubmitForm = async (values, { setStatus, resetForm, setSubmitting }) => {
    values.caseId = id;
    await storeSuffering(values, id);
    await getSufferings();

    resetForm();
    setSubmitting(false)
  };

  useEffect(() => {
    if (id) {
      getSufferings();
    }
  }, [props.data]);

  const validationSchema = Yup.object().shape({});

  return (
    <>
      <Formik
          enableReinitialize={true}
          initialValues={{ ...initialValues, axaCategoriesArray: axaCategories }}
          onSubmit={handleSubmitForm}
          validationSchema={validationSchema}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            status,
            isSubmitting,
            submitForm,
            setFieldValue,
          }) => {
          bindSubmitForm(submitForm);
          return (
              <>
                <AlertError status={status} />
                <Form id="formu2" onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group controlId="clientCertificate">
                        <Form.Label>Código de cliente/ Certificado</Form.Label>
                        <Form.Control
                            type="clientCertificate"
                            name="clientCertificate"
                            value={values.clientCertificate}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "clientCertificate"
                            )}`}
                        />
                        <ErrorMessage name="clientCertificate">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="atentionIden">
                        <Form.Label>Atención</Form.Label>
                        <Form.Control
                            type="text"
                            name="atentionIden"
                            value={values.atentionIden}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "atentionIden"
                            )}`}
                        />
                        <ErrorMessage name="atentionIden">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="identificator">
                        <Form.Label>Folio</Form.Label>
                        <Form.Control
                            type="text"
                            name="identificator"
                            value={values.identificator}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "identificator"
                            )}`}
                        />
                        <ErrorMessage name="identificator">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="opinionIden">
                        <Form.Label>Dictamen</Form.Label>
                        <Form.Control
                            type="text"
                            name="opinionIden"
                            value={values.opinionIden}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "opinionIden"
                            )}`}
                        />
                        <ErrorMessage name="opinionIden">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="icd">
                        <Form.Label>ICD (Clave)</Form.Label>
                        <Form.Control
                            type="text"
                            name="icd"
                            value={values.icd}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "icd"
                            )}`}
                        />
                        <ErrorMessage name="icd">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="icdDescription">
                        <Form.Label>ICD descripción</Form.Label>
                        <Form.Control
                            type="text"
                            name="icdDescription"
                            value={values.icdDescription}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "icdDescription"
                            )}`}
                        />
                        <ErrorMessage name="icdDescription">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="cpt">
                        <Form.Label>CPT (Clave)</Form.Label>
                        <Form.Control
                            type="text"
                            name="cpt"
                            value={values.cpt}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "cpt"
                            )}`}
                        />
                        <ErrorMessage name="cpt">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group controlId="cptDescription">
                        <Form.Label>CPT descripción</Form.Label>
                        <Form.Control
                            type="text"
                            name="cptDescription"
                            value={values.cptDescription}
                            onChange={handleChange}
                            disabled={props.disabledAll}
                            className={`${getInputClasses(
                                touched,
                                errors,
                                "cptDescription"
                            )}`}
                        />
                        <ErrorMessage name="cptDescription">
                          {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                          )}
                        </ErrorMessage>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button className="mb-4" variant="primary" type="submit" disabled={isSubmitting}>
                    {isSubmitting && (
                        <Spinner
                            as="span"
                            animation="border"
                            variant="dark"
                            size="sm"
                        />
                    )}
                    Guardar
                  </Button>
                </Form>
              </>
          );
        }}
      </Formik>
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Atención</th>
          <th>Folio</th>
          <th>Dictamen</th>
          <th>ICD (Clave)</th>
          <th>ICD descripción</th>
          <th>CPT descripción</th>
          <th>CPT descripción</th>
        </tr>
        </thead>
        <tbody>
          {sufferings?.map((suffering, idx) => (
              <tr key={idx}>
                <td>{suffering.atentionIden}</td>
                <td>{suffering.identificator}</td>
                <td>{suffering.opinionIden}</td>
                <td>{suffering.icd}</td>
                <td>{suffering.icdDescription}</td>
                <td>{suffering.cpt}</td>
                <td>{suffering.cptDescription}</td>
              </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
