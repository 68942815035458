import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  Spinner,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
import { save } from "@services/binnaclesService";
import { GetUser } from "@metronic/_helpers";
import { useContext } from "react";
import CaseContext from "@contexts/CaseContext";
import BlockCaseContext from "@contexts/BlockCaseContext";
import { RegistrationCaseType } from '@helpers/constants';
import $ from "jquery";
import {isHospitalAtc} from "@utils/checkHospitalAtc";
import {ListReviewCuts} from "../InsuredFile/ReviewCuts/ListReviewCuts";
import {getAllReviewCuts, saveReviewCuts} from "@services/reviewCutsService";


export const UpdateCaseType = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Actualizacion de tramite
          </Accordion.Toggle>
          <i
            className={`fa ${props.icon}`}
            style={{
              position: "absolute",
              right: "0",
              marginRight: "15px",
              marginTop: "10px",
            }}
          ></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentUpdateCaseType {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentUpdateCaseType = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Actualizacion de tramite
      </Alert>

      <Card>
        <Card.Body>
          <UpdateCaseTypeForm {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const UpdateCaseTypeForm = (props) => {
  const [userAuth] = useState({ ...GetUser().user });
  const { id, slug } = props.data;
  const [caseRecord, setCaseRecord] = useContext(CaseContext);
  const [, setShoudBlockCase] = useContext(BlockCaseContext);
  const [reviewCuts, setReviewCuts] = useState([]);
  const [caseType, setCaseType] = useState("");
  const [totalDeviations, setTotalDeviations] = useState(false);

  const initialValues = {
    caseTypeSelect: !props.setBlankUpdateCaseType ? props.data.caseType: "" || "",
    atcTicket: !props.setBlankUpdateCaseType ? props.data.invoiceATC: "" || "",
    atcTypeTicket: !props.setBlankUpdateCaseType ? props.data.typeInvoice: "" || "",
  };

  const atcTicketValidation = Yup.string().required("El campo es requerido").matches(
    /^[0-9]+$/,
    'El campo "Folio ATC" solo permite caracteres numericos'
  )

  const atcTypeTicketValidation = Yup.string().required("El campo es requerido");

  const validationSchema = Yup.object().shape({
    caseTypeSelect: Yup.string().required("El campo es requerido"),
    atcTicket: caseType === "Alta express" ? atcTicketValidation : null,
    atcTypeTicket: caseType === "Alta express" ? atcTypeTicketValidation : null,
  });

  const casesTypes = props.caseTypes || [
    "Alta hospitalaria", "Alta express", "Revision de corte",
  ];

  useEffect(() => {
    setCaseType(props?.data?.caseType);
    setTotalDeviations(props.data?.hospital?.atc?.toLowerCase() === 'no');

    if (props?.data) {
      getAllReviewCutsInit();
    }
  }, [props.data]);

  const getAllReviewCutsInit = () => {
    getAllReviewCuts(id).then((response) => {
      setReviewCuts(response.data);
    });
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    let timestamp = await axios.get('/cases/getTimestamp');
    setTimeout(() => {
      const hospital_datehire = moment.utc(timestamp.data).format();

      let request_dic = {
        caseType: values.caseTypeSelect,
        typeInvoice: values.atcTypeTicket,
        invoiceATC: values.atcTicket,
      }

      if (RegistrationCaseType.includes(request_dic.caseType) && !caseRecord.hospitalDateHire) {
        request_dic.hospitalDateHire = hospital_datehire
      }

      update(
        request_dic,
        id
      ).then((response) => {
        if (props.data.caseType !== values.caseTypeSelect) {
          save({
            comment: "Se realizo Actualizacion de Tramite en Mesa de Control.",
            event: "Mesa de Control",
            caseType: values.caseTypeSelect,
            userId: userAuth.idUser
          }, slug).then((response) => {});
          $("#btn_save_comment").click();
        }
        setCaseRecord({ ...caseRecord, caseType: values.caseTypeSelect, typeInvoice: values.atcTypeTicket, invoiceATC: values.atcTicket});
        setShoudBlockCase(false);
        alert("Actualizado");
      });

      setSubmitting(false);
    }, 1000);

    if ((values.caseTypeSelect === "Revision de corte" || values.caseTypeSelect === "Alta hospitalaria") && isHospitalAtc(caseRecord)) {
      await saveReviewCuts({ caseType: values.caseTypeSelect, typeInvoice: values.atcTypeTicket, invoiceATC: values.atcTicket, caseId: caseRecord.id, userId: userAuth.idUser });
      getAllReviewCutsInit();
    }
  }

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize={true}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
          submitForm,
          setFieldValue,
        }) => {
          return (
            <>
              <AlertError status={status} />
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="caseTypeSelect">
                      <Form.Label>Tipo de TRÁMITE:</Form.Label>
                      <Form.Control
                        as="select"
                        name="caseTypeSelect"
                        value={values.caseTypeSelect || ""}
                        onChange={ e => {
                          handleChange(e);
                          setCaseType(e.target.value);
                          if (isHospitalAtc(caseRecord)) {
                            setTotalDeviations(false);
                          } else {
                            setTotalDeviations(true);
                          }
                        }}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "caseTypeSelect"
                        )}`}
                      >
                        <option value="">SELECCIONE UNA OPCIÓN....</option>
                        {casesTypes.map( (caseType, idx) => (
                          <option key={idx} value={caseType}>{caseType}</option>
                        ))}
                      </Form.Control>
                      <ErrorMessage name="caseTypeSelect">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row hidden={totalDeviations}>
                  <Col>
                    <Form.Group controlId="atcTicket">
                      <Form.Label>Folio ATC:</Form.Label>
                      <Form.Control
                        name="atcTicket"
                        value={values.atcTicket || ""}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "atcTicket"
                        )}`}
                      />
                      <ErrorMessage name="atcTicket">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row hidden={totalDeviations}>
                  <Col>
                    <Form.Group controlId="atcTypeTicket">
                      <Form.Label>Tipo de folio ATC:</Form.Label>
                      <Form.Control
                        as="select"
                        name="atcTypeTicket"
                        value={values.atcTypeTicket || ""}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "atcTypeTicket"
                        )}`}
                      >
                        <option value="">SELECCIONE UNA OPCIÓN....</option>
                        <option value="Alta"> Alta </option>
                        <option value="Corte">Corte</option>
                      </Form.Control>
                      <ErrorMessage name="atcTypeTicket">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                 <Row>
                  <Col>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="dark"
                          size="sm"
                        />
                      )}
                      Actualizar
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          );
        }}
      </Formik>

      {( caseType === "Revision de corte" || caseType === "Alta hospitalaria" ) && isHospitalAtc(props.data) && <ListReviewCuts reviewCuts={reviewCuts} />}
    </>
  );
};
