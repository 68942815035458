import React from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  InputGroup,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
// import { storeLetterMvByCase } from "@services/letterMovsService";
import NumberFormat from "react-number-format";

export const MedicalOpinionData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            DICTAMEN MÉDICO
          </Accordion.Toggle>
          <i className="fa fa-pencil-alt" style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicalOpinionData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentMedicalOpinionData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        DICTAMEN MÉDICO
      </Alert>

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <b>Categoria AXA (Mesa de Control): </b>
                  {props.data.axaCategories}
                  <br />
                  <b>Enfermera (o) Asignado (o): </b>
                  {props.data.nurse?.fullName}
                  <br />
                  <b>Concierge Asignado: </b>
                  {props.data.concierge?.conciergeName}
                  <br />
                  <b>Medico Asignado: </b>
                  {props.data.doctor?.fullName}
                  <br />
                  <b>Celula: </b>
                  {props.data.cell?.cellDescription}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row md={4} className="ml-1">
            <Alert
              variant="primary"
              className="text-center mt-4"
              style={{ fontSize: "13px" }}
            >
              Captura Datos de la Carta
            </Alert>
          </Row>
          <FormMedicalOpinionData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormMedicalOpinionData = (props) => {
  const initialValues = {
    enough: props.data.enoughYesNo || "",
    statusDictum: props.data.statusDictum || "",
    complexCase: "", //props.data.complexCase || "",
    movementsMedicalOpinion: props.data.movementsMedicalOpinion || "",
    caseAccountability: props.data.caseAccountability || "",
    motiveAccountability: props.data.motiveAccountability || "",
    comprehensiveCare: props.data.comprehensiveCare || '',
  };

  const { id } = props.data;
  const { bindSubmitForm, bindErrors } = props;

  const isCaseAccountability = props.data.caseType === "Reconsideración de Dictamen" || props.data.caseType === "Reconsideración de Alta";

  const validationSchema = Yup.object().shape({
    enough: Yup.string()
        .required("El campo es requerido"),
    statusDictum: Yup.string()
        .required("El campo es requerido"),
    movementsMedicalOpinion: Yup.string()
        .matches(
            /^[0-9]+$/,
            {
              excludeEmptyString: true,
              message: 'El campo solo permite caracteres numericos',
            }
        ),
    caseAccountability: isCaseAccountability ? Yup.string().required("El campo es requerido") : null,
    motiveAccountability: isCaseAccountability ? Yup.string().required("El campo es requerido") : null,
  });

  const optionsAccountabilityYes = [
      "Nombre incorrecto de hospital y/o médico y/o asegurado", "Aplicación de deducible, coaseguro, tope de coaseguro erróneo",
      "No se aplicó endoso", "Visado erroneo por parte de AXA", "No se revisó la información por AXA  para dictamen o tabulación",
      "Solicitud de información innecesaria",
  ];
  const optionsAccountabilityNo = ["Error de hospital", "Envío inoportuno de información por parte de hospital", "Solicitud de visado por error de hospital", "Ajuste tardío de médicos no red"];

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    values.enoughYesNo = values.enough;
    values.dictumReason = values.statusDictum;
    await update(values, id).then((response) =>
      console.log("Form medic assignation traking inside saved")
    );
  }

  return (
    <>
    <Row>
      <Col>
        <Form.Group controlId="amount">
          <Form.Label>Importe Carta Autorizacion</Form.Label>
          <InputGroup className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
            </InputGroup.Prepend>
            <NumberFormat
              disabled
              fixedDecimalScale={true}
              decimalScale={2}
              value={props.totalLetters || "0"}
              name="amount"
              thousandSeparator={true}
              className={`form-control`}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        bindSubmitForm(submitForm);
        bindErrors(errors);
        return (
          <>
            <AlertError status={status} />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="enough">
                    <Form.Label>Carta Suficiente *</Form.Label>
                    <div className="mb-3">
                      <Form.Check
                        name="enough"
                        inline
                        label="SI"
                        id="si"
                        value="si"
                        type="radio"
                        disabled={props.disabledAll}
                        checked={values.enough === "si"}
                        onChange={handleChange}
                      />
                      <Form.Check
                        name="enough"
                        inline
                        label="NO"
                        id="no"
                        value="no"
                        type="radio"
                        disabled={props.disabledAll}
                        checked={values.enough === "no"}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="enough">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="statusDictum">
                    <Form.Label>Estatus Dictamen *</Form.Label>
                    <Form.Control
                      as="select"
                      name="statusDictum"
                      value={values.statusDictum}
                      disabled={props.disabledAll}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "Rechazo Definitivo") {
                          props.setIsFinalReject(true);
                          setFieldValue('enough', 'no');
                        } else {
                          props.setIsFinalReject(false);
                        }
                      }}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "statusDictum"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      <option value="Autorizado">Autorizado</option>
                      <option value="Rechazo Definitivo">
                        Rechazo Definitivo
                      </option>
                      <option value="Informacion Adicional">
                        Informacion Adicional
                      </option>
                      <option value="Revision Aumento de Suma">
                        Revision Aumento de Suma
                      </option>
                      <option value="Revision Tabulaciones">
                        Revision Tabulaciones
                      </option>
                    </Form.Control>
                    <ErrorMessage name="statusDictum">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="comprehensiveCare">
                    <Form.Label>Cuidado Integral</Form.Label>
                    <div className={`${getInputClasses(
                        touched,
                        errors,
                        "comprehensiveCare"
                    )}`}>
                      <Form.Check
                          custom
                          inline
                          label="SI"
                          id="comprehensiveCareSi"
                          type="radio"
                          value="si"
                          checked={values.comprehensiveCare === 'si'}
                          onChange={handleChange}
                          name="comprehensiveCare"
                      />
                      <Form.Check
                          custom
                          inline
                          label="NO"
                          id="comprehensiveCareNo"
                          type="radio"
                          value="no"
                          checked={values.comprehensiveCare === 'no'}
                          onChange={handleChange}
                          name="comprehensiveCare"
                      />
                    </div>
                    <ErrorMessage name="comprehensiveCare">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="complexCase">
                    <Form.Label>Caso complejo</Form.Label>
                    <div className="mb-3">
                      <Form.Check
                        name="complexCase"
                        inline
                        label="SI"
                        id="siComplexCase"
                        value="si"
                        type="radio"
                        checked={values.complexCase === "si"}
                        disabled={props.disabledAll}
                        onChange={ e => {
                          handleChange(e);
                          alert("Recuerda que solo aplica si en esta solicitud te piden más de 2 siniestros, más de 3 procedimientos quirúrgicos, "
                          + "más de 15 documentos para revisar, reporte a ATF y Rescisiones, o si tienes que realizar más de 2 de las "
                          + "siguientes opciones: más de 10 DEIH, más de 3 interconsultas, reconsideración procedente.");
                        }}
                      />
                      <Form.Check
                        name="complexCase"
                        inline
                        label="NO"
                        id="noComplexCase"
                        value="no"
                        type="radio"
                        checked={values.complexCase === "no"}
                        disabled={props.disabledAll}
                        onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="complexCase">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="movementsMedicalOpinion">
                    <Form.Label>Movimientos</Form.Label>
                    <Form.Control
                      as="select"
                      name="movementsMedicalOpinion"
                      value={values.movementsMedicalOpinion}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "movementsMedicalOpinion"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Form.Control>
                    <ErrorMessage name="movementsMedicalOpinion">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row hidden={!isCaseAccountability}>
                <Col>
                  <Form.Group controlId="caseAccountability">
                    <Form.Label>CASO IMPUTABLE A AXA *</Form.Label>
                    <div className="mb-3">
                      <Form.Check
                          name="caseAccountability"
                          inline
                          label="SI"
                          id="siCaseAccountability"
                          value="si"
                          type="radio"
                          checked={values.caseAccountability === "si"}
                          disabled={props.disabledAll}
                          onChange={handleChange}
                      />
                      <Form.Check
                          name="caseAccountability"
                          inline
                          label="NO"
                          id="noCaseAccountability"
                          value="no"
                          type="radio"
                          checked={values.caseAccountability === "no"}
                          disabled={props.disabledAll}
                          onChange={handleChange}
                      />
                    </div>
                    <ErrorMessage name="caseAccountability">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row hidden={!isCaseAccountability}>
                <Col>
                  <Form.Group controlId="motiveAccountability">
                    <Form.Label>Motivo de imputabilidad *</Form.Label>
                    <Form.Control
                        as="select"
                        name="motiveAccountability"
                        value={values.motiveAccountability}
                        disabled={props.disabledAll}
                        onChange={handleChange}
                        className={`${getInputClasses(
                            touched,
                            errors,
                            "motiveAccountability"
                        )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {values.caseAccountability === "si" && optionsAccountabilityYes?.map( (accountabilityOption, idx) => (
                          <option key={idx} value={accountabilityOption}>{accountabilityOption}</option>
                      ))}
                      {values.caseAccountability === "no" && optionsAccountabilityNo?.map( (accountabilityOption, idx) => (
                          <option key={idx} value={accountabilityOption}>{accountabilityOption}</option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="motiveAccountability">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
    </>
  );
};
