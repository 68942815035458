import React, { useState, useEffect } from "react";
import {
    Accordion,
    Alert, Button, Col, Row,
} from "react-bootstrap";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import {
    getBySlug, update,
} from "@services/hospitalizationcrud";
import { getLastLetterMovByCase } from "@services/letterMovsService";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";
import axios from "axios";
import moment from "moment/moment";
import {useHistory} from "react-router-dom";
import {GetUser} from "@metronic/_helpers";
import {PROCEDURE_TYPES} from "../../../helpers/constants";

const CompletedCasesDetails = (props) => {
    const subheader = useSubheader();

    const { slug } = props.match.params;
    const { idUser } = GetUser().user;

    const [caseRecord, setCaseRecord] = useState({});
    const [, setLetterMov] = useState({});
    const [, setTotalLetters] = useState("0");
    const [shouldBlockCase, setShouldBlockCase] = useState(true);
    const [caseType, setCaseType] = useState("");
    const [note, setNote] = useState('');

    const history = useHistory();

    useEffect(() => {
        subheader.setTitle("Solicitudes Extemporáneas");
    }, [subheader]);

    useEffect(() => {
        window.wasCommented = false;
        getBySlug(slug).then((response) => {
            setCaseRecord(response.data);
            window.requires_tabulation = response.data.requiresTabulation;
            getLastLetterMovByCase(response.data.id).then((currentLetterMov) =>
                setLetterMov(currentLetterMov.data)
            );
        });
    }, [slug]);

    const onSubmitCaseType = async (e) => {
        e.preventDefault();

        if (caseType === '') {
            alert('El tipo de trámite es obligatorio');
            return;
        }

        update({ caseType }, caseRecord.id).then((response) =>{
            $("#btn_save_comment").click();
            alert("Actualizado");
        });

        setCaseRecord({ ...caseRecord, ...{ caseType } });
    }

    const onClickSendExtemporaneous = async () => {
        if (caseType === "") {
            alert("Debe actualizar el tipo de tramite");
            return;
        }

        if (!window.wasCommented) {
            alert("Debe ingresar un comentario");
            return;
        }

        $("#btn_save_comment").click();

        let timestamp = await axios.get('/cases/getTimestamp');

        const dataUpdateCase =  {
            caseStatusId: 12,
            fecextemporaneous: moment.utc(timestamp.data).format(),
            userId: idUser,
            caseType: caseType,
        };

        update(dataUpdateCase, caseRecord.id).then((response) => {
            history.push("/casos_finalizados_hospital");
        });

        setCaseRecord({ ...caseRecord, ...{ caseType } });
    }

    return (
        <>
            <Alert
                variant="success"
                className="text-center"
                style={{ fontSize: "16px" }}
            >
                Solicitudes Extemporáneas
            </Alert>

            <CaseHeader
                caseRecord={caseRecord}
                isSingleHeader={true}
                caseTypeSlot={
                    <>
                        <Row className="mt-2 align-items-center">
                            <Col className="col-auto pr-0">
                                <b>Tipo de TRÁMITE: *</b>
                            </Col>
                            <Col className="col-auto">
                                <select
                                    className="form-control"
                                    name="caseTypeSelect"
                                    value={caseType}
                                    onChange={e => {
                                        setCaseType(e.target.value);
                                        switch (e.target.value) {
                                            case PROCEDURE_TYPES.FEE_TABULATION:
                                                setNote('Especificar la Información del Médico Tratante a Tabular en el Apartado de Honorarios Medicos. Adjunta la Documentación Actualizada en caso de ser necesario.');
                                                break;
                                            case PROCEDURE_TYPES.VISA_VALIDATION_ATC:
                                                setNote('Especifica el Trámite solicitado en la Bitácora de Comentarios. Si necesitas un Nuevo Visado, por favor indica el Folio ATC.');
                                                break;
                                            case PROCEDURE_TYPES.AUTHORIZATION_LETTER_MODIFICATION:
                                                setNote('Especifica el Trámite solicitado en la Bitácora de Comentarios. Si necesitas un Nuevo Visado, por favor indica el Folio ATC.');
                                                break;
                                            default:
                                                setNote('');
                                                break;
                                        }
                                    }}
                                >
                                    <option value="">SELECCIONE UNA OPCIÓN....</option>
                                    <option value="Tabulación de Honorarios">
                                        Tabulación de Honorarios
                                    </option>
                                    <option value="Visado / Validación de ATC">
                                        Visado / Validación de ATC
                                    </option>
                                    <option value="Modificación Carta de Autorización">
                                        Modificación Carta de Autorización
                                    </option>
                                </select>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col hidden={note === '' || note === null}>
                                <b>Nota: </b> {note}
                            </Col>
                        </Row>
                    </>
                }
            />

            <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
                <Accordion defaultActiveKey="0" className="mt-5">
                    <Documentation
                        icon="fa-pencil-alt"
                        eventKey="4"
                        data={caseRecord}
                        {...props}
                    />
                    <MedicalFees
                        icon="fa-search"
                        eventKey="6"
                        data={caseRecord}
                        hideFormCreate={false}
                        ignoreStatus={true}
                        {...props}
                    />
                    <AuthLetters
                        icon="fa-search"
                        showForm={false}
                        hideDeleteOption={true}
                        eventKey="3"
                        data={caseRecord}
                        disabledAll={false}
                        setTotalLetters={setTotalLetters}
                        {...props}
                    />
                    <InssuredData
                        icon="fa-search"
                        eventKey="1"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={() => {
                        }}
                    />
                    <HospitalData
                        icon="fa-search"
                        eventKey="2"
                        data={caseRecord}
                        disabledAll={true}
                        bindSubmitForm={() => {
                        }}
                    />
                </Accordion>
                <Accordion defaultActiveKey="5" className="mt-5">
                    <Binnacle
                        icon="fa-search"
                        eventKey="5"
                        data={caseRecord}
                        eventStage="Casos Finalizados Hospital"
                        isDifferentBackground={true}
                        isPageable={true}
                        {...props}
                    />
                </Accordion>
            </BlockCaseContext.Provider>

            <Row>
                <Col className="mt-5">
                    <Button variant="danger" onClick={onClickSendExtemporaneous}>
                        Enviar a Dictamen Extemporáneos
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default CompletedCasesDetails;
