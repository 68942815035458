import React from "react";
import { Col, Row, Card } from "react-bootstrap";

const CaseHeader = ({ caseRecord, ...props }) => {
  if (props.isSingleHeader) {
    return (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                {props.keySlot || (
                    <>
                      <b>Clave: </b>
                      {caseRecord.caseTicket}
                    </>
                )}
                <br />
                {props.insuredSlot || (
                    <>
                      <b>Asegurado: </b>
                      {`${caseRecord.assuredName} ${caseRecord.assuredFlastname} ${caseRecord.assuredMlastname}`}
                    </>
                )}
                <br />
                {props.diagnosisSlot || (
                    <>
                      <b>Diagnostico Presuntivo: </b>
                      {caseRecord.diagnosis}
                    </>
                )}
                <br />
                {props.hospitalNameSlot || (
                    <>
                      <b>Hospital: </b>
                      {caseRecord.hospital?.hospitalName}
                      <br />
                    </>
                )}
                {props.caseTypeSlot || (
                    <>
                      <b>Tipo de TRÁMITE: </b>
                      {caseRecord.caseType}
                      <br />
                    </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
    )
  }

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body>
            {props.keySlot || (
              <>
                <b>Clave: </b>
                {caseRecord.caseTicket}
              </>
            )}
            <br />
            {props.insuredSlot || (
              <>
                <b>Asegurado: </b>
                {`${caseRecord.assuredName} ${caseRecord.assuredFlastname} ${caseRecord.assuredMlastname}`}
              </>
            )}
            <br />
            {props.incomeTypeSlot || (
              <>
                <b>Tipo de Ingreso: </b>
                {caseRecord.incomeType}
              </>
            )}
            <br />
            {props.comprehensiveCare || (
              <>
                <b>Cuidado Integral: </b>
                {caseRecord.comprehensiveCare || 'NO'}
              </>
            )}
            <br />
            {props.diagnosisSlot || (
              <>
                <b>Diagnostico Presuntivo: </b>
                {caseRecord.diagnosis}
              </>
            )}
            <br />
            {props.requiresTabulationSlot || (
              <>
                <b>Solicitud de tabulacion: </b>
                {caseRecord.requiresTabulation}
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card>
          <Card.Body>
            {props.hospitalIdenSlot || (
              <>
                <b>Folio Hospital / Episodio: </b>
                {caseRecord.hospitalIden}
                <br />
              </>
            )}
            {props.hospitalNameSlot || (
              <>
                <b>Hospital: </b>
                {caseRecord.hospital?.hospitalName}
                <br />
              </>
            )}
            {props.hospitalModelSlot || (
              <>
                <b>Modelo: </b>
                {caseRecord.hospital?.model}
                <br />
              </>
            )}
            {props.caseTypeSlot || (
              <>
                <b>Tipo de TRÁMITE: </b>
                {caseRecord.caseType}
                <br />
              </>
            )}
            {caseRecord.hospital?.atc?.toLowerCase() === 'si' && (props.invoiceAtcSlot || (
              <>
                <b>Folio estado de cuenta ATC: </b>
                {caseRecord.invoiceATC}
                <br />
              </>
            ))}
            {caseRecord.hospital?.atc?.toLowerCase() === 'si' && (props.typeInvoiceAtc || (
              <>
                <b>Tipo de folio ATC: </b>
                {caseRecord.typeInvoice}
                <br />
              </>
            ))}
            {caseRecord.hospital?.atc?.toLowerCase() === 'no' && (
              <>
                  <b>Tipo de visado: </b>
                  Manual
                  <br />
              </>
            )}
            {props.rightHeaderSlot}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CaseHeader;
