import axios from "axios";

export const BASE_ROUTE = "review_cuts";

export let getAllReviewCuts = function(caseId) {
  return axios.get(`${BASE_ROUTE}/${caseId}`);
};

export let saveReviewCuts = function(data) {
  return axios.post(`${BASE_ROUTE}`, data);
};

export let updateReviewCuts = function(data, id) {
  return axios.put(`${BASE_ROUTE}/${id}`, data);
};