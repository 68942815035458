import React from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  Spinner, InputGroup,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
import { ListReviewCuts } from "../InsuredFile/ReviewCuts/ListReviewCuts";
import { useState } from "react";
import { getAllReviewCuts } from "@services/reviewCutsService";
import { useEffect } from "react";
import {isHospitalAtc, isNotHospitalAtc} from "@utils/checkHospitalAtc";
import NumberFormat from "react-number-format";

export const CostControlData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Control de Costos
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentCostControlData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentCostControlData = (props) => {
  const formatToCurrency = amount => "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");

  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Control de Costos
      </Alert>

      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <b>CATEGORÍA AXA (Mesa de Control): </b>{props.data.axaCategories}
                  <br />
                  <b>Enfermera (o) Asignado (o): </b>{props.data.nurse?.fullName}
                  <br />
                  <b>Concierge Asignado: </b>{props.data.concierge?.conciergeName}
                  <br />
                  <b>Medico Asignado: </b>{props.data.doctor?.fullName}
                  <br />
                  <b>Celula: </b>{props.data.cell?.cellDescription}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row md={4} className="ml-1">
            <Alert
              variant="primary"
              className="text-center mt-4"
              style={{ fontSize: "13px" }}
            >
              Datos de la Carta
            </Alert>
          </Row>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <b>Importe Carta Autorizacion: </b>{formatToCurrency(props.totalLetters)}
                  <br />
                  <b>Carta Suficiente: </b>{ props.data.enoughYesNo }
                  <br />
                  <b>Estatus Dictamen: </b>{ props.data.statusDictum }
                  <br />
                  <div hidden={isHospitalAtc(props.data)}><b>Importe Total de Desvíos: </b>${props.data.totalDeviations}</div>
                  <br />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <FormCostControlData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormCostControlData = ({ showForm = true, ...props }) => {
  const initialValues = {
    statusCC: props.data.statusCC || "",
    voBoLetter: props.data.voBoLetter || "",
    totalDeviations: props.data.totalDeviations || "",
  };

  const validationSchema = Yup.object().shape({
    statusCC: Yup.string().required("El campo es requerido"),
    voBoLetter: Yup.string().required("El campo es requerido"),
    totalDeviations: isNotHospitalAtc(props.data) ? Yup.string().required("El campo es requerido") : '',
  });

  const { id } = props.data;
  const { bindSubmitForm, bindErrors } = props;
  const [reviewCuts, setReviewCuts] = useState([]);

  useEffect(() => {
    if (props.reviewCuts)
      setReviewCuts(props.reviewCuts);
    else if (id)
      getAllReviewCutsInit();
      
  }, [props.reviewCuts, id]);

  const getAllReviewCutsInit = () => {
    getAllReviewCuts(id).then((response) => {
      setReviewCuts(response.data);
    });
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    await update(values, id).then((response) => {
      console.log("Form hospital data inside saved");
      setSubmitting(false);
    }).catch( err => setSubmitting(false));
  }

  return (
    <>
      {showForm && <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
          submitForm,
        }) => {
          bindSubmitForm(submitForm);
          bindErrors(errors);
          return (
            <>
              <AlertError status={status} />
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group controlId="statusCC">
                      <Form.Label>Estatus *</Form.Label>
                      <Form.Control
                        as="select"
                        name="statusCC"
                        value={values.statusCC}
                        onChange={handleChange}
                        disabled={props.disabledAll}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "statusCC"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="Finalizada">Finalizada</option>
                      </Form.Control>
                      <ErrorMessage name="statusCC">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="voBoLetter">
                      <Form.Label>VoBo. Carta de Autorizacion *</Form.Label>
                      <Form.Control
                        as="select"
                        name="voBoLetter"
                        value={values.voBoLetter}
                        onChange={handleChange}
                        disabled={props.disabledAll}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "voBoLetter"
                        )}`}
                      >
                        <option value="">Selecciona una opcion...</option>
                        <option value="OK">OK</option>
                        <option value="No procede">No procede</option>
                      </Form.Control>
                      <ErrorMessage name="voBoLetter">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row hidden={props.data?.hospital?.atc?.toLowerCase() === 'si'}>
                  <Col>
                    <Form.Group controlId="totalDeviations">
                      <Form.Label>Importe Total de Desvíos *</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <NumberFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            value={values.totalDeviations}
                            onChange={handleChange}
                            name="totalDeviations"
                            thousandSeparator={true}
                            disabled={props.disabledAll}
                            className={`form-control ${getInputClasses(
                                touched,
                                errors,
                                "totalDeviations"
                            )}`}
                        />
                      </InputGroup>
                      <ErrorMessage name="totalDeviations">
                        {(msg) => (
                            <Form.Control.Feedback type="invalid">
                              {msg}
                            </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="dark"
                      size="sm"
                    />
                  )}
                  Guardar
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>}

      {(props.data.caseType === "Revision de corte" || props.data.caseType === "Alta hospitalaria") && isHospitalAtc(props.data) && <ListReviewCuts reviewCuts={reviewCuts} />}
    </>
  );
};
