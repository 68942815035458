/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive, IfHasPermission } from "../../../../_helpers";


export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>

          {/*begin::1 Level*/}
          { IfHasPermission("Doctores") && (
            <li
                className={`menu-item ${getMenuItemActive("/doctores", false)}`}
            >
              <NavLink className="menu-link" to="/doctores">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                </span>
                <span className="menu-text">Doctores</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          { IfHasPermission("Enfermeras") && (
            <li
                className={`menu-item ${getMenuItemActive("/enfermeras", false)}`}
            >
              <NavLink className="menu-link" to="/enfermeras">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                </span>
                <span className="menu-text">Enfermeras</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          { IfHasPermission("Hospitales") && (
            <li
                className={`menu-item ${getMenuItemActive("/hospitales", false)}`}
            >
              <NavLink className="menu-link" to="/hospitales">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Half-heart.svg")}/>
                </span>
                <span className="menu-text">Hospitales</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}

            {/*begin::1 Level*/}
            { IfHasPermission("Concierges") && (
                <li
                    className={`menu-item ${getMenuItemActive("/concierges", false)}`}
                >
                  <NavLink className="menu-link" to="/concierges">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                    </span>
                    <span className="menu-text">Concierges</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}
          
          {/*begin::1 Level*/}
          { IfHasPermission("Privilegios") && (
            <li
                className={`menu-item ${getMenuItemActive("/privilegios", false)}`}
            >
              <NavLink className="menu-link" to="/privilegios">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}/>
                </span>
                <span className="menu-text">Privilegios</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}

          {/*begin::1 Level*/}
          { IfHasPermission("Roles") && (
            <li
                className={`menu-item ${getMenuItemActive("/roles", false)}`}
            >
              <NavLink className="menu-link" to="/roles">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}/>
                </span>
                <span className="menu-text">Roles</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}
          
          {/*begin::1 Level*/}
          { IfHasPermission("Usuarios") && (
            <li
                className={`menu-item ${getMenuItemActive("/usuarios", false)}`}
            >
              <NavLink className="menu-link" to="/usuarios">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
                </span>
                <span className="menu-text">Usuarios</span>
              </NavLink>
            </li>
          )}
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
