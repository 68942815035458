import axios from "axios";

export const GET_LAST_BY_CASE = "mc_movements/getLastByCase";
export const STORE_BY_CASE = "mc_movements/storeMcMovementByCase";
export const UPDATE_BY_CASE = "mc_movements/updateMcMovementByCase";


export const getLasMcMovementtByCase = function (caseId) {
  return axios.get(`${GET_LAST_BY_CASE}/${caseId}`);
}

export const storeMcMovementByCase = (caseSlug, data) => {
  return axios.post(`${STORE_BY_CASE}/${caseSlug}`, data);
}