import React from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";

export const MedicDataTreating = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos del Medico Tratante
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicDataTreating {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentMedicDataTreating = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Datos del Medico Tratante
      </Alert>

      <Card>
        <Card.Body>
          <FormMedicDataTreating {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormMedicDataTreating = (props) => {
  const initialValues = {
    treatmentDoctorName: props.data.treatmentDoctorName || "",
    treatmentDoctorPhone: props.data.treatmentDoctorPhone || "",
    treatmentDoctorExt: props.data.treatmentDoctorExt || "",
    doctorsNetId: props.data.doctorsNetId || "",
  };

  const { id } = props.data;

  const handleSubmitForm = (values, { setStatus, setSubmitting }) => {
    update({ ...props.data, ...values }, id).then((response) =>
      console.log("Form medic data treating inside saved")
    );
  };

  const { bindSubmitForm } = props;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
      }) => {
        bindSubmitForm(submitForm);
        return (
          <>
            <AlertError status={status} />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="treatmentDoctorName">
                    <Form.Label>Medico Tratante</Form.Label>
                    <Form.Control
                      type="text"
                      name="treatmentDoctorName"
                      disabled={props.disabledAll}
                      value={values.treatmentDoctorName}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "treatmentDoctorName"
                      )}`}
                    />
                    <ErrorMessage name="treatmentDoctorName">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="treatmentDoctorPhone">
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control
                      type="phone"
                      name="treatmentDoctorPhone"
                      disabled={props.disabledAll}
                      value={values.treatmentDoctorPhone}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "treatmentDoctorPhone"
                      )}`}
                    />
                    <ErrorMessage name="treatmentDoctorPhone">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="treatmentDoctorExt">
                    <Form.Label>Exntension</Form.Label>
                    <Form.Control
                      type="number"
                      name="treatmentDoctorExt"
                      disabled={props.disabledAll}
                      value={values.treatmentDoctorExt}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "treatmentDoctorExt"
                      )}`}
                    />
                    <ErrorMessage name="treatmentDoctorExt">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="doctorsNetId">
                    <Form.Label>Red</Form.Label>
                    <Form.Control
                      as="select"
                      name="doctorsNetId"
                      disabled={props.disabledAll}
                      value={values.doctorsNetId}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "doctorsNetId"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      <option value="1">Convenio</option>
                    </Form.Control>
                    <ErrorMessage name="doctorsNetId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
