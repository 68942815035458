import React, { useState, useEffect } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { getAll } from "@services/hospitalsService";
import { update } from "@services/hospitalizationcrud";
import { INCOME_TYPE } from "@helpers/incomeType";
import { shallowEqual, useSelector } from "react-redux";
import {isNotEmpty} from "../../../_utils/checkObjectEmpty";

export const HospitalData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos Hospital
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentHospitalData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentHospitalData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Datos Hospital
      </Alert>

      <Card>
        <Card.Body>
          <FormHospitalData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormHospitalData = (props) => {
  const [hospitals, setHospitals] = useState([]);
  const [incomeTypes, setIncomeTypes] = useState([]);

  const {role, hospital, user} = useSelector(
    ({auth}) => ({
        role: auth.user?.role,
        hospital: auth.user?.hospital,
        user: auth.user,
    }),
    shallowEqual
  );

  const { userType } = user;

  const getHospitals = () => {
        getAll().then((data) => {
            let data_hospital = [ ...data.data ];
            if (userType === "Hospital") {
              const { hospitalName } = hospital;
              data_hospital = data_hospital.filter(item => item.hospitalName === hospitalName);
            }

            if (role.name === "AXA_HOSPITAL")
                setHospitals(data_hospital.filter(currHospital => currHospital.model === "MANUAL"));
            else
                setHospitals(data_hospital);
        });
  }

  useEffect(() => {
    getHospitals();
  }, []);

  useEffect(() => {
    if (props.scheduledSurgery === null && props.isNewCase === null) {
      incomeTypes.push({value: "Reporte hospitalario", label: "Reporte hospitalario"});
    } else if (props.scheduledSurgery === false && props.isNewCase === true) {
      incomeTypes.push({value: "Cirugia no programada", label: "Cirugía no programada"});
    } else if (props.scheduledSurgery === true && props.isNewCase === false) {
      incomeTypes.push({value: "Programacion de cirugia", label: "PROGRAMACIÓN DE CIRUGÍA"});
    } else if (isNotEmpty(props.data)) {
      incomeTypes.push({value: props.data.incomeType, label: props.data.incomeType});
    }
  }, [props.data]);

  const initialValues = {
    incomeType: props.data.incomeType || "",
    caseType: props.data.caseType || "Ingreso hospitalario",
    hospitalId: props.data.hospitalId || "",
    reporter: props.data.reporter || "",
    hospitalEmail: props.data.hospitalEmail || "",
    hospitalPhone: props.data.hospitalPhone || "",
    hospitalExtension: props.data.hospitalExtension || "",
    insuranceComp: props.data.insuranceComp || "AXA",
  };

  const { id } = props.data;

  const handleSubmitForm = (values, { setStatus, setSubmitting }) => {
    update({ ...props.data, ...values }, id).then((response) =>
      console.log("Form hospital data inside saved")
    );
  };

  const { bindSubmitForm } = props;

  const validationSchema = Yup.object().shape({
    incomeType: Yup.string()
        .required("El campo es requerido"),
    caseType: Yup.string()
      .required("El campo es requerido"),
    hospitalId: Yup.string()
      .required("El campo es requerido"),
    reporter: Yup.string()
      .required("El campo es requerido"),
    hospitalEmail: Yup.string()
      .required("El campo es requerido"),
    hospitalPhone: Yup.string()
      .required("El campo es requerido"),
    hospitalExtension: Yup.string()
      .required("El campo es requerido"),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
      }) => {
        bindSubmitForm(submitForm)
        return (
          <>
            <AlertError status={status} />
            <Form id="formu1" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="incomeType">
                    <Form.Label>Tipo de Ingreso *</Form.Label>
                    <Form.Control
                      as="select"
                      name="incomeType"
                      value={values.incomeType}
                      onChange={ e => {
                        handleChange(e);

                        if (e.target.value === INCOME_TYPE.SCHEDULED_SURGERY.value) {
                          props.setIsScheduledSurgery(true);
                        } else {
                          props.setIsScheduledSurgery(false);
                        }
                      }}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "incomeType"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {incomeTypes?.map((incomeType, idx) => (
                          <option key={idx} value={incomeType.value}>
                            {incomeType.label}
                          </option>
                      ))}
                      {/*<option value="Reporte hospitalario" hidden={props.scheduledSurgery !== null && props.isNewCase !== null}>*/}
                      {/*  Reporte hospitalario*/}
                      {/*</option>*/}
                      {/*<option value="Programacion de cirugia" hidden={props.scheduledSurgery === false && props.isNewCase === true}>*/}
                      {/*  PROGRAMACIÓN DE CIRUGÍA*/}
                      {/*</option>*/}
                      {/*<option value="Cirugia no programada" hidden={props.scheduledSurgery && !props.isNewCase}>*/}
                      {/*  Cirugía no programada*/}
                      {/*</option>*/}
                    </Form.Control>
                    <ErrorMessage name="incomeType">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="caseType">
                    <Form.Label>Tipo de TRÁMITE / Movimiento *</Form.Label>
                    <Form.Control
                      as="select"
                      name="caseType"
                      value={values.caseType}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "caseType"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      <option value="Ingreso hospitalario">
                        Ingreso Hospitalario
                      </option>
                    </Form.Control>
                    <ErrorMessage name="caseType">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="hospitalId">
                    <Form.Label>Hospital *</Form.Label>
                    <Form.Control
                      as="select"
                      name="hospitalId"
                      value={values.hospitalId}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "hospitalId"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {hospitals?.map((hospital, idx) => (
                        <option key={idx} value={hospital.id}>
                          {hospital.hospitalName}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="hospitalId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="reporter">
                    <Form.Label>Reportado por *</Form.Label>
                    <Form.Control
                      type="text"
                      name="reporter"
                      value={values.reporter}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "reporter"
                      )}`}
                    />
                    <ErrorMessage name="reporter">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="hospitalEmail">
                    <Form.Label>Correo *</Form.Label>
                    <Form.Control
                      type="email"
                      name="hospitalEmail"
                      value={values.hospitalEmail}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "hospitalEmail"
                      )}`}
                    />
                    <ErrorMessage name="hospitalEmail">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="hospitalPhone">
                    <Form.Label>TELÉFONO *</Form.Label>
                    <Form.Control
                      type="number"
                      name="hospitalPhone"
                      value={values.hospitalPhone}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                      className={`${getInputClasses(touched, errors, "hospitalPhone")}`}
                    />
                    <ErrorMessage name="hospitalPhone">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="hospitalExtension">
                    <Form.Label>EXTENSIÓN *</Form.Label>
                    <Form.Control
                      type="number"
                      name="hospitalExtension"
                      value={values.hospitalExtension}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "hospitalExtension"
                      )}`}
                    />
                    <ErrorMessage name="hospitalExtension">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
              <Col>
                  <Form.Group controlId="insuranceComp">
                    <Form.Label>ASEGURADORA</Form.Label>
                    <Form.Control
                      type="text"
                      name="insuranceComp"
                      value={values.insuranceComp}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "insuranceComp"
                      )}`}
                    />
                    <ErrorMessage name="insuranceComp">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
