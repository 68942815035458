import { ErrorMessage, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Card,
  Row,
  Col,
  Accordion,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import * as Yup from "yup";
import AlertError from "@components/AlertError";
import { getInputClasses } from "@utils/getInputclasses";
import { getAllMedicalFees, getAllProcessesTab, getAllSpecialities, saveMedicalFee } from "@services/medicalFeesService";
import { ListMedicalFees } from "./ListMedicalFees";
import { update } from "@services/hospitalizationcrud";
import { ContentAuthLetters } from "../AuthLetter/AuthLetters";

import BlockCaseContext from "@contexts/BlockCaseContext";

export const MedicalFees = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Honorarios Medicos
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
          <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicalFees {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentMedicalFees = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center"
        style={{ fontSize: "14px" }}
      >
        Honorarios Medicos
      </Alert>

      <Card>
        <Card.Body>
          <MedicalFeesForm {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const MedicalFeesForm = (props) => {
  const [medicalFees, setMedicalFees] = useState([]);
  const [requiresTab, setRequiresTab] = useState(null);
  const [, setTotalLetters] = useState("0");
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [specialities, setSpecialities] = useState([]);
  const [, setProcessesTab] = useState([]);
  const [, setShoudBlockCase] = useContext(BlockCaseContext);

  const { id, caseStatusId } = props.data;
  const { bindSubmitForm, bindErrors } = props;

  const initialValues = {
    medicName: '',
    speciality: '',
    rol: '',
    medicEmail: '',
    processDate: '',
    visitsNumber: '',
    processTab: '',
    cptTabulate: '',
  }

  const validationSchema = Yup.object().shape({
    medicName: Yup.string()
        .required("El campo es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "NOMBRE DEL MEDICO" solo permite caracteres alfabéticos'
        ),
    speciality: Yup.string()
        .required("El campo es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ,\s]+$/,
            'El campo "ESPECIALIDAD" solo permite caracteres alfabéticos'
        ),
    rol: Yup.string()
        .required("El campo es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "Otro" solo permite caracteres alfabéticos'
        ),
    medicEmail: Yup.string().required("El campo es requerido"),
    processDate: Yup.string().required("El campo es requerido"),
    visitsNumber: Yup.string()
        .required("El campo es requerido")
        .matches(
            /^[0-9]+$/,
            'El campo "NUMERO DE VISITAS" solo permite caracteres numericos'
        ),
            
  });


  useEffect(() => {
    if (id) {
      getMedicalFees(id);
    }
    
    if (props.data.requiresTabulation === "Si") setRequiresTab(true);
    else if (props.data.requiresTabulation === "No") setRequiresTab(false);

    getAllSpecialities().then( response => setSpecialities(response.data));
    getAllProcessesTab().then( response => setProcessesTab(response.data));
    
  
  }, [props.data]);

  const getMedicalFees = () => {
    getAllMedicalFees(id).then((response) => setMedicalFees(response.data));
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting, resetForm }) => {
    values.caseId = id;
    setTimeout(() => {
      saveMedicalFee(values).then(response => {
        setShoudBlockCase(false);
        console.log("Form medical fees inside saved");
        getMedicalFees();
        resetForm();
      });

      update({ requiresTabulation: requiresTab ? 'Si' : 'No' }, id);
      setSubmitting(false);
    }, 1000);
  }

  const SuggestionsListComponent = ({ setFieldValue, value }) => {
    const unLinked = specialities.filter((suggestion) => suggestion.description.toLowerCase().includes(value.toLowerCase()));

    return specialities.length ? (
      <ul className="suggestions">
        {unLinked.map((suggestion, index) => {
          let className;
  
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
  
          return (
            <li className={className} key={index} onClick={ e => {
              setFieldValue("speciality", suggestion.description);
              setShowSuggestions(false);
            }}>
              {suggestion.description}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions">
        <span role="img" aria-label="tear emoji">
          😪
        </span>{" "}
        <em>Sin sugerencias</em>
      </div>
    );
  };

  return (
    <>
      <Row hidden={caseStatusId === 8 && !props.ignoreStatus}>
        <Col>
          <Form.Group controlId="requireTab">
            <Form.Label>Requiere tabulacion *</Form.Label>
            <div className="mb-3">
              <Form.Check
                custom
                inline
                label="SI"
                id="si1"
                type="radio"
                value="Si"
                onChange={e => {
                  setRequiresTab(true);
                  window.requires_tabulation = "Si";
                }}
                checked={requiresTab === true}
                disabled={props.hideFormCreate}
                name="requireTab"
              />
              <Form.Check
                custom
                inline
                label="NO"
                id="no1"
                type="radio"
                value="No"
                onChange={e => {
                  setRequiresTab(false);
                  window.requires_tabulation = "No";
                }}
                checked={requiresTab === false}
                disabled={props.hideFormCreate}
                name="requireTab"
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      {requiresTab && (caseStatusId !== 8 || props.ignoreStatus) && !props.hideFormCreate && <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize={true}
        onSubmit={handleSubmitForm}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          status,
          isSubmitting,
          submitForm,
          setFieldValue,
        }) => {
          if (bindSubmitForm && bindErrors) {
            bindSubmitForm(submitForm);
            bindErrors(errors);
          }
          return (
            <>
              <AlertError status={status} />
              <Form onSubmit={handleSubmit} className="mb-4">
                <Row>
                  <Col>
                    <Form.Group controlId="medicName">
                      <Form.Label>Nombre del medico *</Form.Label>
                      <Form.Control
                        name="medicName"
                        value={values.medicName}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "medicName"
                        )}`}
                      />
                      <ErrorMessage name="medicName">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="speciality">
                      <Form.Label>Especialidad *</Form.Label>
                      <Form.Control
                        type="text"
                        name="speciality"
                        value={values.speciality}
                        onChange={ e => {
                          handleChange(e);
                          setShowSuggestions(true);
                          setActiveSuggestionIndex(0);
                        }}
                        autoComplete="off"
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "speciality"
                        )}`}
                      />

                      {showSuggestions && values.speciality && <SuggestionsListComponent value={values.speciality} setFieldValue={setFieldValue} />}
                      <ErrorMessage name="speciality">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                <Col>
                    <Form.Group controlId="rol">
                      <Form.Label>Equipo medico (Rol) *</Form.Label>
                      <div className="mb-3">
                        <Form.Check
                          custom
                          inline
                          label="Cirujano"
                          id="Cirujano"
                          type="radio"
                          value="Cirujano"
                          onChange={handleChange}
                          name="rol"
                        />
                        <Form.Check
                          custom
                          inline
                          label="Tratante"
                          id="Tratante"
                          type="radio"
                          value="Tratante"
                          onChange={handleChange}
                          name="rol"
                        />
                        <Form.Check
                          custom
                          inline
                          label="Anestesiologo"
                          id="Anestesiologo"
                          type="radio"
                          value="Anestesiologo"
                          onChange={handleChange}
                          name="rol"
                        />
                        <Form.Check
                          custom
                          inline
                          label="Ayudante"
                          id="Ayudante"
                          type="radio"
                          value="Ayudante"
                          onChange={handleChange}
                          name="rol"
                        />
                        <Form.Check
                          custom
                          inline
                          label="Interconsultante"
                          id="Interconsultante"
                          type="radio"
                          value="Interconsultante"
                          onChange={handleChange}
                          name="rol"
                        />
                        
                        <br />
                        <Form.Label className="mt-4">Otro</Form.Label>
                        <Form.Control
                          name="rol"
                          onChange={handleChange}
                          className={`${getInputClasses(
                            touched,
                            errors,
                            "rol"
                        )}`}
                      />
                      </div>
                      <ErrorMessage name="rol">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="medicEmail">
                      <Form.Label>E-mail medico *</Form.Label>
                      <Form.Control
                        name="medicEmail"
                        value={values.medicEmail}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "medicEmail"
                        )}`}
                      />
                      <ErrorMessage name="medicEmail">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="processDate">
                      <Form.Label>Fecha del procedimiento / visita *</Form.Label>
                      <Form.Control
                        type="date"
                        name="processDate"
                        value={values.processDate}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "processDate"
                        )}`}
                      />
                      <ErrorMessage name="processDate">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="visitsNumber">
                      <Form.Label>Numero de visitas *</Form.Label>
                      <Form.Control
                        name="visitsNumber"
                        value={values.visitsNumber}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "visitsNumber"
                        )}`}
                      />
                      <ErrorMessage name="visitsNumber">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="cptTabulate">
                      <Form.Label>CPT a tabular</Form.Label>
                      <Form.Control
                        name="cptTabulate"
                        value={values.cptTabulate}
                        onChange={handleChange}
                        className={`${getInputClasses(
                          touched,
                          errors,
                          "cptTabulate"
                        )}`}
                      />
                      <ErrorMessage name="cptTabulate">
                        {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                        )}
                      </ErrorMessage>
                    </Form.Group>
                  </Col>
                </Row>

                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      variant="dark"
                      size="sm"
                    />
                  )}
                  Guardar honorario medico
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>}

      <ListMedicalFees
        medicalFees={medicalFees}
        getMedicalFees={getMedicalFees}
        {...props} />

      <ContentAuthLetters
        medicalFees={medicalFees}
        setTotalLetters={setTotalLetters}
        titleAlert="Cartas de honorarios medicos"
        typeModule="Honorarios medicos"
        hideAmountLetter={true}
        {...props} />
    </>
  );
};
