//React, Bootstrap & Others
import React, { useState, useEffect } from "react";
import { Alert, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";

//Metronic
import { useSubheader } from "@metronic/layout";

//Components
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";

//Contexts
import CaseContext from "@contexts/CaseContext";

//Services
import { getAllControlBoardCostControlByFilter } from "@services/casesService";

export function ControlBoardCostControl() {
    const subheader = useSubheader();
    subheader.setTitle("Asignaciones de Control de Costos");

    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableParams, setTableParams] = useState({});
    const location = useLocation();

    useEffect(() => {
        const body = { pathName: location.pathname };
        getAllCasesControlBoard(body);
        
    }, []);

    const getAllCasesControlBoard = (body) => {
        getAllControlBoardCostControlByFilter(body).then((response) => {
            setData(response);
        });
    }

    const showTable = (cases, params) => {
        setTableParams(params);
        setTableData(cases);
    }

    return (
        <>
            <Alert
                variant="success"
                className="text-center"
                style={{ fontSize: "16px" }}
            >
                Asignaciones de Control de Costos
            </Alert>

            <CaseContext.Provider value={[data, setData]}>
                <GeneralFilter
                    getCasesOwnFunc={getAllControlBoardCostControlByFilter}
                    controlBoard={true}
                    hidePeriodEqual={false}
                    hidePeriodFrom={true}
                    hidePeriodTo={true}
                    hideInsurance={true}
                    hideRequiresTabulation={true}
                    hideInssuredName={true}
                    hideMovementType={true}
                    hidePresumptiveDiagnosis={true}
                    hideAxaCategoryId={true}
                    hideCategoryVip={true}
                    hideReason={true}
                    hideStatusPatient={true}
                    hideHospitalType={true}
                    hideConciergeAssigned={true}
                    hideDiffDays={true}
                    hideAmount={true}
                    hideUpdatePageBtn={false}
                />
            </CaseContext.Provider>

            <Table responsive striped bordered hover className="mt-5">
                <thead>
                <tr>
                    <th>Modulo</th>
                    <th>Enfermera Asignada</th>
                    <th>Solicitudes asignadas</th>
                    <th>Envio a dictamen medico</th>
                    <th>Envio a informacion medica</th>
                    <th>Envio a alta paciente</th>
                    <th>Solicitudes pendientes</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Control de Costos</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <th></th>
                </tr>
                {data?.map((currDoctor, idx) => (
                    <tr
                        key={idx}
                        onClick={(e) => {
                            showTable(currDoctor.cases, {
                                urlToGo: "/control_costos_detalles/",
                                reason: "Dictum",
                                assignationColumn: "feadictamen",
                                currModule: "Dictamen Medico",
                            });
                        }}
                    >
                        <td></td>
                        <td>{currDoctor.name}</td>
                        <td>{currDoctor?.control_desk + currDoctor?.medic_information + currDoctor?.cost_control + currDoctor?.pending_total_cases || "0"}</td>
                        <td>{currDoctor?.control_desk || "0"}</td>
                        <td>{currDoctor?.medic_information || "0"}</td>
                        <td>{currDoctor?.cost_control || "0"}</td>
                        <td>{currDoctor?.pending_total_cases || "0"}</td>
                    </tr>
                ))}
                </tbody>
            </Table>

            <GeneralTable
                urlToGo={tableParams.urlToGo}
                query={'?from=control_board'}
                data={tableData}
                activePage={1}
                totalItemsCount={tableData.length}
                hideReloadButton={true}
                reason={tableParams.reason}
                assignationColumn={tableParams.assignationColumn}
                currModule={tableParams.currModule}
            />
        </>
    );
}
