import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";
import { getCasesByStatuses } from "@services/casesService";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const CasesCanceled = () => {
    window.currModule = "Cancelaciones";
    window.assignationColumn = "feamesaControl";

    const subheader = useSubheader();

    const [casesRecord, setCasesRecord] = useState([]);
    const [paramsPagination, setParamsPagination] = useState({
        activePage: 1,
        totalPages: null,
        itemsCountPerPage: null,
        totalItemsCount: 0,
    });

    const getAllCasesByStatus = (pageNumber) => {
        var queryParams = [];

        if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
            queryParams = ['&byRegion=true', '&byCell=true'];
        }

        getCasesByStatuses([2, 9, 5, 6, 3, 4], pageNumber, queryParams).then((response) => {
            setCasesRecord(response.data);
            setParamsPagination({
                activePage: pageNumber,
                totalPages: response.totalPages,
                itemsCountPerPage: response.size,
                totalItemsCount: response.totalElements,
            });
        });
    }

    const handlePageChange = async (pageNumber) => {
        setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

        if (!paramsPagination.hasOwnProperty("fromFilter")) {
            getAllCasesByStatus(pageNumber);
        }
    }

    useEffect(() => {
        getAllCasesByStatus(paramsPagination.activePage);

        subheader.setTitle("Cancelaciones");
    }, []);

    return (
        <>
            <Alert
                variant="success"
                className="text-center"
                style={{ fontSize: "16px" }}
            >
                Cancelaciones
            </Alert>

            <CaseContext.Provider value={[
                casesRecord, setCasesRecord,
                paramsPagination, setParamsPagination
            ]}>
                <GeneralFilter
                    statuses={[2, 9, 5, 6, 3, 4]}
                    hideInsurance={true}
                    hideReason={false}
                    hideCategoryVip={true}
                    hideHospitalType={false}
                    hideHospitalInvoice={true}
                />
            </CaseContext.Provider>

            <GeneralTable
                urlToGo="/cancelaciones_detalles/"
                data={casesRecord}
                activePage={paramsPagination.activePage}
                totalItemsCount={paramsPagination.totalItemsCount}
                hidePatientStatusColumn={true}
                hideCelulaColumn ={false}
                reason="Cancelaciones"
                assignationColumn="feadictamen"
                currModule="Cancelaciones"
            />

            <div className="d-flex justify-content-center">
                <Pagination
                    activePage={paramsPagination.activePage}
                    itemsCountPerPage={paramsPagination.itemsCountPerPage}
                    totalItemsCount={paramsPagination.totalItemsCount}
                    pageRangeDisplayed={10}
                    itemClass="page-item"
                    linkClass="btn btn-light"
                    onChange={handlePageChange}
                />
            </div>
        </>
    );
};

export default CasesCanceled;
