import axios from "axios";

export const GET_ALL = "privileges";
export const SAVE = "privileges";
export const UPDATE = "privileges";
export const DELETE = "privileges";

export function getAll() {
    return axios.get(GET_ALL);
}

export function save(data) {
    return axios.post(SAVE, data);
}

export function update(data) {
    return axios.put(`${UPDATE}/${data.idPermission}`, data);
}

export function destroy(roleId) {
    return axios.delete(`${DELETE}/${roleId}`);
}