import axios from "axios";

export const BASE_ROUTE = "medical_fees";

export let getAllMedicalFees = function (caseId) {
  return axios.get(`${BASE_ROUTE}/${caseId}`);
}

export let saveMedicalFee = function (data) {
  return axios.post(`${BASE_ROUTE}`, data);
}

export let updateMedicalFee = function (data, id) {
  return axios.put(`${BASE_ROUTE}/${id}`, data);
}

export let getAllStatusesCategory = function (statuses = [], categorizationId) {
  const categorizationIdParam = categorizationId ? `&categorizationId=${categorizationId}` : '';
  return axios.get(`${BASE_ROUTE}/getStatusesCategory?statuses=${statuses}${categorizationIdParam}`);
}

export let getAllCategorizations = function (statuses = []) {
  return axios.get(`${BASE_ROUTE}/getCategorizations?statuses=${statuses}`);
}

export let getAllSpecialities = function () {
  return axios.get(`${BASE_ROUTE}/getSpecialities`);
}

export let getAllProcessesTab = function () {
  return axios.get(`${BASE_ROUTE}/getProcessesTab`);
}