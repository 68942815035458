import React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";

export const MedicalDataAdditional = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Informacion Medica Adicional
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentMedicalDataAdditional {...props} />
          </Card.Body>
        </Accordion.Collapse>
        
      </Card>
    </>
  );
};

export const ContentMedicalDataAdditional = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Informacion Medica Adicional
      </Alert>

      <Card>
        <Card.Body>
          <FormMedicalDataAdditional {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormMedicalDataAdditional = (props) => {
  const { disabled = false, bindSubmitForm } = props;
  const { id } = props.data;
  const checkboxOptions = props.checkboxOptions || [
    { value: "Nota de Evolución", label: "Nota de Evolución" },
    { value: "Fecha de Diagnostico", label: "Fecha de Diagnostico" },
    { value: "Estudios que confirme Dx", label: "Estudios que confirme Dx" },
    { value: "Estudios de Lab/Gabinete", label: "Estudios de Lab/Gabinete" },
    { value: "Nota Posquirurgica", label: "Nota Posquirurgica" },
    { value: "Hisotira Clinica", label: "Historia Clínica" },  // Note the correction in the label text
    { value: "Resumen Medico Actual", label: "Resumen Medico Actual" },
    { value: "Aviso de Accidente", label: "Aviso de Accidente" },
    { value: "Informe Medico Actualizado", label: "Informe Medico Actualizado" },
    { value: "Denuncia ante MP", label: "Denuncia ante MP" }
  ];

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    await update(values, id).then((response) =>
      console.log("Form medical data additional inside saved")
    );
  }

  return (
    <Formik initialValues={{ additionalMedicInformation: props.data.additionalMedicInformation }} onSubmit={handleSubmitForm} enableReinitialize={true}>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
      }) => {
        bindSubmitForm(submitForm);
        return (
          <>
            <AlertError status={status} />
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group>
                    <div role="group" aria-labelledby="checkbox-group">
                      {checkboxOptions?.map( ({value, label}) => (
                          <label className="mr-5" key={value}>
                            <Field
                                className="mr-1"
                                disabled={disabled}
                                name="additionalMedicInformation"
                                value={value}
                                type="checkbox"
                                onChange={handleChange}
                            />
                            {label}
                          </label>
                      ))}
                    </div>
                    <ErrorMessage name="enough_letter_without_name">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  Nota: Cualquier otro Estudio o Documento Especializado Favor de Especificarlo en la Bitacora de Comentarios.
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
