import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";
import { getAllToday } from "@services/casesService";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const GeneralConsult = (props) => {
  const params = new URLSearchParams(props.location.search);

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    var queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    getAllToday(pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    subheader.setTitle(`Consulta General ${params.get("modo")}`);

    
  }, [params]);

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);
    
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Consulta General {params.get("modo")}
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[]}
          hideStatusPatient={false}
          hideInsurance={params.get("modo") === "aseguradora"}
          hideHospital={params.get("modo") === "hospital"}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideDiffDays={params.get("modo") === "hospital"}
          hideAmount={params.get("modo") === "hospital"}
          hideAxaCategoryId={params.get("modo") === "hospital"}
          extraOptions={<option value="Cancelado">Cancelado</option>}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/consulta_general_detalles/"
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        data={casesRecord}
        hideFlagsColumn={true}
        hideReasonColumn={true}
        hideAxaCategoryColumn={params.get("modo") === "hospital"}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        hideCelulaColumn ={params.get("modo") === "hospital"}
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default GeneralConsult;
