import { ErrorMessage, Formik } from "formik";
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Spinner,
  Modal,
} from "react-bootstrap";
import * as Yup from "yup";
import AlertError from "@components/AlertError";
import { getInputClasses } from "@utils/getInputclasses";
import { getAllCategorizations, getAllStatusesCategory, updateMedicalFee } from "@services/medicalFeesService";

export const ModalInsuranceMedicalFee = ({ showModal, handleCloseModal, medicalFee, getMedicalFees, hideTabsModuleInputs = true, ...props }) => {
  const [statusesCategory, setStatusesCategory] = useState([]);
  const [categorizationTab, setCategorizationTab] = useState([]);

  useEffect(() => {
    getAllCategorizations(props.filterStatuses)
        .then( response => setCategorizationTab(response.data));

    if (!props.filterStatusesBycategorization) {
      getAllStatusesCategory(props.filterStatuses)
          .then(response => setStatusesCategory(response.data));
    }
  }, [props.filterStatuses, props.filterStatusesBycategorization])

  const initialValues = {
    statusTab: medicalFee.statusTab || '',
    reason: medicalFee.reason || '',
    centralTab: medicalFee.centralTab || '',
    categoryTab: medicalFee.categoryTab || '',
    statusCategory: medicalFee.statusCategory || '',
  }

  const validationSchema = Yup.object().shape({
    statusTab: Yup.string().required("El campo es requerido"),
  });

  const handleSubmitForm = async (values, { setStatus, setSubmitting, resetForm }) => {
    setTimeout(() => {
      updateMedicalFee(values, medicalFee.id).then(response => {
        console.log("Form medical fees inside saved");
        getMedicalFees();
        handleCloseModal();
        resetForm();
      });
      setSubmitting(false);
    }, 1000);
  }

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
            <Modal.Title>Estatus honorarios medicos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ ...initialValues }}
            enableReinitialize={true}
            onSubmit={handleSubmitForm}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              status,
              isSubmitting,
              submitForm,
              setFieldValue,
            }) => {
              return (
                <>
                  <AlertError status={status} />
                  <Form onSubmit={handleSubmit} className="mb-4">
                    <Row>
                      <Col>
                        <Form.Group controlId="statusTab">
                          <Form.Label>Estatus tabulacion *</Form.Label>
                          <div className="mb-3">
                            <Form.Check
                              custom
                              inline
                              label="Procedente"
                              id="Procedente"
                              type="radio"
                              value="Procedente"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="statusTab"
                              checked={values.statusTab === "Procedente"}
                            />
                            <Form.Check
                              custom
                              inline
                              label="Improcedente"
                              id="Improcedente"
                              type="radio"
                              value="Improcedente"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="statusTab"
                              checked={values.statusTab === "Improcedente"}
                            />
                            <Form.Check
                              custom
                              inline
                              label="Falta informacion"
                              id="Falta informacion"
                              type="radio"
                              value="Falta informacion"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="statusTab"
                              checked={values.statusTab === "Falta informacion"}
                            />
                            <Form.Check
                              custom
                              inline
                              label="Reembolso"
                              id="Reembolso"
                              type="radio"
                              value="Reembolso"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="statusTab"
                              checked={values.statusTab === "Reembolso"}
                            />
                          </div>
                          <ErrorMessage name="statusTab">
                            {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="reason">
                          <Form.Label>Motivo</Form.Label>
                          <Form.Control
                            as="select"
                            name="reason"
                            value={values.reason}
                            onChange={handleChange}
                            disabled={values.statusTab !== "Improcedente" || props.disabledAll}
                            className={`${getInputClasses(
                              touched,
                              errors,
                              "reason"
                            )}`}
                          >
                            <option value="">Selecciona una opcion...</option>
                            <option value="No se ha considerado el rol en el procedimiento tabulado">No se ha considerado el rol en el procedimiento tabulado</option>
                            <option value="Honorarios cubiertos por el asegurado">Honorarios cubiertos por el asegurado</option>
                            <option value="No se cuenta con ajuste de tabulador del medico">No se cuenta con ajuste de tabulador del medico</option>
                          </Form.Control>
                          <ErrorMessage name="reason">
                            {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row hidden={hideTabsModuleInputs}>
                      <Col>
                        <Form.Group controlId="centralTab">
                          <Form.Label>VoBo. tabulaciones central *</Form.Label>
                          <div className="mb-3">
                            <Form.Check
                              custom
                              inline
                              label="Si"
                              id="Si"
                              type="radio"
                              value="Si"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="centralTab"
                              checked={values.centralTab === "Si"}
                            />
                            <Form.Check
                              custom
                              inline
                              label="No"
                              id="No"
                              type="radio"
                              value="No"
                              disabled={props.disabledAll}
                              onChange={handleChange}
                              name="centralTab"
                              checked={values.centralTab === "No"}
                            />
                          </div>
                          <ErrorMessage name="centralTab">
                            {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row hidden={hideTabsModuleInputs}>
                      <Col>
                        <Form.Group controlId="categoryTab">
                          <Form.Label>Categorizacion (tabulacion) *</Form.Label>
                          <Form.Control
                            as="select"
                            name="categoryTab"
                            value={values.categoryTab}
                            disabled={props.disabledAll}
                            onChange={ e => {
                              handleChange(e);
                              const selectedIndex = e.target.options.selectedIndex;
                              const selectedOption = e.target.options[selectedIndex];
                              const dataId = selectedOption.getAttribute('data-id');
                              if (props.filterStatusesBycategorization) {
                                getAllStatusesCategory(props.filterStatuses, dataId)
                                    .then(response => setStatusesCategory(response.data));
                              }
                            }}
                            className={`${getInputClasses(
                              touched,
                              errors,
                              "categoryTab"
                            )}`}
                          >
                            <option value="">Selecciona una opcion...</option>
                            {categorizationTab?.map( (categorization, idx) => (
                              <option key={idx} data-id={categorization.id} value={categorization.description}>{categorization.description}</option>
                            ))}
                          </Form.Control>
                          <ErrorMessage name="categoryTab">
                            {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row hidden={hideTabsModuleInputs}>
                      <Col>
                        <Form.Group controlId="statusCategory">
                          <Form.Label>Estatus (categorizacion) *</Form.Label>
                          <Form.Control
                            as="select"
                            name="statusCategory"
                            value={values.statusCategory}
                            disabled={props.disabledAll}
                            onChange={handleChange}
                            className={`${getInputClasses(
                              touched,
                              errors,
                              "statusCategory"
                            )}`}
                          >
                            <option value="">Selecciona una opcion...</option>
                            {statusesCategory?.map( (status, idx) => (
                              <option key={idx} value={status.description}>{status.description}</option>
                            ))}
                          </Form.Control>
                          <ErrorMessage name="statusCategory">
                            {(msg) => (
                              <Form.Control.Feedback type="invalid">
                                {msg}
                              </Form.Control.Feedback>
                            )}
                          </ErrorMessage>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Button variant="primary" type="submit" disabled={isSubmitting} hidden={props.disabledAll}>
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          variant="dark"
                          size="sm"
                        />
                      )}
                      Guardar
                    </Button>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};