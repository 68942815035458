//React, Bootstrap & Others
import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner} from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

//Components
import AlertError from "@components/AlertError"

//Services
import { getAll as getAllHospitals } from "@services/hospitalsService";
import { getAll as getAllDoctors } from "@services/doctorsService";
import { getAll as getAllNurses } from "@services/nursesService";
import { getAllCells } from "@services/cellService";
import {save, update} from "@services/usersCrud"
import {getAll} from "@services/rolesCrud"

//Utils
import {getInputClasses} from "@utils/getInputclasses"

const FormUser = (props) => {
    const [isHospital, setIsHospital] = useState(false);
    const [hospitals, setHospitals] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [nurses, setNurses] = useState([]);
    const [cellsArray, setCellsArray] = useState([]);
    const [roles, setRoles] = useState([]);
    const [cells, setCells] = useState([]);

    useEffect(() => {
        const hideCells = ['Ágil', 'VIP', 'Urgente', 'No Urgente', 'Facultamiento 24/7'];

        getAllHospitals().then( result => setHospitals(result.data));
        getAllDoctors().then( result => setDoctors(result.data));
        getAllNurses().then( result => setNurses(result.data));
        getAllCells().then(result => {
            result.data = result.data.filter(cell => !hideCells.includes(cell.cellDescription))
            setCells(result.data)
        });
        getAll().then(data => setRoles(data.data));
        setIsHospital(props.user.userType === "Hospital");
        setCellsArray(props.user.cell?.split(',').map( cell => ({label: cell, value: cell})) || []);
    }, [props.user]);

    const passwordSchema = {
        password: Yup.string()
            .min(6, "Minimo 3 caracteres")
            .max(20, "Maximo 20 caracteres")
            .required("El campo es requerido"),
        passwordUpdate: Yup.string()
            .min(6, "Minimo 3 caracteres")
            .max(20, "Maximo 20 caracteres"),
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Minimo 3 caracteres")
            .max(40, "Maximo 40 caracteres")
            .required("El campo es requerido"),
        userName: Yup.string()
            .min(3, "Minimo 3 caracteres")
            .max(40, "Maximo 40 caracteres")
            .required("El campo es requerido"),
        email: Yup.string()
            .min(3, "Minimo 3 caracteres")
            .max(100, "Maximo 20 caracteres")
            .email("No parece ser un email valido")
            .required("El campo es requerido"),
        password: !props.shouldUpdate ? passwordSchema.password : passwordSchema.passwordUpdate,
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        values.cell = values.cellsArray?.map( cell => cell.value).join(",") || "";
        setTimeout(() => {
            if (props.shouldUpdate) {
                update(values).then(response => {
                    props.getUsers();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( (error) => {
                    setSubmitting(false);
                    if (error.response.data.message === undefined) {
                        setStatus(`Ocurrio algun error al guardar`);
                    } else {
                        setStatus(`Ocurrio algun error al guardar: ${error.response.data.message}`);
                    }
                });
            } else {
                save(values).then(response => {
                    props.getUsers();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( (error) => {
                    setSubmitting(false);
                    if (error.response.data.message === undefined) {
                        setStatus(`Ocurrio algun error al guardar`);
                    } else {
                        setStatus(`Ocurrio algun error al guardar: ${error.response.data.message}`);
                    }
                });
            } 
        }, 1000);

    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.shouldUpdate ? "Editar" : "Agregar"} usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{...props.user, cellsArray: cellsArray}}
                            onSubmit={onSubmit}
                            validationSchema={validationSchema}
                            enableReinitialize={true}>
                        {({values, errors, touched, handleChange, handleSubmit, status, setFieldValue, isSubmitting}) => (
                            <>
                                <AlertError status={status} />
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="name">
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    placeholder="Escribe el nombre"
                                                    className={`${getInputClasses(touched, errors, "name")}`} />

                                        <ErrorMessage name="name">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="userName">
                                        <Form.Label>Usuario</Form.Label>
                                        <Form.Control type="text"
                                                    value={values.userName}
                                                    onChange={handleChange}
                                                    name="userName"
                                                    placeholder="Escribe el usuario"
                                                    className={`${getInputClasses(touched, errors, "userName")}`} />
                                            <ErrorMessage name="userName">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    name="email"
                                                    placeholder="Escribe el email"
                                                    className={`${getInputClasses(touched, errors, "email")}`} />
                                            <ErrorMessage name="email">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>

                                    { !props.shouldUpdate &&
                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    name="password"
                                                    placeholder="Escribe la password"
                                                    className={`${getInputClasses(touched, errors, "password")}`} />
                                        <ErrorMessage name="password">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                    </Form.Group>
                                    }

                                    <Form.Group controlId="roleId">
                                        <Form.Label>Role</Form.Label>
                                        <Form.Control as="select"
                                                    name="roleId"
                                                    onChange={handleChange}
                                                    defaultValue={props.shouldUpdate ? values.role?.idRole : ""}
                                                    className={`${getInputClasses(touched, errors, "roleId")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            {roles?.map( (role, idx) => (
                                                <option key={idx} value={role.idRole}>{role.name}</option>
                                            ))}
                                            <ErrorMessage name="roleId">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="userType">
                                        <Form.Label>Tipo de usuario</Form.Label>
                                        <Form.Control as="select"
                                                    name="userType"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        e.target.value === "Hospital" ? setIsHospital(true) : setIsHospital(false);
                                                    }}
                                                    defaultValue={props.shouldUpdate ? values.userType : ""}
                                                    className={`${getInputClasses(touched, errors, "userType")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            <option value="Aseguradora">Aseguradora</option>
                                            <option value="Hospital">Hospital</option>
                                            <ErrorMessage name="userType">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="hospitalId" hidden={!isHospital}>
                                        <Form.Label>Hospital</Form.Label>
                                        <Form.Control as="select"
                                                    name="hospitalId"
                                                    onChange={handleChange}
                                                    defaultValue={props.shouldUpdate ? values.hospital?.id : ""}
                                                    className={`${getInputClasses(touched, errors, "hospitalId")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            {hospitals?.map( (hospital, idx) => (
                                                <option key={idx} value={hospital.id}>{hospital.hospitalName}</option>
                                            ))}
                                            <ErrorMessage name="hospitalId">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId="doctorId" hidden={isHospital || typeof values.userType === "undefined" || values.userType === ""}>
                                        <Form.Label>Doctor</Form.Label>
                                        <Form.Control as="select"
                                                    name="doctorId"
                                                    onChange={handleChange}
                                                    defaultValue={props.shouldUpdate ? values.doctor?.id : ""}
                                                    className={`${getInputClasses(touched, errors, "doctorId")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            {doctors?.map( (doctor, idx) => (
                                                <option key={idx} value={doctor.id}>{doctor.fullName}</option>
                                            ))}
                                            <ErrorMessage name="doctorId">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="nurseId" hidden={isHospital || typeof values.userType === "undefined" || values.userType === ""}>
                                        <Form.Label>Enfermera</Form.Label>
                                        <Form.Control as="select"
                                                    name="nurseId"
                                                    onChange={handleChange}
                                                    defaultValue={props.shouldUpdate ? values.nurse?.id : ""}
                                                    className={`${getInputClasses(touched, errors, "nurseId")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            {nurses?.map( (nurse, idx) => (
                                                <option key={idx} value={nurse.id}>{nurse.fullName}</option>
                                            ))}
                                            <ErrorMessage name="nurseId">
                                                { msg => (
                                                    <Form.Control.Feedback type="invalid">
                                                        {msg}
                                                    </Form.Control.Feedback>
                                                )}
                                            </ErrorMessage>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="cellsArray" hidden={values.userType !== 'Aseguradora'}>
                                        <Form.Label>Celula</Form.Label>
                                        <MultiSelect
                                            name="cellsArray"
                                            value={values.cellsArray || []}
                                            overrideStrings={
                                                {
                                                    "allItemsAreSelected": "Todos los elementos fueron seleccionados.",
                                                    "clearSearch": "Limpiar busqueda",
                                                    "noOptions": "No hay opciones",
                                                    "search": "Buscar",
                                                    "selectAll": "Seleccionar Todas",
                                                    "selectSomeItems": "Seleccionar..."
                                                }
                                            }
                                            onChange={(e) => {
                                                setFieldValue("cellsArray", e);
                                            }}
                                            options={cells?.map( cell => ({ label: cell.cellDescription, value: cell.cellDescription})) || []}
                                            labelledBy="Selecciona las celulas"
                                            className={`${getInputClasses(
                                                touched,
                                                errors,
                                                "cellsArray"
                                            )}`}
                                        />
                                        <ErrorMessage name="cellsArray">
                                            {(msg) => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="region" hidden={values.userType !== 'Aseguradora'}>
                                        <Form.Label>Region</Form.Label>
                                        <Form.Control as="select"
                                                      value={values.region || ''}
                                                      onChange={handleChange}
                                                      name="region"
                                                      className={`${getInputClasses(touched, errors, "region")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            <option value="Región I">Región I</option>
                                            <option value="Región II">Región II</option>
                                            <option value="Región pendiente">Región pendiente</option>
                                        </Form.Control>
                                        <ErrorMessage name="region">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                                        Guardar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>                           
        </>
    );
}

export default FormUser;
