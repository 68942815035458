import React from "react";
// import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import {isHospitalAtc} from "@utils/checkHospitalAtc";
// import AlertError from "@components/AlertError";
// import { getLetterMovsByCase } from "../../insurance/services/letterMovsService";

export const AdminData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos Administrativos
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentAdminData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentAdminData = (props) => {
  // const initialValues = {
  //   enough: props.letterMov.enough || "",
  // };

  // const { slug } = props.data;
  // const { letterMovId } = props.letterMov || '';
  // const { bindSubmitForm } = props;

  // const handleSubmitForm = (values, { setStatus, setSubmitting }) => {
  //   updateLetterMvByCase(letterMovId, { ...props.letterMov, ...values });
  //   console.log("saved admin data inside");
  // };

  return (
    <>
      <Alert
        variant="dark"
        className="text-center"
        style={{ fontSize: "14px" }}
      >
        Datos Administrativos
      </Alert>

      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <Card>
                <Card.Body>
                  <b>Importe Carta Autorizacion: </b>${props.totalLetters}
                  <br />
                  <b>Carta Suficiente: </b>{props.data.enoughYesNo}
                  <br />
                  <b>Estatus Dictamen: </b>{props.data.statusDictum}
                  <br />
                  <b>Estatus: </b>{props.data.statusCC}
                  <br />
                  <b>VoBo. Carta de Autorizacion: </b>{props.data.voBoLetter}
                  <br />
                  <div hidden={isHospitalAtc(props.data)}><b>Importe Total de Desvíos: </b>${props.data.totalDeviations}</div>
                  <hr />
                  {/* <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={handleSubmitForm}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      status,
                      isSubmitting,
                      submitForm,
                    }) => {
                      bindSubmitForm(submitForm);
                      return (
                        <>
                          <AlertError status={status} />
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col>
                                <Form.Group controlId="enough">
                                  <Form.Label>Carta Suficiente</Form.Label>
                                  <div className="mb-3">
                                    <Form.Check
                                      custom
                                      name="enough"
                                      inline
                                      label="SI"
                                      id="si"
                                      value="si"
                                      type="radio"
                                      checked={values.enough === "si"}
                                      onChange={handleChange}
                                    />
                                    <Form.Check
                                      custom
                                      name="enough"
                                      inline
                                      label="NO"
                                      id="no"
                                      value="no"
                                      type="radio"
                                      checked={values.enough === "no"}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <ErrorMessage name="enough">
                                    {(msg) => (
                                      <Form.Control.Feedback type="invalid">
                                        {msg}
                                      </Form.Control.Feedback>
                                    )}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      );
                    }}
                  </Formik> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
