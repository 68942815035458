//React, Bootstrap & Others
import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner} from "react-bootstrap";

//Components
import AlertError from "@components/AlertError"

//Services
import {updatePassword} from "@services/usersCrud"

//Utils
import {getInputClasses} from "@utils/getInputclasses";

const FormPassword = (props) => {

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, "Minimo 3 caracteres")
            .max(20, "Maximo 20 caracteres")
            .required("El campo es requerido"),
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        setTimeout(() => {
            updatePassword({...props.user, ...values}).then(response => {
                props.handleClose();
                swal("Guardado Exitoso", "Se ha guardado", "success");
            }).catch( (error) => {
                setSubmitting(false);
                if (error.response.data.message === undefined) {
                    setStatus(`Ocurrio algun error al guardar`);
                } else {
                    setStatus(`Ocurrio algun error al guardar: ${error.response.data.message}`);
                }
            });
        }, 1000);

    };

    return (
        <>
        <Modal show={props.showModal} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Actualizar Contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik initialValues= {{}} onSubmit={onSubmit} validationSchema={validationSchema}>
                    {({values, errors, touched, handleChange,handleSubmit, status, isSubmitting}) =>(
                    <> 
                        <AlertError status={status} />
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password"
                                value={values.password}
                                onChange={handleChange}
                                name="password"
                                placeholder="Escribe la password"
                                className={`${getInputClasses(touched, errors, "password")}`} />
                                <ErrorMessage name="password">
                                    { msg => (
                                        <Form.Control.Feedback type="invalid">
                                            {msg}
                                        </Form.Control.Feedback>
                                    )}
                                </ErrorMessage>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                            Guardar
                            </Button>
                        </Form>
                    </> 
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>                            
        </>
    );
}

export default FormPassword;