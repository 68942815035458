import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import { getCasesLockedByStatus } from "@services/casesService";
import GeneralTable from "@components/GeneralTable";
import { update } from "@services/hospitalizationcrud";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const Requests = () => {
  window.currModule = "Solicitudes";
  window.assignationColumn = "feadictamen";

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    var queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    getCasesLockedByStatus([2, 9], pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  const activateCase = (caseId) => {
    update({ caseLocked: 0, caseWorking: 0 }, caseId)
    .then((response) => {
      getAllCasesByStatus(paramsPagination.activePage);
      alert("Desbloqueado");
    });
  }

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);

    subheader.setTitle("Solicitudes");
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Solicitudes
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[2, 9]}
          getCasesLocked={1}
          hideInsurance={true}
          hideReason={false}
          hideCategoryVip={true}
          hideHospitalType={false}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/mesa_control_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideOptionsButton={true}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hidePatientStatusColumn={true}
        OptionsColumnSlot={<>ola</>}
        reason="ControlDesk"
        assignationColumn="feamesaControl"
        currModule="Mesa de Control"
        activateCaseFunc={activateCase}
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default Requests;
