//React, Bootstrap & Others
import React, { useState, useEffect } from "react";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';

//Metronic
import {useSubheader} from "@metronic/layout";

//Components
import FormDoctor from "./FormDoctor";

//Services
import {getAll, destroy} from "@services/doctorsService";

const DoctorsList = (props) => {
    const subheader = useSubheader();

    const initialDoctorState = { doctorName: "", doctorFatherLastname: "", doctorMotherLastname: "", fullName: "", category: "" };

    const [doctors, setDoctors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [doctor, setDoctor] = useState(initialDoctorState);

    useEffect(() => {
		getDoctors();

        subheader.setTitle("Listado de Doctores");
	}, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const getDoctors = () => getAll().then(data => setDoctors(data.data));
    const handleDeleteDoctor = (e, doctorId) => {
        e.preventDefault();
        destroy(doctorId).then(response => {
            getDoctors();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStoreDoctor = (e) => {
        handleShow();
        setDoctor(initialDoctorState);
        setShouldUpdate(false);
    }
    const handleEditDoctor = (e, doctor) => {
        e.preventDefault();
        setDoctor({ ...doctor });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStoreDoctor}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Categoria</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {doctors?.map( (doctor, idx) => (
                                <tr key={idx}>
                                    <td>{doctor.fullName}</td>
                                    <td>{doctor.category}</td>
                                    <td>
                                        <Button variant="success" onClick={(e) => handleEditDoctor(e, doctor)}>Editar</Button>{' '}
                                        <Button variant="danger" onClick={(e) => handleDeleteDoctor(e, doctor.id)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormDoctor showModal={showModal}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            shouldUpdate={shouldUpdate}
                            getDoctors={getDoctors}
                            doctor={doctor} />
        </>
    );
};

export default DoctorsList;