import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";
import { getCasesByStatuses } from "@services/casesService";
import CaseContext from "@contexts/CaseContext";

const StorePatient = () => {
  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    getCasesByStatuses([1,2,3,4], pageNumber).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    subheader.setTitle("Cirugías programadas");
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Cirugías programadas
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[1,2,3,4]}
          hidePeriodEqual={true}
          hidePeriodFrom={true}
          hidePeriodTo={true}
          hideReason={true}
          hideStatusPatient={true}
          hideHospitalType={true}
          hideConciergeAssigned={true}
          hideDiffDays={true}
          hideAmount={true}
          hideInssuredName={true}
          hideAxaCategoryId={true}
          hideInsurance={true}
          hideTypePolicy={true}
          hideCategoryVip={true}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
          hideMovementType={true}
          hideAtcNumber={true}
          hideRegion={true}
          hideCell={true}
          hideComprehensiveCare={true}
          hideIdentificator={false}
          hideAtentionIden={false}
          callbackEmptyResults={() => {
            alert("Asegurado no cuenta con Programación de Cirugía");
          }}
        />
      </CaseContext.Provider>

      <GeneralTable
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideOptionsColumn={true}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hideAxaCategoryColumn={true}
        hideHospitalDateTimeHireColumn={true}
        hideTabulationColumn={true}
        hidePolicyTypeColumn={true}
        hideFlagsColumn={true}
        hideCounterColumn={true}
        hidePatientStatusColumn={true}
        hideAdmissionDateTimeColumn={true}
        hospitalIden={true}
        hideModule={true}
        hideDiagnosticColumn={true}
        hideComprehensiveCare={true}
        hideReasonColumn={true}
        hideDaysStayColumn={true}
        hideMountsColumn={true}
        assignationColumn={null}
        statusTitle={null}
        currModule="Cirugias programadas"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default StorePatient;
