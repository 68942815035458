import React from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";

export const FamilyData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos de Familiar
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
          <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentFamilyData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentFamilyData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Datos de familiar
      </Alert>

      <Card>
        <Card.Body>
          <FormFamilyData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormFamilyData = (props) => {
  const initialValues = {
    familiarName: props.data.familiarName || "",
    relationship: props.data.relationship || "",
    familiarPhone: props.data.familiarPhone || "",
    familiarMobile: props.data.familiarMobile || "",
    familiarEmail: props.data.familiarEmail || "",
  };

  const { id } = props.data;
  const { bindSubmitForm, required = true } = props;

  const handleSubmitForm = (values, { setStatus, setSubmitting }) => {
    update({ ...props.data, ...values }, id).then((response) =>
      console.log("Form family data inside saved")
    );
  };

  const validationSchema = Yup.object().shape({
    familiarName: Yup.string()
        .required("El campo nombre es requerido"),
    relationship: Yup.string()
        .required("El campo parentesco es requerido"),
    familiarPhone: Yup.string()
        .required("El campo TELÉFONO fijo es requerido"),
    familiarMobile: Yup.string()
        .required("El campo TELÉFONO celular es requerido"),
    familiarEmail: Yup.string()
        .required("El campo correo es requerido")
        .email("El campo no parece ser un email"),
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
      }) => {
        bindSubmitForm(submitForm);
        return (
          <>
            <AlertError status={status} />
            <Form id="formu3" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="familiarName">
                    <Form.Label>Nombre {required ? '*' : ''}</Form.Label>
                    <Form.Control
                      type="text"
                      name="familiarName"
                      disabled={props.disabledAll}
                      value={values.familiarName}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "familiarName"
                      )}`}
                    />
                    <ErrorMessage name="familiarName">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="relationship">
                    <Form.Label>Parentesco {required ? '*' : ''}</Form.Label>
                    <Form.Control
                      as="select"
                      name="relationship"
                      disabled={props.disabledAll}
                      value={values.relationship}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "relationship"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      <option value="1">Esposo (a)</option>
                      <option value="2">Hijo (a)</option>
                      <option value="3">Hermano (a)</option>
                      <option value="4">Padre</option>
                      <option value="5">Madre</option>
                      <option value="6">Otro</option>
                    </Form.Control>
                    <ErrorMessage name="relationship">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="familiarPhone">
                    <Form.Label>TELÉFONO FIJO {required ? '*' : ''}</Form.Label>
                    <Form.Control
                      type="number"
                      name="familiarPhone"
                      disabled={props.disabledAll}
                      value={values.familiarPhone}
                      onChange={handleChange}
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "familiarPhone"
                      )}`}
                    />
                    <ErrorMessage name="familiarPhone">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="familiarMobile">
                    <Form.Label>TELÉFONO Celular {required ? '*' : ''}</Form.Label>
                    <Form.Control
                      type="number"
                      name="familiarMobile"
                      disabled={props.disabledAll}
                      value={values.familiarMobile}
                      onChange={handleChange}
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "familiarMobile"
                      )}`}
                    />
                    <ErrorMessage name="familiarMobile">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="familiarEmail">
                    <Form.Label>Correo {required ? '*' : ''}</Form.Label>
                    <Form.Control
                      type="email"
                      name="familiarEmail"
                      disabled={props.disabledAll}
                      value={values.familiarEmail}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "familiarEmail"
                      )}`}
                    />
                    <ErrorMessage name="familiarEmail">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
