import { actionTypesBinnacles } from "../actions/binnacles";

const initialState = {
    data: [],
};

export const binnaclesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypesBinnacles.GET_ALL_BY_CASE:
            return { ...state, data: action.payload };
        case actionTypesBinnacles.STORE:
            return state;
        default:
            return state;
    }
}