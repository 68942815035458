import axios from "axios";

export const GET_LAST_BY_CASE = "letter_movs/getLastByCase";
export const STORE = "letter_movs";
export const STORE_BY_CASE = "letter_movs/storeLetterMvByCase";
export const GET_BY_CASE = "letter_movs/getAllByCase";
export const UPDATE_BY_CASE = "letter_movs/updateLetterMvByCase";
export const DELETE = "letter_movs";


export let getLastLetterMovByCase = function (caseId) {
  return axios.get(`${GET_LAST_BY_CASE}/${caseId}`);
}

export const getLetterMovsByCase = (caseId) => {
  return axios.get(`${GET_BY_CASE}/${caseId}`);
}

export const storeLetterMov = (data) => {
  return axios.post(`${STORE}`, data);
}

export const storeLetterMvByCase = (caseSlug, data) => {
  return axios.post(`${STORE_BY_CASE}/${caseSlug}`, data);
}

export const updateLetterMvByCase = (id, data) => {
  return axios.put(`${UPDATE_BY_CASE}/${id}`, data);
}

export const removeLetterMov = (id) => {
  return axios.delete(`${DELETE}/${id}`);
}
