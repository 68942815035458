import axios from "axios";

export const GET_BY_SLUG = "cases";
export const SAVE = "cases";
export const UPDATE = "cases";
export const DELETE = "cases";

export let getBySlug = function (slug) {
    return axios.get(`${GET_BY_SLUG}/${slug}`);
}

export let save = function (data) {
    return axios.post(SAVE, data);
}

export function update(data, id) {
    return axios.put(`${UPDATE}/${id}`, data);
}

export function destroy(roleId) {
    return axios.delete(`${DELETE}/${roleId}`);
}
