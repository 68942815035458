export function toBoolean (value){
  if (value === 'true') return true;
  if (value === 'false') return false;
  return false; // Default to false if value is null or undefined
}

export function toBooleanOrNull (value){
  if (value === 'true') return true;
  if (value === 'false') return false;
  return null;
}
