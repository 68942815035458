/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, IfHasPermission } from "../../../_helpers";
import { Icon } from "@material-ui/core";

export function AsideSearch({ isActive }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <div
      className={`tab-pane p-3 px-lg-7 py-lg-5 fade ${isActive &&
        "show active"}`}
    >
      {/* begin::Form */}
      <form className="p-2 p-lg-3">
        <div className="d-flex">
          <div className="input-icon h-40px">
            <input
              type="text"
              className="form-control form-control-lg form-control-solid h-40px"
              placeholder="Search..."
              id="generalSearch"
              autoComplete="false"
            />
            <span>
              <span className="svg-icon svg-icon-lg">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                />
              </span>
            </span>
          </div>
        </div>
      </form>
      {/* end::Form */}

      <div className={`tab-pane fade ${isActive && "show active"}`}>
        <div className="aside-menu-wrapper flex-column-fluid py-5">
          {/* begin::Menu Container */}
          <div
            id="kt_aside_menu"
            data-menu-vertical="1"
            className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
            {...layoutProps.asideMenuAttr}
          >
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
              {/*begin::1 Level*/}
              {IfHasPermission("Tablero de Control") && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/tablero_control",
                    false
                  )}`}
                >
                  <NavLink className="menu-link" to="/tablero_control">
                    <span className="svg-icon menu-icon">
                      <Icon className="fa fa-search-plus" />
                    </span>
                    <span className="menu-text">Volumen General</span>
                  </NavLink>
                </li>
              )}

              {IfHasPermission("Tablero de Dictamen") && (
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/tablero_dictamen",
                          false
                      )}`}
                  >
                    <NavLink className="menu-link" to="/tablero_dictamen">
                      <span className="svg-icon menu-icon">
                        <Icon className="fa fa-search-plus" />
                      </span>
                      <span className="menu-text">Asignaciones de Dictamen</span>
                    </NavLink>
                  </li>
              )}

              {IfHasPermission("Tablero de Control de Costos") && (
                  <li
                      className={`menu-item ${getMenuItemActive(
                          "/tablero_control_costos",
                          false
                      )}`}
                  >
                    <NavLink className="menu-link" to="/tablero_control_costos">
                      <span className="svg-icon menu-icon">
                        <Icon className="fa fa-search-plus" />
                      </span>
                      <span className="menu-text">Asignaciones de Control de Costos</span>
                    </NavLink>
                  </li>
              )}
              {/*end::1 Level*/}
            </ul>
          </div>
        </div>
      </div>

      {/* end::List */}
    </div>
  );
}
