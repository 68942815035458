import React, { useEffect, useState } from "react";
import { Accordion, Alert, Button } from "react-bootstrap";
import * as Yup from "yup";
import { setIn } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useSubheader } from "@metronic/layout";
import { HospitalData } from "@pages/subPages/HospitalData/HospitalData";
import { InssuredData } from "@pages/subPages/InsuredData/InssuredData";
import { FamilyData } from "@pages/subPages/FamilyData/FamilyData";
import { Documentation } from "@pages/subPages/Documentation/Documentation";
import { Binnacle } from "@pages/subPages/Binnacle/Binnacle";
import {
  getBySlug,
  update,
} from "@services/hospitalizationcrud";
import { isEmpty } from "@utils/checkObjectEmpty";
import { MedicAssigned } from "@pages/subPages/MedicAssigned/MedicAssigned";
import AuthLetters from "@pages/subPages/AuthLetter/AuthLetters";
import moment from "moment";
import { MedicalFees } from "@pages/subPages/MedicalFee/MedicalFees";
import { getAllMedicalFees } from "@services/medicalFeesService";
import { ProvidersData } from "@pages/subPages/SurgeryProvidersData/ProvidersData";
import CaseHeader from "@pages/subPages/CaseHeader/CaseHeader";
import BlockCaseContext from "@contexts/BlockCaseContext";
import $ from "jquery";

const TracingDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [caseRecord, setCaseRecord] = useState({});
  const [errors, setErrors] = useState({});
  const [hasError, setHasError] = useState(false);
  const [shouldBlockCase, setShouldBlockCase] = useState(true);

  useEffect(() => {
    subheader.setTitle("Seguimiento");

    window.wasCommented = false;
    getBySlug(slug).then((response) => {
      response.data.caseType = "Ingreso hospitalario";
      setCaseRecord(response.data);
      window.requires_tabulation = response.data.requiresTabulation;
    });
  }, [slug]);

  let history = useHistory();

  let submitFormInssuredData = null;
  let submitFormHospitalData = null;
  let submitFormFamilyData = null;
  let submitFormMedicDataTreating = null;
  let submitFormMedicalFee = null;
  let submitFormMedicalFeeErrors = null;

  const bindSubmitFormInssuredData = (submitForm) =>
    (submitFormInssuredData = submitForm);
  const bindSubmitFormHospitalData = (submitForm) =>
    (submitFormHospitalData = submitForm);
  const bindSubmitFormFamilyData = (submitForm) =>
    (submitFormFamilyData = submitForm);
  const bindSubmitFormMedicDataTreating = (submitForm) =>
    (submitFormMedicDataTreating = submitForm);

  const bindSubmitFormMedicalFee = (submitForm) =>
    (submitFormMedicalFee = submitForm);

  const bindSubmitFormMedicalFeeErrors = (errors) =>
    (submitFormMedicalFeeErrors = errors);

  const onClickSendControlDesk = async () => {
    let dataForms = {};
    for (let pair of new FormData(
      document.getElementById("formu1")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu2")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    for (let pair of new FormData(
      document.getElementById("formu3")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }
    for (let pair of new FormData(
      document.getElementById("formu4")
    ).entries()) {
      dataForms[pair[0]] = pair[1];
    }

    const validationSchema = Yup.object().shape({
      incomeType: Yup.string().required(
        "El campo tipo de ingreso es requerido"
      ),
      caseType: Yup.string().required(
        "El campo tipo de tramite / movimiento es requerido"
      ),
      hospitalId: Yup.string().required("El campo hospital es requerido"),
      reporter: Yup.string()
        .required("El campo reportado por es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "reportado por" solo permite caracteres alfabéticos'
        ),
      hospitalEmail: Yup.string()
        .required("El campo email de hospital es requerido")
        .email("El campo email hospital parece no ser un valido"),
      hospitalPhone: Yup.string()
        .required("El campo telefono de hospital es requerido")
        .matches(
            /^[0-9]+$/,
            'El campo "TELÉFONO" solo permite caracteres numericos'
        ),
      hospitalExtension: Yup.string()
        .required("El campo extension de hospital es requerido")
        .matches(
            /^[0-9]+$/,
            'El campo "EXTENSIÓN" solo permite caracteres numericos'
        ),
      familiarName: Yup.string()
        .required("El campo nombre es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            {
                excludeEmptyString: true,
                message: 'El campo "NOMBRE" solo permite caracteres alfabéticos',
            }
        ),
      relationship: Yup.string()
        .required("El campo parentesco es requerido"),
      familiarPhone: Yup.string()
        .required("El campo TELÉFONO fijo es requerido")
        .matches(
            /^[0-9]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "TELÉFONO FIJO" solo permite caracteres numericos',
            }
        ),
      familiarMobile: Yup.string()
        .required("El campo TELÉFONO celular es requerido")
        .matches(
            /^[0-9]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "TELÉFONO CELULAR" solo permite caracteres numericos',
            }
        ),
      familiarEmail: Yup.string()
          .required("El campo correo es requerido")
          .email("El campo no parece ser un email"),
      insuranceComp: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            {
                excludeEmptyString: true,
                message: 'El campo "ASEGURADORA" solo permite caracteres alfabéticos',
            }
        ),
      assuredFlastname: Yup.string()
        .required("El campo apellido paterno asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "apellido paterno" solo permite caracteres alfabéticos'
        ),
      assuredMlastname: Yup.string()
        .required("El campo apellido materno asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "apellido materno" solo permite caracteres alfabéticos'
        ),
      assuredName: Yup.string()
        .required("El campo nombre asegurado es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]*$/,
            'El campo "nombre asegurado" solo permite caracteres alfabéticos'
        ),
      assuredEmail: Yup.string()
        .required("El campo email asegurado es requerido")
        .email("El campo email asegurado parece no ser un valido"),
      incomeDatetime: Yup.string().required(
        "El campo fecha de ingreso es requerido"
      ),
      hospitalIden: Yup.string()
        .required("El campo folio hospital es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ0-9\s]+$/,
            'El campo "folio hospital" solo permite caracteres alfanuméicos'
        ),
      symptom: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s,.;:]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "SÍNTOMA" solo permite caracteres alfabéticos',
            }
        ),
      diagnosis: Yup.string()
        .required("El campo diagnostivo presuntivo es requerido")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "DIAGNÓSTICO PRESUNTIVO" solo permite caracteres alfabéticos'
        ),
      treatmentDoctorName: Yup.string()
        .required("El campo nombre medico tratante es obligatorio")
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            'El campo "MÉDICO tratante" solo permite caracteres alfabéticos'
        ),
      treatmentDoctorPhone: Yup.string()
        .required("El campo telefono medico tratante es obligatorio")
        .matches(
            /^[0-9]+$/,
            'El campo "TELÉFONO" en MÉDICO tratante solo permite caracteres numericos'
        ),
      treatmentDoctorExt: Yup.string()
        .matches(
            /^[0-9]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "EXTENSIÓN" en MÉDICO tratante solo permite caracteres numericos',
            }
        ),
      treatmentDoctorEmail: Yup.string().required("El campo email medico tratante es obligatorio"),
      medicName: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "NOMBRE DEL MEDICO" solo permite caracteres alfabéticos',
            }
        ),
      speciality: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "ESPECIALIDAD" solo permite caracteres alfabéticos',
            }
        ),
      rol: Yup.string()
        .matches(
            /^[a-zA-Zà-ÿÀ-ÿ\s]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "OTRO" solo permite caracteres alfabéticos',
            }
        ),
      visitsNumber: Yup.string()
        .matches(
            /^[0-9]+$/,
            {
                excludeEmptyString: true,
                message: 'El campo "NUMERO DE VISITAS" solo permite caracteres numericos',
            }
        )
    });

    if (!validateRequireTabulation()) {
      alert("El campo requiere tabulacion es obligatorio");
      return;
    }

    const medicalFeesResponse = await getAllMedicalFees(caseRecord.id);

    if (document.getElementById('si1').checked && medicalFeesResponse.data.length === 0) {
      await submitFormMedicalFee();
    }

    if (!isEmpty(submitFormMedicalFeeErrors)) {
      return;
    }

    try {
      await validationSchema.validate(dataForms, { abortEarly: false });

      dataForms = {
        ...caseRecord,
        ...dataForms,
        requiresTabulation: window.requires_tabulation ? window.requires_tabulation : null,
        requiresProviders: window.requires_providers ? window.requires_providers : null };
      dataForms["caseStatusId"] = 2;
      dataForms['user'] = null;

      if (!window.wasCommented) {
        alert("Debe ingresar un comentario");
        return;
      }
      $("#btn_save_comment").click();
      
      let timestamp = await axios.get('/cases/getTimestamp');
      dataForms["feamesaControl"] = moment.utc(timestamp.data).format();
      update(dataForms, caseRecord.id).then((response) => {
        setErrors({});
        setHasError(false);
        history.push("/seguimiento");
      });
    } catch (err) {
      const errorsCatch = err.inner.reduce((formError, innerError) => {
        return setIn(formError, innerError.path, innerError.message);
      }, {});
      setErrors(errorsCatch);
      setHasError(true);
    }
  }

  const validateRequireTabulation = () => {
    let value = document.querySelector('input[name=requireTab]').value;

    if(document.getElementById('si1').checked || document.getElementById('no1').checked) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Seguimiento
      </Alert>

      <CaseHeader
        caseRecord={caseRecord}
        requiresTabulationSlot={
          <>
            <b>Tabulacion: </b>
            {caseRecord.requiresTabulation}
          </>
        }
      />

      <Alert show={hasError} variant="danger" style={{ fontSize: "14px" }}>
        {Object.entries(errors).map((error, idx) => (
          <p key={idx}>{error[1]}</p>
        ))}
      </Alert>

      <BlockCaseContext.Provider value={[shouldBlockCase, setShouldBlockCase]}>
        <Accordion defaultActiveKey="0" className="mt-5">
          <InssuredData
            icon="fa-pencil-alt"
            eventKey="0"
            data={caseRecord}
            disabledAll={false}
            {...props}
            bindSubmitForm={bindSubmitFormInssuredData}
          />
          <HospitalData
            icon="fa-pencil-alt"
            eventKey="1"
            data={caseRecord}
            disabledAll={false}
            {...props}
            bindSubmitForm={bindSubmitFormHospitalData}
          />
          <FamilyData
            icon="fa-pencil-alt"
            eventKey="2"
            data={caseRecord}
            disabledAll={false}
            {...props}
            bindSubmitForm={bindSubmitFormFamilyData}
          />
          <MedicAssigned
            icon="fa-pencil-alt"
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            bindSubmitForm={bindSubmitFormMedicDataTreating}
          />
          <AuthLetters
            icon="fa-pencil-alt"
            showForm={false}
            hideDeleteOption={true}
            eventKey="4"
            data={caseRecord}
            disabledAll={false}
            setTotalLetters={() => {}}
            {...props}
          />
          {/* <MedicDataTreating
            eventKey="3"
            data={caseRecord}
            disabledAll={false}
            {...props}
            bindSubmitForm={bindSubmitFormMedicDataTreating}
          /> */}
          <Documentation
            icon= "fa-pencil-alt"
            eventKey="5"
            data={caseRecord}
            disabledAll={false}
            attachOptions={true}
            typesDocumentToAttach={["Estado de Cuenta Final"]}
            {...props}
          />
          <MedicalFees
            icon= "fa-pencil-alt"
            eventKey="6"
            data={caseRecord}
            bindSubmitForm={bindSubmitFormMedicalFee}
            bindErrors={bindSubmitFormMedicalFeeErrors}
            {...props}
          />

          <ProvidersData
            icon= "fa-pencil-alt"
            eventKey="7"
            data={caseRecord}
            disabled={caseRecord?.hospital?.captProviders !== 1}
            {...props}
          />
        </Accordion>
        <Accordion defaultActiveKey="6" className="mt-5">
          <Binnacle
            icon="fa-pencil-alt"
            eventKey="6"
            data={caseRecord}
            disabledAll={false}
            eventStage="Seguimiento"
            {...props}
          />
        </Accordion>
      </BlockCaseContext.Provider>

      <Button
        className="mt-5"
        variant="primary"
        onClick={onClickSendControlDesk}
      >
        Enviar a Mesa de Control
      </Button>
    </>
  );
};

export default TracingDetails;
