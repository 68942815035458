import React, { useEffect, useState } from "react";
import {useSubheader} from "@metronic/layout";
import {Card, Table, Button} from "react-bootstrap";
import swal from 'sweetalert';
import {getAll, destroy} from "@services/rolesCrud"
import FormRole from "./FormRole";

const RolesList = () => {
    const subheader = useSubheader();

    const roleInitialState = {
        name: "",
        privileges: [],
    };

    const [roles, setRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [role, setRole] = useState(roleInitialState);

    useEffect(() => {
        getRoles();

        subheader.setTitle("Listado de Roles");
    }, []);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const getRoles = () => getAll().then(data => setRoles(data.data));
    const handleDeleteRole = (e, roleId) => {
        e.preventDefault();
        destroy(roleId).then(response => {
            getRoles();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }
    const handleStoreRole = (e) => {
        handleShow();
        setRole(roleInitialState);
        setShouldUpdate(false);
    }
    const handleEditRole = (e, role) => {
        e.preventDefault();
        role.privilegesChecked = role.privileges.map( privilege => privilege.idPermission.toString());
        setRole({ ...role });
        handleShow();
        setShouldUpdate(true);
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Button variant="success" onClick={handleStoreRole}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Permisos</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles?.map( (role, idx) => (
                                <tr key={idx}>
                                    <td>{role.name}</td>
                                    <td>{Array.prototype.map.call(role.privileges, privilege => privilege.name).toString()}</td>
                                    <td>
                                        <Button variant="success" onClick={(e) => handleEditRole(e, role)}>Editar</Button>{' '}
                                        <Button variant="danger" onClick={(e) => handleDeleteRole(e, role.idRole)}>Eliminar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <FormRole showModal={showModal}
                        handleShow={handleShow}
                        handleClose={handleClose}
                        shouldUpdate={shouldUpdate}
                        getRoles={getRoles}
                        role={role} />
        </>
    );
};

export default RolesList;