import axios from "axios";

export const GET_ALL = "users";
export const GET_ALL_SEARCH = "users/name_username";
export const SAVE = "users";
export const UPDATE = "users";
export const UPDATE_PASS = "users/password";
export const UPDATE_UNLOCK = "users/unlock";
export const DELETE = "users";

export function getAll() {
    return axios.get(GET_ALL);
}

export function getAllPageable(pageNumber) {
    return axios.get(`${GET_ALL}?page=${pageNumber}`);
}

export function getAllPageableSearch(search) {
    return axios.get(`${GET_ALL_SEARCH}?search=${search}`);
}

export function save(data) {
    return axios.post(SAVE, data);
}

export function update(data) {
    return axios.put(`${UPDATE}/${data.idUser}`, data);
}

export function updatePassword(data) {
    return axios.put(`${UPDATE_PASS}/${data.idUser}`, data);
}

export function unlockUser(data) {
    return axios.put(`${UPDATE_UNLOCK}/${data.idUser}`, data);
}

export function destroy(roleId) {
    return axios.delete(`${DELETE}/${roleId}`);
}
