//React, Bootstrap & Others
import React, { useState, useEffect } from "react";
import {Card, Table, Button, Form} from "react-bootstrap";
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

//Metronic
import { IfHasPermission } from "@metronic/_helpers";
import {useSubheader} from "@metronic/layout";

//Components
import FormUser from "./FormUser";
import FormPassword from "./FormPassword";

//Services
import {getAllPageable, getAllPageableSearch, unlockUser, destroy} from "@services/usersCrud"

const UsersList = () => {
    const subheader = useSubheader();

    const userInitialState = {
        name: "",
        userName: "",
        email: "",
        password: "",
        roleId: null,
    };

	const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showModalPass, setShowModalPass] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [user, setUser] = useState(userInitialState);
    const [searchUser, setSearchUser] = useState("");
    const [paramsPagination, setParamsPagination] = useState({
        activePage: 1,
        totalPages: null,
        itemsCountPerPage: null,
        totalItemsCount: 0,
    });
    const hasPermissionToUnlock = IfHasPermission("Desbloquear Usuario");

    useEffect(() => {
        getUsers(paramsPagination.activePage);

        subheader.setTitle("Listado de Usuarios");
    }, []);

    const getUsers = (pageNumber) => {
        getAllPageable(pageNumber).then(response => {
            setParamsPagination({
                activePage: pageNumber,
                totalPages: response.data.totalPages,
                itemsCountPerPage: response.data.size,
                totalItemsCount: response.data.totalElements,
            });
            setUsers(response.data.content);
        });
    }
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleClosePass = () => setShowModalPass(false);
    const handleShowPass = () => setShowModalPass(true);

    const handlePageChange = (pageNumber) => {
        setParamsPagination({...paramsPagination, activePage: pageNumber });
        getUsers(pageNumber);
    }

    const handleDeleteUser = (e, userId) => {
        e.preventDefault();
        destroy(userId).then(response => {
            getUsers();
            swal("Good job!", "Se ha eliminado", "success");
        });
    }

    const handleStoreUser = (e) => {
        handleShow();
        setUser(userInitialState);
        setShouldUpdate(false);
    }

    const handleUnlockUser = (e, user) => {
        e.preventDefault();
        unlockUser(user).then(response => {
            getUsers();
            swal("Good job!", "Se ha desbloqueado", "success");
        });
    }

    const handleEditUser = (e, user) => {
        e.preventDefault();
        setUser({ ...user });
        handleShow();
        setShouldUpdate(true);
    }

    const handleEditPassword = (e, user) => {
        e.preventDefault();
        setUser({ ...user });
        setShowModalPass(true);
    }

    const handleSearchUser = (e) => {
        setSearchUser(e.target.value);
        if (e.target.value === "") {
            getUsers(1);
        } else {
            getAllPageableSearch(e.target.value).then(response => {
                setParamsPagination({
                    activePage: 1,
                    totalPages: response.data.totalPages,
                    itemsCountPerPage: response.data.size,
                    totalItemsCount: response.data.totalElements,
                });
                setUsers(response.data.content);
            });
        }
    }

    return (
        <>
            <Card>
                <Card.Body>
                    <Form.Group controlId="name">
                        <Form.Label>Buscar por nombre o usuario</Form.Label>
                        <Form.Control type="text"
                                    value={searchUser}
                                    onChange={handleSearchUser}
                                    name="name"
                                    placeholder="Escribe el nombre o usuario" />
                    </Form.Group>
                    <Button variant="success" onClick={handleStoreUser}>Agregar</Button>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Usuario</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map( (user, idx) => (
                                <tr key={idx}>
                                    <td>{user.name}</td>
                                    <td>{user.userName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role?.name}</td>
                                    <td>
                                        {hasPermissionToUnlock && (
                                            <Button className="btn-sm" variant="warning" onClick={(e) => handleUnlockUser(e, user)}
                                                disabled={user.accountNonLocked}>
                                                Desbloquear
                                            </Button>
                                        )}{' '}
                                        <Button className="btn-sm" variant="success" onClick={(e) => handleEditUser(e, user)}>Editar</Button>{' '}
                                        <Button className="btn-sm" variant="success" onClick={(e) => handleEditPassword(e, user)}>Editar Password</Button>{' '}
                                        <Button className="btn-sm" variant="danger" onClick={(e) => handleDeleteUser(e, user.idUser)}>Eliminar</Button>{' '}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center">
                        <Pagination
                            activePage={paramsPagination.activePage}
                            itemsCountPerPage={paramsPagination.itemsCountPerPage}
                            totalItemsCount={paramsPagination.totalItemsCount}
                            pageRangeDisplayed={10}
                            itemClass='page-item'
                            linkClass='btn btn-light'
                            onChange={handlePageChange}
                        />
                    </div>
                </Card.Body>
            </Card>

            <FormUser showModal={showModal}
                    handleShow={handleShow}
                    handleClose={handleClose}
                    shouldUpdate={shouldUpdate}
                    getUsers={getUsers}
                    user={user} />

            <FormPassword showModal={showModalPass}
                    handleShow={handleShowPass}
                    handleClose={handleClosePass}
                    user={user} />
        </>
    );
};

export default UsersList;
