import React, { useEffect, useState } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import Pagination from "react-js-pagination";
import GeneralFilter from "@components/GeneralFilter";
import { getAllToday } from "@services/casesService";
import GeneralTable from "@components/GeneralTable";
import CaseContext from "@contexts/CaseContext";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

const AlwaysWithYou = () => {
  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
      var queryParams = [];

      if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
        queryParams = ['&byRegion=true', '&byCell=true'];
      }

      getAllToday(pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);

    subheader.setTitle("24/7 Contigo");
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        24/7 Contigo
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[]}
          hideInsurance={true}
          hideConciergeAssigned={false}
          hideHospitalInvoice={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideModule={false}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/24_7_contigo_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideReasonColumn={true}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        hideCelulaColumn ={false}
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default AlwaysWithYou;
