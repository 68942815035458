//React, Bootstrap & Others
import React from "react";
import {Table, Button} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';

//Services
import { getBySlug } from "@services/hospitalizationcrud";

//Helpers
import { RegistrationCaseType, CaseTypeSlaFlagColumn } from '@helpers/constants';

const GeneralTable = ({ statusTitle, reasonTitle, reason, assignationColumn, showButtonGo = true, urlToGo, query = "", data, ...props }) => {
    const refresh = () => window.location.reload()
    const now = moment(new Date());
    const history = useHistory();

    function checkDiffDays(moment1, moment2) {
        let diffDays = moment1.diff(moment2, 'days')
        if (diffDays <= 1)
            return "<1";
        else if (diffDays > 2 && diffDays <= 10)
            return ">2";
        else
            return ">10";
    }

    return (
        <>
            {/*<div className="mt-5">Mostrando un total de {props.totalItemsCount} registros</div>*/}
            <button hidden={props?.hideReloadPageButton} onClick={refresh} className="btn btn-primary mb-1 mt-2">Actualizar</button>

            <Table responsive striped bordered hover className="mt-5">
                <thead>
                <tr>
                    <th hidden={props?.hideOptionsColumn}>Opciones</th>
                    <th hidden={props?.hideExtemporaneousFlagColumn}>
                        SLA Extemporáneos
                    </th>
                    <th hidden={props?.hideFlagsColumn}>
                        SLA Total Alta
                    </th>
                    <th hidden={props?.hideFlagsColumn}>
                        SLA Modulo
                    </th>
                    <th hidden={props?.hideCounterColumn}>Conteo</th>
                    <th>Clave</th>
                    <th>Tipo de TRÁMITE</th>
                    <th hidden={props?.hideIngressType}>Tipo de Ingreso</th>
                    <th>Asegurado</th>
                    <th>Hospital</th>
                    <th hidden={props?.hideDateTimeExtemporaneous}>Fecha hora solicitud extemporanea</th>
                    <th hidden={props?.hideDateTimeOutHospitalFollowUpColumn}>Fecha hora respuesta extemporanea</th>
                    <th hidden={props?.hospitalIden}>Folio Hospital / Episodio</th>
                    <th hidden={props?.hideAdmissionDateTimeColumn}>Fecha Hora Ingreso (dd/mm/aaaa hh:mm:ss)</th>
                    <th hidden={props?.hideDiagnosticColumn}>DIAGNÓSTICO Presuntivo</th>
                    <th hidden={props?.hideDateTimeRequestColumn}>Fecha Hora Solicitud (dd/mm/aaaa hh:mm:ss)</th>
                    <th hidden={props?.hideComprehensiveCareColumn}>Cuidado Integral</th>
                    <th hidden={props?.hideUserColumn}>Usuario</th>
                    <th hidden={props?.hideStatusTitleOrModuleColumn}>{statusTitle || 'Modulo'}</th>
                    <th hidden={props?.hideReasonColumn}>{reasonTitle || "Motivo de Envio"}</th>
                    <th hidden={props?.hideAssignedDoctorColumn}>Medico Asignado</th>
                    <th hidden={props?.hideAssignedNurseColumn}>Enfermera Asignada</th>
                    <th hidden={props?.hideAssignedConciergeColumn}>Concierge Asignado</th>
                    <th hidden={props?.hideAxaCategoryColumn}>CATEGORÍA AXA</th>
                    <th hidden={props?.hidePolicyTypeColumn}>Tipo de poliza</th>
                    <th hidden={props?.hideCelulaColumn}>Célula</th>
                    <th hidden={props?.hideDaysStayColumn}>Dias Estancia</th>
                    <th hidden={props?.hideMountsColumn}>Montos</th>
                    <th hidden={props?.hideHospitalDateTimeHireColumn}>Fecha / Hora de Alta Hospitalaria (dd/mm/aaaa
                        hh:mm:ss)
                    </th>
                    <th hidden={!assignationColumn || assignationColumn === ""}>Fecha / Hora Asignacion del Caso
                        (dd/mm/aaaa hh:mm:ss)
                    </th>
                    <th hidden={props?.hidePatientStatusColumn}>Estatus Alta Paciente</th>
                    <th hidden={props?.hideExtemporaneousStatus}>Dictamen Extemporáneo</th>
                    <th hidden={props?.hideTabulationColumn}>Tabulacion</th>
                </tr>
                </thead>
                {!data ?
                    (
                        <tbody>
                        </tbody>
                    )
                    : (
                        <tbody>
                        {data?.map((currCase, i) => {
                            return (
                                <tr key={i}>
                                    <td hidden={props?.hideOptionsColumn}>
                                        {!props.hideOptionsButton && props.currModule === "Mesa de Control" && !currCase.caseLocked
                                            && <Link className="btn btn-primary" to={urlToGo + currCase.slug}
                                                     onClick={async e => {
                                                         if (props.currModule === "Mesa de Control") {
                                                             e.preventDefault();
                                                             let response = await getBySlug(currCase.slug);
                                                             if (response.data.caseWorking) {
                                                                 alert("El caso esta siendo trabajado por un usuario");
                                                                 return;
                                                             }

                                                             if (response.data?.status?.id !== 2 && response.data?.status?.id !== 9) {
                                                                 alert("El caso ya no se encuentra en este modulo");
                                                                 return;
                                                             }

                                                             history.push(urlToGo + currCase.slug);
                                                         }
                                                     }}>
                                                Ir
                                            </Link>}

                                        {!props.hideOptionsButton && props.currModule !== "Mesa de Control" &&
                                            <Link className="btn btn-primary" to={urlToGo + currCase.slug}>
                                                Ir
                                            </Link>}

                                        {props.hideOptionsButton && <Button className="btn btn-primary"
                                                                            onClick={e => props.activateCaseFunc(currCase.id)}>
                                            Activar
                                        </Button>}
                                    </td>
                                    <td hidden={props?.hideExtemporaneousFlagColumn}>
                                        <i className="fas fa-flag" style={{color: currCase.flagModule}}></i>
                                    </td>
                                    <td hidden={props?.hideFlagsColumn}>
                                        {CaseTypeSlaFlagColumn.includes(currCase.caseType) &&
                                            <i className="fas fa-flag" style={{color: currCase.flagHigh}}></i>}
                                    </td>
                                    <td hidden={props?.hideFlagsColumn}>
                                        <i className="fas fa-flag" style={{color: currCase.flagModule}}></i>
                                    </td>
                                    <td hidden={props?.hideCounterColumn}>{((props.activePage - 1) * 20) + i + 1}</td>
                                    <td>{currCase.caseTicket}</td>
                                    <td>{currCase.caseType}</td>
                                    <td hidden={props?.hideIngressType}>{currCase.incomeType}</td>
                                    <td>{`${currCase.assuredName} ${currCase.assuredFlastname} ${currCase.assuredMlastname}`}</td>
                                    <td>{currCase.hospital?.hospitalName}</td>
                                    <td hidden={props?.hideDateTimeExtemporaneous}>{moment.utc(currCase.fecextemporaneous).format('DD/MM/YYYY H:mm:ss A')}</td>
                                    <td hidden={props?.hideDateTimeOutHospitalFollowUpColumn}>
                                        {moment.utc(currCase.fecextemporaneous).format('DD/MM/YYYY H:mm:ss A')}
                                    </td>
                                    <td hidden={props?.hospitalIden}>{currCase.hospitalIden}</td>
                                    <td hidden={props?.hideAdmissionDateTimeColumn}>{moment.utc(currCase.incomeDatetime).format("DD/MM/Y HH:mm:ss A")}</td>
                                    <td hidden={props?.hideDiagnosticColumn}>{currCase.diagnosis}</td>
                                    <td hidden={props?.hideDateTimeRequestColumn}>{moment.utc(currCase.createdAt).format("DD/MM/Y HH:mm:ss A")}</td>
                                    <td hidden={props?.hideComprehensiveCareColumn}>{currCase.comprehensiveCare}</td>
                                    <td hidden={props?.hideUserColumn}>{currCase.user?.userName}</td>
                                    <td hidden={props?.hideStatusTitleOrModuleColumn}>
                                        {(() => {
                                            if (statusTitle) {
                                                if (statusTitle === "Estatus Mesa de Control") {
                                                    return currCase.reasonMC;
                                                } else {
                                                    return currCase.statusCC;
                                                }
                                            } else {
                                                return currCase.status?.descr;
                                            }
                                        })()}
                                    </td>
                                    <td hidden={props?.hideReasonColumn}>{(() => {
                                        if (reason === "ControlDesk") {
                                            return currCase.reasonMC
                                        } else if (reason === "Tracing") {
                                            return currCase.tracingReason
                                        } else if (reason === "Information") {
                                            return currCase.informationReason
                                        } else if (reason === "Dictum") {
                                            return currCase.statusDictum
                                        } else {
                                            return "";
                                        }
                                    })()}</td>
                                    <td hidden={props?.hideAssignedDoctorColumn}>{currCase.doctor?.fullName}</td>
                                    <td hidden={props?.hideAssignedNurseColumn}>{currCase.nurse?.fullName}</td>
                                    <td hidden={props?.hideAssignedConciergeColumn}>{currCase.concierge?.conciergeName}</td>
                                    <td hidden={props?.hideAxaCategoryColumn}>{currCase.axaCategories}</td>
                                    <td hidden={props?.hidePolicyTypeColumn}>{currCase.axaCategories?.split(',')?.filter(category => category === 'VIP') || ''}</td>
                                    <td hidden={props?.hideCelulaColumn}>{currCase.cell?.cellDescription}</td>
                                    <td hidden={props?.hideDaysStayColumn}>
                                        {currCase.caseType === "Alta hospitalaria" ? checkDiffDays(now, moment(currCase.hospitalDateHire)) : checkDiffDays(now, moment(currCase.incomeDatetime))}
                                    </td>
                                    <td hidden={props?.hideMountsColumn}>
                                        {currCase.sumLetters > 500000 ? "> $500,000" : "< $500,000"}
                                    </td>
                                    <td hidden={props?.hideHospitalDateTimeHireColumn}>
                                        {
                                            currCase.hospitalDateHire && RegistrationCaseType.includes(currCase.caseType)
                                                ? moment.utc(currCase.hospitalDateHire).format('DD/MM/YYYY H:mm:ss A')
                                                : ''
                                        }
                                    </td>
                                    <td hidden={!assignationColumn || assignationColumn === ""}>
                                        {(() => {
                                            if (currCase[assignationColumn] && currCase.caseStatusId !== 8) {
                                                return moment.utc(currCase[assignationColumn]).format('DD/MM/YYYY H:mm:ss A');
                                            } else if (assignationColumn && assignationColumn === "feaapaciente" && currCase.caseStatusId === 8 && currCase["fecfinalizada"]) {
                                                return moment.utc(currCase["fecfinalizada"]).format('DD/MM/YYYY H:mm:ss A');
                                            } else {
                                                return "";
                                            }
                                        })()}
                                    </td>
                                    <td hidden={props?.hidePatientStatusColumn}>{currCase.status.stage}</td>
                                    <td hidden={props?.hideExtemporaneousStatus}>{currCase.dictumReason}</td>
                                    <td hidden={props?.hideTabulationColumn}>{currCase.requiresTabulation}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    )
                }
            </Table>
        </>
    );
};

GeneralTable.defaultProps = {
    hideReloadPageButton: false,
    hideOptionsButton: false,
    hideOptionsColumn: false,
    hideExtemporaneousFlagColumn: true,
    hideFlagsColumn: false,
    hideCounterColumn: false,
    hideAdmissionDateTimeColumn: false,
    hideDiagnosticColumn: false,
    hideReasonColumn: false,
    hideAssignedDoctorColumn: false,
    hideAssignedNurseColumn: false,
    hideAssignedConciergeColumn: false,
    hideAxaCategoryColumn: false,
    hidePolicyTypeColumn: false,
    hospitalIden: false,
    hideModule: false,
    hideComprehensiveCare: false,
    hideCelulaColumn: true,
    hideDaysStayColumn: false,
    hideMountsColumn: false,
    hideHospitalDateTimeHireColumn: false,
    hidePatientStatusColumn: false,
    hideTabulationColumn: false,
    hideUserColumn: true,
    hideExtemporaneousStatus: true,
    hideIngressType: false,
    hideDateTimeRequestColumn: false,
    hideComprehensiveCareColumn: false,
    hideStatusTitleOrModuleColumn: false,
    hideDateTimeExtemporaneous: true,
    hideDateTimeOutHospitalFollowUpColumn: true,
}

export default GeneralTable;
