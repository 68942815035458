import React, { useState, useEffect } from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";
import Pagination from "react-js-pagination";
import GeneralTable from "@components/GeneralTable";
import { getCasesByStatuses } from "@services/casesService";
import GeneralFilter from "@components/GeneralFilter";
import CaseContext from "@contexts/CaseContext";

const Tracing = () => {
  window.currModule = "Seguimiento";
  window.assignationColumn = "feaseguimiento";

  const subheader = useSubheader();

  const [casesRecord, setCasesRecord] = useState([]);
  const [paramsPagination, setParamsPagination] = useState({
    activePage: 1,
    totalPages: null,
    itemsCountPerPage: null,
    totalItemsCount: 0,
  });

  const getAllCasesByStatus = (pageNumber) => {
    var queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    getCasesByStatuses([3], pageNumber, queryParams).then((response) => {
      setCasesRecord(response.data);
      setParamsPagination({
        activePage: pageNumber,
        totalPages: response.totalPages,
        itemsCountPerPage: response.size,
        totalItemsCount: response.totalElements,
      });
    });
  }

  const handlePageChange = async (pageNumber) => {
    setParamsPagination({ ...paramsPagination, activePage: pageNumber, updatePage: true });

    if (!paramsPagination.hasOwnProperty("fromFilter")) {
      getAllCasesByStatus(pageNumber);
    }
  }

  useEffect(() => {
    getAllCasesByStatus(paramsPagination.activePage);
    

    subheader.setTitle("Seguimiento");
  }, []);

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Seguimiento
      </Alert>

      <CaseContext.Provider value={[
        casesRecord, setCasesRecord,
        paramsPagination, setParamsPagination
      ]}>
        <GeneralFilter
          statuses={[3]}
          hideAxaCategoryId={true}
          hideCategoryVip={true}
        />
      </CaseContext.Provider>

      <GeneralTable
        urlToGo="/seguimiento_detalles/"
        data={casesRecord}
        activePage={paramsPagination.activePage}
        totalItemsCount={paramsPagination.totalItemsCount}
        hideAssignedDoctorColumn={true}
        hideAssignedNurseColumn={true}
        hideAssignedConciergeColumn={true}
        hideAxaCategoryColumn={true}
        hideHospitalDateTimeHireColumn={true}
        hidePatientStatusColumn={true}
        hideTabulationColumn={true}
        hidePolicyTypeColumn={true}
        reason="Tracing"
        assignationColumn="feaseguimiento"
        currModule="Seguimiento"
      />

      <div className="d-flex justify-content-center">
        <Pagination
          activePage={paramsPagination.activePage}
          itemsCountPerPage={paramsPagination.itemsCountPerPage}
          totalItemsCount={paramsPagination.totalItemsCount}
          pageRangeDisplayed={10}
          itemClass="page-item"
          linkClass="btn btn-light"
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default Tracing;
