import React, {useEffect, useState} from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner, Row, Col} from "react-bootstrap";
import {save, update} from "@services/hospitalsService";
import {getAllCells} from "@services/cellService";
import {getInputClasses} from "@utils/getInputclasses";

import AlertError from "@components/AlertError";

const FormHospital = (props) => {
    const [cells, setCells] = useState([]);

    const validationSchema = Yup.object().shape({
        hospitalName: Yup.string()
            .required("El campo es requerido"),
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        setTimeout(() => {
            if (props.shouldUpdate) {
                update(values, props.hospital.id).then(response => {
                    props.getHospitals();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al actualizar");
                });
            } else {
                values.isActive = 1;
                save(values).then(response => {
                    props.getHospitals();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al guardar");
                });
            }
        }, 1000);
    }

    useEffect(() => {
        const hideCells = ['Ágil', 'VIP', 'Urgente', 'No Urgente', 'Facultamiento 24/7'];

        getAllCells().then(result => {
            result.data = result.data.filter(cell => !hideCells.includes(cell.cellDescription))
            setCells(result.data)
        });
    }, []);


    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{props.shouldUpdate ? "Editar" : "Agregar"} hospital</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{...props.hospital}} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleSubmit, status, isSubmitting}) => (
                            <>
                                <AlertError status={status} />
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalName">
                                                <Form.Label>Nombre</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalName}
                                                            onChange={handleChange}
                                                            name="hospitalName"
                                                            placeholder="Escribe el nombre"
                                                            className={`${getInputClasses(touched, errors, "hospitalName")}`} />
                                                <ErrorMessage name="hospitalName">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="hospitalContact">
                                                <Form.Label>Contacto</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalContact || ''}
                                                            onChange={handleChange}
                                                            name="hospitalContact"
                                                            placeholder="Escribe el contacto"
                                                            className={`${getInputClasses(touched, errors, "hospitalContact")}`} />
                                                <ErrorMessage name="hospitalContact">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalPhone">
                                                <Form.Label>Telefono</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalPhone || ''}
                                                            onChange={handleChange}
                                                            name="hospitalPhone"
                                                            placeholder="Escribe el telefono"
                                                            className={`${getInputClasses(touched, errors, "hospitalPhone")}`} />
                                                <ErrorMessage name="hospitalPhone">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="hospitalExt">
                                                <Form.Label>Extension</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalExt || ''}
                                                            onChange={handleChange}
                                                            name="hospitalExt"
                                                            placeholder="Escribe la extension"
                                                            className={`${getInputClasses(touched, errors, "hospitalExt")}`} />
                                                <ErrorMessage name="hospitalExt">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalEmail || ''}
                                                            onChange={handleChange}
                                                            name="hospitalEmail"
                                                            placeholder="Escribe el email"
                                                            className={`${getInputClasses(touched, errors, "hospitalEmail")}`} />
                                                <ErrorMessage name="hospitalEmail">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="hospitalEst">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalEst || ''}
                                                            onChange={handleChange}
                                                            name="hospitalEst"
                                                            placeholder="Escribe el estado"
                                                            className={`${getInputClasses(touched, errors, "hospitalEst")}`} />
                                                <ErrorMessage name="hospitalEst">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalKeyAtc">
                                                <Form.Label>Clave Hospital ATC</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalKeyAtc || ''}
                                                            onChange={handleChange}
                                                            name="hospitalKeyAtc"
                                                            placeholder="Escribe la clave hospital ATC"
                                                            className={`${getInputClasses(touched, errors, "hospitalKeyAtc")}`} />
                                                <ErrorMessage name="hospitalKeyAtc">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="rfc">
                                                <Form.Label>RFC</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.rfc || ''}
                                                            onChange={handleChange}
                                                            name="rfc"
                                                            placeholder="Escribe el RFC"
                                                            className={`${getInputClasses(touched, errors, "rfc")}`} />
                                                <ErrorMessage name="rfc">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalGroup">
                                                <Form.Label>Grupo Hospitalario</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalGroup || ''}
                                                            onChange={handleChange}
                                                            name="hospitalGroup"
                                                            placeholder="Escribe el grupo hospitalario"
                                                            className={`${getInputClasses(touched, errors, "hospitalGroup")}`} />
                                                <ErrorMessage name="hospitalGroup">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="zone">
                                                <Form.Label>Zona</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.zone || ''}
                                                            onChange={handleChange}
                                                            name="zone"
                                                            placeholder="Escribe la zona"
                                                            className={`${getInputClasses(touched, errors, "zone")}`} />
                                                <ErrorMessage name="zone">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="hospitalLevel">
                                                <Form.Label>Nivel hospitalario</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.hospitalLevel || ''}
                                                            onChange={handleChange}
                                                            name="hospitalLevel"
                                                            placeholder="Escribe el nivel hospitalario"
                                                            className={`${getInputClasses(touched, errors, "hospitalLevel")}`} />
                                                <ErrorMessage name="hospitalLevel">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="vip">
                                                <Form.Label>VIP</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.vip || ''}
                                                            onChange={handleChange}
                                                            name="vip"
                                                            placeholder="Escribe el VIP"
                                                            className={`${getInputClasses(touched, errors, "vip")}`} />
                                                <ErrorMessage name="vip">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="always">
                                                <Form.Label>24/7</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.always || ''}
                                                            onChange={handleChange}
                                                            name="always"
                                                            placeholder="Escribe el 24/7"
                                                            className={`${getInputClasses(touched, errors, "always")}`} />
                                                <ErrorMessage name="always">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="atc">
                                                <Form.Label>ATC</Form.Label>
                                                <Form.Control type="text"
                                                            value={values.atc || ''}
                                                            onChange={handleChange}
                                                            name="atc"
                                                            placeholder="Escribe el atc"
                                                            className={`${getInputClasses(touched, errors, "atc")}`} />
                                                <ErrorMessage name="atc">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="model">
                                                <Form.Label>Modelo</Form.Label>
                                                <Form.Control as="select"
                                                              value={values.model || ''}
                                                              onChange={handleChange}
                                                              name="model"
                                                              className={`${getInputClasses(touched, errors, "model")}`}>
                                                    <option value="">Selecciona una opcion...</option>
                                                    <option value="HOSPITAL CON ATC PLUS">HOSPITAL CON ATC PLUS</option>
                                                    <option value="MANUAL">MANUAL</option>
                                                </Form.Control>
                                                <ErrorMessage name="model">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="modelDescription">
                                                <Form.Label>Modelo descripción</Form.Label>
                                                <Form.Control as="select"
                                                            value={values.modelDescription || ''}
                                                            onChange={handleChange}
                                                            name="modelDescription"
                                                            className={`${getInputClasses(touched, errors, "modelDescription")}`}>
                                                    <option value="">Selecciona una opcion...</option>
                                                    <option value="CON 24/7 CON ATC PLUS">CON 24/7 CON ATC PLUS</option>
                                                    <option value="CON 24/7 MANUAL">CON 24/7 MANUAL</option>
                                                    <option value="SIN 24/7 CON ATC PLUS">SIN 24/7 CON ATC PLUS</option>
                                                    <option value="SIN 24/7 MANUAL">SIN 24/7 MANUAL</option>
                                                </Form.Control>
                                                <ErrorMessage name="modelDescription">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Group controlId="region">
                                                <Form.Label>Region</Form.Label>
                                                <Form.Control as="select"
                                                              value={values.region || ''}
                                                              onChange={handleChange}
                                                              name="region"
                                                              className={`${getInputClasses(touched, errors, "region")}`}>
                                                    <option value="">Selecciona una opcion...</option>
                                                    <option value="Región I">Región I</option>
                                                    <option value="Región II">Región II</option>
                                                    <option value="Región pendiente">Región pendiente</option>
                                                </Form.Control>
                                                <ErrorMessage name="region">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>

                                        <Col>
                                            <Form.Group controlId="cell">
                                                <Form.Label>Celula</Form.Label>
                                                <Form.Control as="select"
                                                              value={values.cell || ''}
                                                              onChange={handleChange}
                                                              name="cell"
                                                              className={`${getInputClasses(touched, errors, "cell")}`}>
                                                    <option value="">Selecciona una opcion...</option>
                                                    {cells?.map( (cell, idx) => (
                                                        <option key={idx} value={cell.cellDescription}>{cell.cellDescription}</option>
                                                    ))}
                                                </Form.Control>
                                                <ErrorMessage name="modelDescription">
                                                    { msg => (
                                                        <Form.Control.Feedback type="invalid">
                                                            {msg}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </ErrorMessage>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                                        Guardar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FormHospital;
