import moment from "moment";
import React from "react";
import { Table, Row, Alert } from "react-bootstrap";

export const ListReviewCuts = ({ reviewCuts }) => {
  return (
    <>
      <Row md={4} className="ml-1">
        <Alert
          variant="primary"
          className="text-center mt-4"
          style={{ fontSize: "13px" }}
        >
          Estados de Cuenta
        </Alert>
      </Row>

      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Tipo de Trámite / Movimiento</th>
            <th>Tipo de folio ATC</th>
            <th>Folio ATC</th>
            <th>Fecha Hora (DD/MM/AAAA HH:MM:SS)</th>
            <th>Usuario</th>
          </tr>
        </thead>
        <tbody>
          {reviewCuts?.map( (reviewCut, idx) => (
            <tr key={idx}>
              <td>{reviewCut.caseType}</td>
              <td>{reviewCut.typeInvoice}</td>
              <td>{reviewCut.invoiceATC}</td>
              <td>{moment(reviewCut.createdAt).format("DD/MM/Y HH:mm:ss A")}</td>
              <td>{reviewCut.user.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};