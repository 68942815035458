//React, Bootstrap & Others
import React, { useState, useEffect, useContext } from "react";
import { Col, Form, Row, Button, Spinner } from "react-bootstrap";
import { ErrorMessage, Formik } from "formik";
import { useLocation } from "react-router-dom";

//Metronic
import { IfHasRoleByLocalStorage } from "@metronic/_helpers";

//Components
import AlertError from "./AlertError";

//Services
import { getAllConcierges } from "@services/conciergeService";
import { getAllCells } from "@services/cellService";
import { getAll } from "@services/hospitalsService";
import { getAll as getAllAxa } from "@services/axaService";
import { getAllByFilter } from "@services/casesService";

//Contexts
import CaseContext from "@contexts/CaseContext";

//Utils
import { getInputClasses } from "@utils/getInputclasses";

const GeneralFilter = (props) => {
  const hideCells = ['Ágil', 'VIP', 'Urgente', 'No Urgente', 'Facultamiento 24/7'];

  const [, setCasesRecord, paramsPagination, setParamsPagination] = useContext(CaseContext);
  const [hospitals, setHospitals] = useState([]);
  const [axas, setAxas] = useState([]);
  const [concierges, setConcierges] = useState([]);
  const [cells, setCells] = useState([]);

  const location = useLocation();
  const caseTypeOptions = props.caseTypeOptions || [
    { value: 'Ingreso Hospitalario', label: 'Ingreso Hospitalario' },
    { value: "Aumento de suma", label: "Aumento de Suma" },
    { value: "Alta hospitalaria", label: "Alta Hospitalaria" },
    { value: "Tabulador", label: "Tabulador" },
    { value: "Seguimiento", label: "Seguimiento" },
    { value: "Dictamen", label: "Dictamen" },
    { value: "Reconsideración de Alta", label: "Reconsideración de Alta" },
    { value: "Reconsideración de dictamen", label: "Reconsideración de Dictamen" },
    { value: "Alta express", label: "Alta express" },
    { value: "Revision de corte", label: "Revision de corte" }
  ];

  const getConcierges = () => getAllConcierges().then((data) => setConcierges(data.data));
  const getAxaCategories = () => getAllAxa().then((data) => setAxas(data.data));
  const getHospitals = () => getAll().then((data) => setHospitals(data.data));
  const getCells = () => getAllCells().then(result => {
    result.data = result.data.filter(cell => !hideCells.includes(cell.cellDescription))
    setCells(result.data)
  });
  const refresh = () => window.location.reload();

  const getAllCasesByFilter = async (body, page) => {
    let queryParams = [];

    if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA") && (props.statuses?.includes("2") || props.statuses?.includes("9"))) {
      queryParams = ['&byRegion=true', '&byCell=true'];
    }

    let response;
    if (props.getCasesOwnFunc) {
      response = await props.getCasesOwnFunc(body);
      setCasesRecord(response);
    } else {
      response = await getAllByFilter(body, props.statuses, page, queryParams);
      setCasesRecord(response.data);

      if (props.callbackEmptyResults && response.data?.length === 0) {
        props.callbackEmptyResults();
      }
    }
    return response;
  }

  const handleSubmitForm = async (values, { setStatus, setSubmitting }) => {
    const body = {
      'caseStatusIdOne': props.status,
      'caseStatusIdTwo': props.status2,
      'caseStatusIdThree': props.status3,
      'caseStatusIdFour': props.status4,
      'getCasesLocked': props.getCasesLocked,
      'periodEqual': values.period_equal,
      'periodFrom': values.period_from,
      'periodTo': values.period_to,
      'hospital_code': values.hospital_code,
      'insurance_id': values.insurance_id,
      'hospital_id': values.hospital_id,
      'type_policy': values.type_policy,
      'requires_tabulation': values.requires_tabulation,
      'movement_type': values.movement_type,
      'presumptive_diagnosis': values.presumptive_diagnosis,
      'inssured_name': values.inssured_name,
      'axa_category_id': values.axa_category_id,
      'reasonMc': values.reasonMc,
      'stage': values.stage,
      'category_vip': values.category_vip,
      'hospital_type': values.hospital_type,
      'concierge_assigned': values.concierge_assigned,
      'atcplus_folio': values.atcplus_folio,
      'dias_estancia': values.dias_estancia,
      'montos': values.montos,
      'region': values.region,
      'celula': values.celula,
      'module': values.module,
      'comprehensiveCare': values.comprehensiveCare,
      'atentionIden': values.atentionIden,
      'identificator': values.identificator,
      'pathName': location.pathname,
    };

    const response = await getAllCasesByFilter(body, 1);
    setParamsPagination({
      activePage: 1,
      totalPages: response.totalPages,
      itemsCountPerPage: response.size,
      totalItemsCount: response.totalElements,
      fromFilter: true,
      body: body,
    });

    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  }

  useEffect(() => {
    getHospitals();
    getAxaCategories();
    getConcierges();
    getCells();
  }, []);

  useEffect(() => {
    if (paramsPagination && paramsPagination.hasOwnProperty("fromFilter") && paramsPagination.hasOwnProperty("updatePage")) {
      getAllCasesByFilter(paramsPagination.body, paramsPagination.activePage);
    }
  }, [paramsPagination]);

  return (
    <Formik
      initialValues={{
        inssured_name: "",
        period_equal: "",
        period_from: "",
        period_to: "",
        hospital_code: "",
        insurance_id: "AXA",
        hospital_id: "",
        requires_tabulation: "",
        movement_type: "",
        presumptive_diagnosis: "",
        hospital_type: "",
        concierge_assigned: "",
        atcplus_folio: "",
        dias_estancia: "",
        montos: "",
        region: "",
        celula: "",
        module: "",
      }}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
      }) => (
        <>
          <AlertError status={status} />

          <Form onSubmit={handleSubmit}>
            <Row>
              <Col hidden={props.hidePeriodEqual}>
                <Form.Group controlId="period_equal">
                  <Form.Label>Periodo de</Form.Label>
                  <Form.Control
                      type="date"
                      name="period_equal"
                      value={values.period_equal}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "period_equal"
                      )}`}
                  />
                  <ErrorMessage name="period_equal">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hidePeriodFrom}>
                <Form.Group controlId="period_from">
                  <Form.Label>Periodo de</Form.Label>
                  <Form.Control
                    type="date"
                    name="period_from"
                    value={values.period_from}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "period_from"
                    )}`}
                  />
                  <ErrorMessage name="period_from">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hidePeriodTo}>
                <Form.Group controlId="period_to">
                  <Form.Label>Periodo al</Form.Label>
                  <Form.Control
                    type="date"
                    name="period_to"
                    value={values.period_to}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "period_to"
                    )}`}
                  />
                  <ErrorMessage name="period_to">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideHospitalInvoice}>
                <Form.Group controlId="hospital_code">
                  <Form.Label>Folio Hospital / Episodio</Form.Label>
                  <Form.Control
                    type="text"
                    name="hospital_code"
                    value={values.hospital_code}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "hospital_code"
                    )}`}
                  />
                  <ErrorMessage name="hospital_code">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideInsurance}>
                <Form.Group controlId="insurance_id">
                  <Form.Label>Aseguradora</Form.Label>
                  <Form.Control
                    as="select"
                    name="insurance_id"
                    value={values.insurance_id}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "insurance_id"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="AXA">AXA</option>
                  </Form.Control>
                  <ErrorMessage name="insurance_id">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideHospital}>
                <Form.Group controlId="hospital_id">
                  <Form.Label>Hospital</Form.Label>
                  <Form.Control
                    as="select"
                    name="hospital_id"
                    value={values.hospital_id}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "hospital_id"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    {hospitals?.map((hospital, idx) => (
                      <option key={idx} value={hospital.id}>
                        {hospital.hospitalName}
                      </option>
                    ))}
                  </Form.Control>
                  <ErrorMessage name="hospital_id">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideAtentionIden}>
                <Form.Group controlId="atentionIden">
                  <Form.Label>Numero de atencion</Form.Label>
                  <Form.Control
                      type="text"
                      name="atentionIden"
                      value={values.atentionIden}
                      onChange={handleChange}
                      maxLength={10}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "atentionIden"
                      )}`}
                  />
                  <ErrorMessage name="atentionIden">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideIdentificator}>
                <Form.Group controlId="identificator">
                  <Form.Label>Numero de folio</Form.Label>
                  <Form.Control
                      type="text"
                      name="identificator"
                      value={values.identificator}
                      onChange={handleChange}
                      maxLength={10}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "identificator"
                      )}`}
                  />
                  <ErrorMessage name="identificator">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideTypePolicy}>
                <Form.Group controlId="type_policy">
                  <Form.Label>Tipo de poliza</Form.Label>
                  <Form.Control
                      as="select"
                      name="type_policy"
                      value={values.type_policy}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "type_policy"
                      )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value='VIP'>VIP</option>
                    <option value='CUIDADO INTEGRAL'>CUIDADO INTEGRAL</option>
                    <option value='VIP - CUIDADO INTEGRAL'>VIP - CUIDADO INTEGRAL</option>
                  </Form.Control>
                  <ErrorMessage name="type_policy">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideModule !== undefined ? props.hideModule : true }>
                <Form.Group controlId="module">
                  <Form.Label>Modulo</Form.Label>
                  <Form.Control
                      as="select"
                      name="module"
                      value={values.module}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "module"
                      )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value='3'>Seguimiento</option>
                    <option value='4'>Informacion Medica</option>
                  </Form.Control>
                  <ErrorMessage name="module">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideRequiresTabulation}>
                <Form.Group controlId="requires_tabulation">
                  <Form.Label>Requiere tabulacion</Form.Label>
                  <Form.Control
                    as="select"
                    name="requires_tabulation"
                    value={values.requires_tabulation}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "hospital_id"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="Si">
                      Si
                    </option>
                    <option value="No">
                      No
                    </option>
                  </Form.Control>
                  <ErrorMessage name="requires_tabulation">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col hidden={props.hideInssuredName}>
                <Form.Group controlId="inssured_name">
                  <Form.Label>Asegurado</Form.Label>
                  <Form.Control
                    type="text"
                    name="inssured_name"
                    value={values.inssured_name}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "inssured_name"
                    )}`}
                  />
                  <ErrorMessage name="inssured_name">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideMovementType}>
                <Form.Group controlId="movement_type">
                  <Form.Label>Tipo de TRÁMITE</Form.Label>
                  <Form.Control
                    as="select"
                    name="movement_type"
                    value={values.movement_type}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "movement_type"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    {caseTypeOptions && caseTypeOptions.map( ({value, label}, idx) => (
                        <option key={idx} value={value}>{label}</option>
                    ))}

                    {props.extraOptions}
                  </Form.Control>
                  <ErrorMessage name="movement_type">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hidePresumptiveDiagnosis}>
                <Form.Group controlId="presumptive_diagnosis">
                  <Form.Label>DIAGNÓSTICO Presuntivo</Form.Label>
                  <Form.Control
                    type="text"
                    name="presumptive_diagnosis"
                    value={values.presumptive_diagnosis}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "presumptive_diagnosis"
                    )}`}
                  />
                  <ErrorMessage name="presumptive_diagnosis">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideAxaCategoryId}>
                <Form.Group controlId="axa_category_id">
                  <Form.Label>CATEGORÍA AXA</Form.Label>
                  <Form.Control
                    as="select"
                    name="axa_category_id"
                    value={values.axa_category_id}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "axa_category_id"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    {axas?.map((axa, idx) => (
                      <option key={idx} value={axa.axaCatDescription}>
                        {axa.axaCatDescription}
                      </option>
                    ))}
                  </Form.Control>
                  <ErrorMessage name="axa_category_id">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideCategoryVip}>
                <Form.Group controlId="category_vip">
                  <Form.Label>CATEGORÍA VIP</Form.Label>
                  <Form.Control
                    as="select"
                    name="category_vip"
                    value={values.category_vip}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "category_vip"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Form.Control>
                  <ErrorMessage name="category_vip">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>
            </Row>

            <Row>
            <Col hidden={props.hideReason}>
                <Form.Group controlId="reasonMc">
                  <Form.Label>Motivo de Envio</Form.Label>
                  <Form.Control
                    as="select"
                    name="reasonMc"
                    value={values.reasonMc}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "reasonMc"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="Carta Erronea">Carta Erronea</option>
                    <option value="Monto en Carta Insuficiente">Monto en Carta Insuficiente</option>
                    <option value="Asignacion Incorrecta">Asignacion Incorrecta</option>
                    <option value="Poliza VIP">Poliza VIP</option>
                    <option value="Asignación Incorrecta VIP">Asignación Incorrecta VIP</option>
                  </Form.Control>
                  <ErrorMessage name="reasonMc">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideStatusPatient}>
                <Form.Group controlId="stage">
                  <Form.Label>Estatus Alta Paciente </Form.Label>
                  <Form.Control
                    as="select"
                    name="stage"
                    value={values.stage}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "stage"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="Finalizado">Caso Finalizado</option>
                    <option value="Alta Paciente">Alta Paciente</option>
                  </Form.Control>
                  <ErrorMessage name="stage">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideHospitalType}>
                <Form.Group controlId="hospital_type">
                  <Form.Label>Tipo hospital</Form.Label>
                  <Form.Control
                    as="select"
                    name="hospital_type"
                    value={values.hospital_type}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "hospital_type"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="CON 24/7 CON ATC PLUS">CON 24/7 CON ATC PLUS</option>
                    <option value="CON 24/7 MANUAL">CON 24/7 MANUAL</option>
                    <option value="SIN 24/7 CON ATC PLUS">SIN 24/7 CON ATC PLUS</option>
                    <option value="SIN 24/7 MANUAL">SIN 24/7 MANUAL</option>
                  </Form.Control>
                  <ErrorMessage name="hospital_type">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideConciergeAssigned}>
                <Form.Group controlId="concierge_assigned">
                  <Form.Label>Concierge asignado</Form.Label>
                  <Form.Control
                    as="select"
                    name="concierge_assigned"
                    value={values.concierge_assigned}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "concierge_assigned"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="NA">No cuenta con concierge</option>
                    {concierges?.map((concierge, idx) => (
                      <option key={idx} value={concierge.id}>
                        {concierge.conciergeName}
                      </option>
                    ))}
                  </Form.Control>
                  <ErrorMessage name="concierge_assigned">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideAtcNumber}>
                <Form.Group controlId="atcplus_folio">
                  <Form.Label>Folio ATC PLUS</Form.Label>
                  <Form.Control
                    type="text"
                    name="atcplus_folio"
                    value={values.atcplus_folio}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "atcplus_folio"
                    )}`}
                  />
                  <ErrorMessage name="atcplus_folio">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideComprehensiveCare}>
                <Form.Group controlId="comprehensiveCare">
                  <Form.Label>Cuidado Integral</Form.Label>
                  <Form.Control
                      as="select"
                      name="comprehensiveCare"
                      value={values.comprehensiveCare}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "comprehensiveCare"
                      )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="si">Si</option>
                    <option value="no">No</option>
                  </Form.Control>
                  <ErrorMessage name="comprehensiveCare">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideDiffDays}>
                <Form.Group controlId="dias_estancia">
                  <Form.Label>Dias Estancia</Form.Label>
                  <Form.Control
                    as="select"
                    name="dias_estancia"
                    value={values.dias_estancia}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "dias_estancia"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="<1">{"<1"}</option>
                    <option value=">2">{">2"}</option>
                    <option value=">10">{">10"}</option>
                  </Form.Control>
                  <ErrorMessage name="dias_estancia">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideAmount}>
                <Form.Group controlId="montos">
                  <Form.Label>Montos</Form.Label>
                  <Form.Control
                    as="select"
                    name="montos"
                    value={values.montos}
                    onChange={handleChange}
                    className={`${getInputClasses(
                      touched,
                      errors,
                      "montos"
                    )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="< $500,000">{"< $500,000"}</option>
                    <option value="> $500,000">{"> $500,000"}</option>
                  </Form.Control>
                  <ErrorMessage name="montos">
                    {(msg) => (
                      <Form.Control.Feedback type="invalid">
                        {msg}
                      </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col hidden={props.hideRegion}>
                <Form.Group controlId="region">
                  <Form.Label>Región</Form.Label>
                  <Form.Control
                      as="select"
                      name="region"
                      value={values.region}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "region"
                      )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    <option value="Región I">Región I</option>
                    <option value="Región II">Región II</option>
                    <option value="Región Pendiente">Región Pendiente</option>
                  </Form.Control>
                  <ErrorMessage name="region">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>

              <Col hidden={props.hideCell}>
                <Form.Group controlId="celula">
                  <Form.Label>Célula</Form.Label>
                  <Form.Control
                      as="select"
                      name="celula"
                      value={values.celula}
                      onChange={handleChange}
                      className={`${getInputClasses(
                          touched,
                          errors,
                          "celula"
                      )}`}
                  >
                    <option value="">SELECCIONA UNA OPCIÓN...</option>
                    {cells?.map( (cell, idx) => (
                        <option key={idx} value={cell.cellDescription}>{cell.cellDescription}</option>
                    ))}
                  </Form.Control>
                  <ErrorMessage name="celula">
                    {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                    )}
                  </ErrorMessage>
                </Form.Group>
              </Col>
            </Row>
            
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  variant="dark"
                  size="sm"
                />
              )}
              {props.textButton}
            </Button>

            {!props.hideButtonCreateCase && <Button className="ml-2" variant="primary" onClick={props.createCase}>
              Nueva solicitud
            </Button>}

            <button hidden={props.hideUpdatePageBtn} onClick={refresh} className="btn btn-primary ml-2">Actualizar</button>
          </Form>
        </>
      )}
    </Formik>
  );
}

GeneralFilter.defaultProps = {
  hidePeriodEqual: true,
  hideReason: true,
  hideStatusPatient: true,
  hideHospitalType: true,
  hideConciergeAssigned: true,
  hideDiffDays: true,
  hideAmount: true,
  hideRequiresTabulation: true,
  hideInssuredName: false,
  hideUpdatePageBtn: true,
  hideButtonCreateCase: true,
  hideAxaCategoryId: false,
  hideInsurance: false,
  hideHospital: false,
  hideTypePolicy: false,
  hideCategoryVip: false,
  hideHospitalInvoice: false,
  hidePresumptiveDiagnosis: false,
  hidePeriodFrom: false,
  hidePeriodTo: false,
  hideMovementType: false,
  hideAtcNumber: false,
  hideRegion: true,
  hideCell: true,
  hideComprehensiveCare: false,
  hideIdentificator: true,
  hideAtentionIden: true,
  textButton: "Filtrar",
}

export default GeneralFilter;
