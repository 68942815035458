import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Button,
  Form,
  Spinner,
  Card,
  Row,
  Col,
  Table,
  Accordion,
} from "react-bootstrap";
import moment from "moment";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { Divider } from "@material-ui/core";
import { getAllByCase, save } from "@services/documentsService";
import { GetUser } from "@metronic/_helpers";
import { shallowEqual, useSelector } from "react-redux";

import BlockCaseContext from "@contexts/BlockCaseContext";
import {isNotEmpty} from "@utils/checkObjectEmpty";

export const Documentation = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Documentación
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
          <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentDocumentation {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentDocumentation = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        Documentacion
      </Alert>

      <Card>
        <Card.Body>
          <FormDocumentation {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

const FormDocumentation = (props) => {
  const { data } = props;
  const { slug } = props.match?.params || props;

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("El campo es requerido")
      .test('fileFormat', 'Solo se permiten archivos PDF', (value) => {
        return value && ['application/pdf'].includes(value.type);
      }),
    document_type: Yup.string()
      .required("El campo es requerido"),
  });

  const [userAuth] = useState({ ...GetUser().user });
  const [, setShouldBlockCase] = useContext(BlockCaseContext);
  const [documents, setDocuments] = useState([]);

  const onSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setTimeout(() => {
      let formData = new FormData();
      formData.append("file", values.file);
      formData.append("user_id", userAuth.idUser);
      formData.append("document_type", values.document_type);
      formData.append("slug", slug);
      save(formData)
        .then( response => {
          getAllByCase(data.id).then(data => setDocuments(data.data));
          setShouldBlockCase(false);
          setInitialDocument({ file: "", document_type: ""});
          resetForm();
          setInputKey(Math.random().toString(36));
        })
        .catch( err => setStatus(`Ocurrio algun error al guardar`));
      setSubmitting(false);
    }, 1000);
  }

  useEffect(() => {
    if (isNotEmpty(data)) {
      getAllByCase(data.id).then(data => setDocuments(data.data));
    }
  }, [data]);

  const [initialDocument, setInitialDocument] = useState({ file: "", document_type: ""});

  const typesDocument = props.typesDocument || [
    "Aviso de Accidente", "Carta de Autorización", "Comprobante de seguro o poliza", "Denuncia ante MP",
    "Desvíos Aplicados", "Estudios de Gabinete", "Estudios de Laboratorio", "Estudios que Confirmen DX",
    "Historia Clinica", "Honorarios Médicos", "Identificacion", "Informe Medico Actualizado", "Nota de Evolución",
    "Nota Postquirurgica", "Resumen Medico Actualizado", "Otro",
  ];

  const [inputKey, setInputKey] = useState("");

  const {token} = useSelector(
    ({auth}) => ({
        token: auth.authToken,
    }),
    shallowEqual
  );

  if (props.attachOptions) {
    const other = typesDocument.pop()
    // typesDocument.unshift(other);
    typesDocument.push(...props.typesDocumentToAttach)
    typesDocument.push(other);
  }

  const downloadPDF = (e, urlFile) => {
    e.preventDefault();
    fetch(urlFile, {
      headers: {
        'Authorization': `Bearer ${token}`, 
      }
    })
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.click();
      });
    })
  }

  return (
    <>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Documento</th>
            <th>Documento</th>
            <th>Pagina</th>
            <th>Usuario</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {documents?.map( (document, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{document.documentType}</td>
              <td><a href={document.url} onClick={(e) => {downloadPDF(e, document.url)}} target="_blank" rel="noopener noreferrer">Ver</a></td>
              <td>{document.user.userName}</td>
              <td>{moment(document.createdAt).format('DD/MM/YYYY HH:mm:ss A')}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Divider className="mt-5 mb-5" />
      <Formik initialValues={ initialDocument } enableReinitialize={true} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({values, errors, touched, handleChange, handleSubmit, setFieldValue, status, isSubmitting}) => (
          <>
            <AlertError status={status} />

            <Form onSubmit={handleSubmit}>
              <Row hidden={props.disabledAll}>
                <Col>
                  <Form.Group controlId="file">
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control
                      key={inputKey || ''}
                      disabled={props.disabledAll}
                      type="file"
                      name="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className={`${getInputClasses(touched, errors, "file")}`}
                    />
                    <ErrorMessage name="file">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="document_type">
                    <Form.Label>Tipo de Documento</Form.Label>
                    <Form.Control
                      disabled={props.disabledAll}
                      as="select"
                      name="document_type"
                      value={values.document_type}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "document_type"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {typesDocument.map( (typeDocument, idx) => (
                        <option key={idx} value={typeDocument}>{typeDocument}</option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="document_type">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Button variant="primary" type="submit" disabled={isSubmitting} hidden={props.disabledAll}>
                {isSubmitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    variant="dark"
                    size="sm"
                  />
                )}
                Subir Documento
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default Documentation;
