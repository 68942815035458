export function isEmpty(obj) {
  for (let prop in obj) {
      if(obj.hasOwnProperty(prop))
          return false;
  }
  return true;
}

export function isNotEmpty(obj) {
    return !isEmpty(obj)
}