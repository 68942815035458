//React, Bootstrap & Others
import React, {useState, useEffect, Fragment} from "react";
import { Alert, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import moment from "moment";

//Metronic
import { useSubheader } from "@metronic/layout";

//Components
import GeneralFilter from "@components/GeneralFilter";
import GeneralTable from "@components/GeneralTable";

//Contexts
import CaseContext from "@contexts/CaseContext";

//Services
import { getAllControlBoardByFilter } from "@services/casesService";

export function ControlBoard() {
  const subheader = useSubheader();
  subheader.setTitle("Volumen General");

  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

  const [data, setData] = useState({});

  const [showRegionDictum, setShowRegionDictum] = useState(false);
  const [showRegionDictumIndex, setShowRegionDictumIndex] = useState(null);
  const [showCellDictumIndex, setShowCellDictumIndex] = useState(null);

  const [showRegionCostControl, setShowRegionCostControl] = useState(false);
  const [showRegionCostControlIndex, setShowRegionCostControlIndex] = useState(null);
  const [showCellCostControlIndex, setShowCellCostControlIndex] = useState(null);

  const [showRegionControlDesk, setShowRegionControlDesk] = useState(false);
  const [showRegionControlDeskIndex, setShowRegionControlDeskIndex] = useState(null);
  const [showCellControlDeskIndex, setShowCellControlDeskIndex] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({});

  const location = useLocation();

  useEffect(() => {
    const body = { pathName: location.pathname };
    getAllCasesControlBoard(body);
    
  }, []);

  const getAllCasesControlBoard = (body) => {
    getAllControlBoardByFilter(body).then((response) => {
      setData(response);
    });
  }

  const showTable = (cases, params) => {
    setTableParams(params);
    setTableData(cases);
  }

  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Volumen General
      </Alert>

      <CaseContext.Provider value={[data, setData]}>
        <GeneralFilter
          getCasesOwnFunc={getAllControlBoardByFilter}
          controlBoard={true}
          hideHospitalInvoice={true}
          hideInsurance={true}
          hideTypePolicy={true}
          hideAxaCategoryId={true}
          hideInssuredName={true}
          hidePresumptiveDiagnosis={true}
          hideCategoryVip={true}
          hideAtcNumber={true}
          hideRegion={false}
          hideCell={false}
          hideUpdatePageBtn={false}
        />
      </CaseContext.Provider>

      <Table responsive striped bordered hover className="mt-5">
        <thead>
          <tr>
            <th rowSpan={2} style={{"width": "190px"}}>Modulo</th>
            <th rowSpan={2} style={{"width": "190px"}}>Region</th>
            <th rowSpan={2} style={{"width": "190px"}}>Celula</th>
            <th rowSpan={2}>Hospital</th>
            <th rowSpan={2}>Usuario</th>
            <th colSpan={4}>SLA Total Alta</th>
            <th colSpan={1}></th>
            <th colSpan={4}>SLA Modulo</th>
            <th rowSpan={2}>Total</th>
            <th rowSpan={2}>
              Fecha / Hora Asignacion del Caso (DD/MM/AAAA HH:MM:SS)
            </th>
          </tr>
          <tr>
            <th>
              <i className="fas fa-flag" style={{ color: "red" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "yellow" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "green" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "gray" }}></i>
            </th>

            <th></th>

            <th>
              <i className="fas fa-flag" style={{ color: "red" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "yellow" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "green" }}></i>
            </th>
            <th>
              <i className="fas fa-flag" style={{ color: "gray" }}></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <button className="btn btn-link">
                <i
                    className="fas fa-plus"
                    onClick={(e) => setShowRegionControlDesk(!showRegionControlDesk)}
                ></i>
              </button>
              Mesa de Control
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.control_desk?.red_upper || "0"}</td>
            <td>{data.control_desk?.yellow_upper || "0"}</td>
            <td>{data.control_desk?.green_upper || "0"}</td>
            <td>{data.control_desk?.gray_upper || "0"}</td>
            <th></th>
            <td>{data.control_desk?.red_module || "0"}</td>
            <td>{data.control_desk?.yellow_module || "0"}</td>
            <td>{data.control_desk?.green_module || "0"}</td>
            <td>{data.control_desk?.gray_module || "0"}</td>
            <td>{data.control_desk?.total || "0"}</td>
            <td></td>
          </tr>
          {showRegionControlDesk &&
              Object.keys(data.control_desk_cases).sort().map((regionNameKey, idxRegion) => (
                  <Fragment key={idxRegion}>
                    <tr>
                      <td></td>
                      <td>
                        <button className="btn btn-link">
                          <i className="fas fa-plus"
                             onClick={(e) => {
                               if (showRegionControlDeskIndex === idxRegion) {
                                 setShowRegionControlDeskIndex(null);
                               } else {
                                 setShowRegionControlDeskIndex(idxRegion);
                               }
                             }}
                          ></i>
                        </button>
                        {regionNameKey}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.control_desk_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.control_desk_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.total, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                    </tr>
                    {showRegionControlDeskIndex === idxRegion &&
                        Object.keys(data.control_desk_cases[regionNameKey]).sort(collator.compare)
                            .map((cellNameKey, idxCell) => (
                                <Fragment key={idxCell}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <button className="btn btn-link">
                                        <i className="fas fa-plus"
                                           onClick={(e) => {
                                             if (showCellControlDeskIndex === idxCell) {
                                               setShowCellControlDeskIndex(null);
                                             } else {
                                               setShowCellControlDeskIndex(idxCell);
                                             }
                                           }}
                                        ></i>
                                      </button>
                                      {cellNameKey}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_upper, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_upper, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_upper, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_upper, 0) }</td>
                                    <td></td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_module, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_module, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_module, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_module, 0) }</td>
                                    <td>{ data.control_desk_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.total, 0) }</td>
                                    <td></td>
                                  </tr>

                                  {showCellControlDeskIndex === idxCell &&
                                      data.control_desk_cases[regionNameKey][cellNameKey].sort( (a,b) => b.flags.total - a.flags.total)
                                          .map((doctor, idxDoctor) => (
                                              <Fragment key={idxDoctor  }>
                                                <tr onClick={(e) => {
                                                  showTable(doctor.cases, {
                                                    urlToGo: "/mesa_control_detalles/",
                                                    reason: "Dictum",
                                                    assignationColumn: "feaccostos",
                                                    currModule: "Control de Costos",
                                                  });
                                                }}>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <th>{doctor.name}</th>
                                                  <td></td>
                                                  <td>{doctor.flags?.red_upper || "0"}</td>
                                                  <td>{doctor.flags?.yellow_upper || "0"}</td>
                                                  <td>{doctor.flags?.green_upper || "0"}</td>
                                                  <td>{doctor.flags?.gray_upper || "0"}</td>
                                                  <th></th>
                                                  <td>{doctor.flags?.red_module || "0"}</td>
                                                  <td>{doctor.flags?.yellow_module || "0"}</td>
                                                  <td>{doctor.flags?.green_module || "0"}</td>
                                                  <td>{doctor.flags?.gray_module || "0"}</td>
                                                  <td>{doctor.flags?.total || "0"}</td>
                                                  <td></td>
                                                </tr>
                                              </Fragment>
                                          ))
                                  }
                                </Fragment>
                            ))}
                  </Fragment>
              ))
          }

          <tr>
            <td>
              <button className="btn btn-link">
                <i
                  className="fas fa-plus"
                  onClick={(e) => setShowRegionDictum(!showRegionDictum)}
                ></i>
              </button>
              Dictamen
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.medical_opinion?.red_upper || "0"}</td>
            <td>{data.medical_opinion?.yellow_upper || "0"}</td>
            <td>{data.medical_opinion?.green_upper || "0"}</td>
            <td>{data.medical_opinion?.gray_upper || "0"}</td>
            <th></th>
            <td>{data.medical_opinion?.red_module || "0"}</td>
            <td>{data.medical_opinion?.yellow_module || "0"}</td>
            <td>{data.medical_opinion?.green_module || "0"}</td>
            <td>{data.medical_opinion?.gray_module || "0"}</td>
            <td>{data.medical_opinion?.total || "0"}</td>
            <td></td>
          </tr>
          {showRegionDictum &&
              Object.keys(data.medical_opinion_cases).sort().map((regionNameKey, idxRegion) => (
                  <Fragment key={idxRegion}>
                    <tr>
                      <td></td>
                      <td>
                        <button className="btn btn-link">
                          <i className="fas fa-plus"
                             onClick={(e) => {
                               if (showRegionDictumIndex === idxRegion) {
                                 setShowRegionDictumIndex(null);
                               } else {
                                 setShowRegionDictumIndex(idxRegion);
                               }
                             }}
                          ></i>
                        </button>
                        {regionNameKey}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.medical_opinion_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.total, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                    </tr>
                    {showRegionDictumIndex === idxRegion &&
                        Object.keys(data.medical_opinion_cases[regionNameKey]).sort(collator.compare)
                            .map((cellNameKey, idxCell) => (
                              <Fragment key={idxCell}>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <button className="btn btn-link">
                                      <i className="fas fa-plus"
                                         onClick={(e) => {
                                           if (showCellDictumIndex === idxCell) {
                                             setShowCellDictumIndex(null);
                                           } else {
                                             setShowCellDictumIndex(idxCell);
                                           }
                                         }}
                                      ></i>
                                    </button>
                                    {cellNameKey}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_upper, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_upper, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_upper, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_upper, 0) }</td>
                                  <td></td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_module, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_module, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_module, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_module, 0) }</td>
                                  <td>{ data.medical_opinion_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.total, 0) }</td>
                                  <td></td>
                                </tr>

                                {showCellDictumIndex === idxCell &&
                                    data.medical_opinion_cases[regionNameKey][cellNameKey].sort( (a,b) => b.flags.total - a.flags.total)
                                        .map((doctor, idxDoctor) => (
                                          <Fragment key={idxDoctor  }>
                                            <tr onClick={(e) => {
                                              showTable(doctor.cases, {
                                                urlToGo: "/control_costos_detalles/",
                                                reason: "Dictum",
                                                assignationColumn: "feaccostos",
                                                currModule: "Control de Costos",
                                              });
                                            }}>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <th>{doctor.hospital}</th>
                                              <td>{doctor.name}</td>
                                              <td>{doctor.flags?.red_upper || "0"}</td>
                                              <td>{doctor.flags?.yellow_upper || "0"}</td>
                                              <td>{doctor.flags?.green_upper || "0"}</td>
                                              <td>{doctor.flags?.gray_upper || "0"}</td>
                                              <th></th>
                                              <td>{doctor.flags?.red_module || "0"}</td>
                                              <td>{doctor.flags?.yellow_module || "0"}</td>
                                              <td>{doctor.flags?.green_module || "0"}</td>
                                              <td>{doctor.flags?.gray_module || "0"}</td>
                                              <td>{doctor.flags?.total || "0"}</td>
                                              <td>
                                                {moment.utc(doctor.last_assignation_date_dictum).format("DD/MM/YYYY H:mm:ss A")}
                                              </td>
                                            </tr>
                                          </Fragment>
                                      ))
                                }
                              </Fragment>
                            ))}
                  </Fragment>
              ))
          }

          <tr>
            <td>
              <button
                className="btn btn-link"
                onClick={(e) => setShowRegionCostControl(!showRegionCostControl)}
              >
                <i className="fas fa-plus"></i>
              </button>
              Control de Costos
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.cost_control?.red_upper || "0"}</td>
            <td>{data.cost_control?.yellow_upper || "0"}</td>
            <td>{data.cost_control?.green_upper || "0"}</td>
            <td>{data.cost_control?.gray_upper || "0"}</td>
            <th></th>
            <td>{data.cost_control?.red_module || "0"}</td>
            <td>{data.cost_control?.yellow_module || "0"}</td>
            <td>{data.cost_control?.green_module || "0"}</td>
            <td>{data.cost_control?.gray_module || "0"}</td>
            <td>{data.cost_control?.total || "0"}</td>
            <td></td>
          </tr>
          {showRegionCostControl &&
              Object.keys(data.cost_control_cases).sort().map((regionNameKey, idxRegion) => (
                  <Fragment key={idxRegion}>
                    <tr>
                      <td></td>
                      <td>
                        <button className="btn btn-link">
                          <i className="fas fa-plus"
                             onClick={(e) => {
                               if (showRegionCostControlIndex === idxRegion) {
                                 setShowRegionCostControlIndex(null);
                               } else {
                                 setShowRegionCostControlIndex(idxRegion);
                               }
                             }}
                          ></i>
                        </button>
                        {regionNameKey}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_upper, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.red_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.yellow_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.green_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.gray_module, 0)
                              }, 0)
                        }
                      </td>
                      <td>
                        {
                          Object.keys(data.cost_control_cases[regionNameKey])
                              .reduce((acc, cellNameKey) => {
                                return acc + data.cost_control_cases[regionNameKey][cellNameKey].reduce((accNested, object) => accNested + object.flags?.total, 0)
                              }, 0)
                        }
                      </td>
                      <td></td>
                    </tr>
                    {showRegionCostControlIndex === idxRegion &&
                        Object.keys(data.cost_control_cases[regionNameKey]).sort(collator.compare)
                            .map((cellNameKey, idxCell) => (
                                <Fragment key={idxCell}>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <button className="btn btn-link">
                                        <i className="fas fa-plus"
                                           onClick={(e) => {
                                             if (showCellCostControlIndex === idxCell) {
                                               setShowCellCostControlIndex(null);
                                             } else {
                                               setShowCellCostControlIndex(idxCell);
                                             }
                                           }}
                                        ></i>
                                      </button>
                                      {cellNameKey}
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_upper, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_upper, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_upper, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_upper, 0) }</td>
                                    <td></td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.red_module, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.yellow_module, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.green_module, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.gray_module, 0) }</td>
                                    <td>{ data.cost_control_cases[regionNameKey][cellNameKey].reduce((acc, object) => acc + object.flags?.total, 0) }</td>
                                    <td></td>
                                  </tr>

                                  {showCellCostControlIndex === idxCell &&
                                      data.cost_control_cases[regionNameKey][cellNameKey].sort( (a,b) => b.flags.total - a.flags.total)
                                          .map((doctor, idxDoctor) => (
                                              <Fragment key={idxDoctor  }>
                                                <tr onClick={(e) => {
                                                  showTable(doctor.cases, {
                                                    urlToGo: "/control_costos_detalles/",
                                                    reason: "Dictum",
                                                    assignationColumn: "feaccostos",
                                                    currModule: "Control de Costos",
                                                  });
                                                }}>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <th>{doctor.hospital}</th>
                                                  <td>{doctor.name}</td>
                                                  <td>{doctor.flags?.red_upper || "0"}</td>
                                                  <td>{doctor.flags?.yellow_upper || "0"}</td>
                                                  <td>{doctor.flags?.green_upper || "0"}</td>
                                                  <td>{doctor.flags?.gray_upper || "0"}</td>
                                                  <th></th>
                                                  <td>{doctor.flags?.red_module || "0"}</td>
                                                  <td>{doctor.flags?.yellow_module || "0"}</td>
                                                  <td>{doctor.flags?.green_module || "0"}</td>
                                                  <td>{doctor.flags?.gray_module || "0"}</td>
                                                  <td>{doctor.flags?.total || "0"}</td>
                                                  <td>
                                                    {moment.utc(doctor.last_assignation_date_cost_control).format("DD/MM/YYYY H:mm:ss A")}
                                                  </td>
                                                </tr>
                                              </Fragment>
                                          ))
                                  }
                                </Fragment>
                            ))}
                  </Fragment>
              ))
          }
          <tr>
            <td>Seguimiento</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.tracing?.red_upper || "0"}</td>
            <td>{data.tracing?.yellow_upper || "0"}</td>
            <td>{data.tracing?.green_upper || "0"}</td>
            <td>{data.tracing?.gray_upper || "0"}</td>
            <th></th>
            <td>{data.tracing?.red_module || "0"}</td>
            <td>{data.tracing?.yellow_module || "0"}</td>
            <td>{data.tracing?.green_module || "0"}</td>
            <td>{data.tracing?.gray_module || "0"}</td>
            <td>{data.tracing?.total || "0"}</td>
            <td></td>
          </tr>
          <tr>
            <td>Informacion Medica</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{data.medic_information?.red_upper || "0"}</td>
            <td>{data.medic_information?.yellow_upper || "0"}</td>
            <td>{data.medic_information?.green_upper || "0"}</td>
            <td>{data.medic_information?.gray_upper || "0"}</td>
            <th></th>
            <td>{data.medic_information?.red_module || "0"}</td>
            <td>{data.medic_information?.yellow_module || "0"}</td>
            <td>{data.medic_information?.green_module || "0"}</td>
            <td>{data.medic_information?.gray_module || "0"}</td>
            <td>{data.medic_information?.total || "0"}</td>
            <td></td>
          </tr>
        </tbody>
      </Table>

      <GeneralTable
        urlToGo={tableParams.urlToGo}
        query={'?from=control_board'}
        data={tableData}
        activePage={1}
        totalItemsCount={tableData.length}
        hideReloadButton={true}
        reason={tableParams.reason}
        assignationColumn={tableParams.assignationColumn}
        currModule={tableParams.currModule}
      />
    </>
  );
}
