import axios from "axios";

export const GET_ALL = "nurses";
export const SAVE = "nurses";
export const UPDATE = "nurses";
export const DELETE = "nurses";


export let getAll = function (status) {
  return axios.get(`${GET_ALL}`);
}

export let save = function (data) {
  return axios.post(SAVE, data);
}

export function update(data, id) {
  return axios.put(`${UPDATE}/${id}`, data);
}

export function destroy(id) {
  return axios.delete(`${DELETE}/${id}`);
}