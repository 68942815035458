import { put, takeLatest, call } from "redux-saga/effects";

import { actionsBinnacles, actionTypesBinnacles } from "../actions/binnacles";
import { getAllByCase, getAllByCasePageable, save as storeBinnacle} from "@services/binnaclesService";

export function* binnaclesSaga() {
    yield takeLatest(actionTypesBinnacles.FETCH_ALL_BY_CASE, function* fetchAll(action) {
        const response = yield call(getAllByCase, action.payload);

        yield put(actionsBinnacles.getAllByCase(response.data));
    });

    yield takeLatest(actionTypesBinnacles.FETCH_ALL_BY_CASE_PAGEABLE, function* fetchAllPageable(action) {
        const response = yield call(getAllByCasePageable, action.payload.caseId, action.payload.pageNumber);

        yield put(actionsBinnacles.getAllByCase(response.data));
    });

    yield takeLatest(actionTypesBinnacles.STORE, function* store(action) {
        const { data, caseId, slug, isPageable } = action.payload;

        yield call(storeBinnacle, data, slug);

        if (isPageable) {
            yield put(actionsBinnacles.fetchAllByCasePageable(caseId));
        } else {
            yield put(actionsBinnacles.fetchAllByCase(caseId));
        }
    });
}