import axios from "axios";

export const GET_ALL = "roles";
export const GET_BY_ID = "roles/";
export const SAVE = "roles";
export const UPDATE = "roles";
export const DELETE = "roles";

export function getAll() {
    return axios.get(GET_ALL);
}

export function getById(id) {
    return axios.get(GET_BY_ID + id);
}

export function save(data) {
    return axios.post(SAVE, data);
}

export function update(data) {
    return axios.put(`${UPDATE}/${data.idRole}`, data);
}

export function destroy(roleId) {
    return axios.delete(`${DELETE}/${roleId}`);
}