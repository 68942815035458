import React, {useEffect} from "react";
import { useSubheader } from "@metronic/layout";
import { Alert } from "react-bootstrap";

const Reconsideration = () => {
  const subheader = useSubheader();

  useEffect(() => {
    subheader.setTitle("Reconsideraciones");
  }, []);
  
  return (
    <>
      <Alert
        variant="success"
        className="text-center"
        style={{ fontSize: "16px" }}
      >
        Reconsideraciones 
      </Alert>
    </>
  );
};

export default Reconsideration;
