import axios from "axios";
import { IfHasRoleByLocalStorage } from "../../../_metronic/_helpers";

export const BASE_CASES = "cases";

export const COUNT_CASES = `${BASE_CASES}/count_cases`;
export const GET_ALL_TODAY_STATUS = `${BASE_CASES}/getCasesByStatuses`;
export const GET_ALL_LOCKED_TODAY_STATUS = `${BASE_CASES}/getCasesLockedByStatuses`;
export const GET_ALL_TODAY = `${BASE_CASES}/getAllToday`;
export const GET_ALL_FILTER = `${BASE_CASES}/filter`;
export const GET_ALL_FILTER_BOARD_CONTROL = `control_board/count`;
export const GET_ALL_FILTER_BOARD_CONTROL_DICTUM = `control_board/dictum_count`;
export const GET_ALL_FILTER_BOARD_CONTROL_COST_CONTROL = `control_board/cost_control_count`;

export const getAllControlBoardByFilter = async function (body) {
  let response = await axios.post(`${GET_ALL_FILTER_BOARD_CONTROL}`, body);
  return new Promise( (resolve, reject) => resolve(response.data));
}

export const getAllControlBoardDictumByFilter = async function (body) {
  let response = await axios.post(`${GET_ALL_FILTER_BOARD_CONTROL_DICTUM}`, body);
  return new Promise( (resolve, reject) => resolve(response.data));
}

export const getAllControlBoardCostControlByFilter = async function (body) {
  let response = await axios.post(`${GET_ALL_FILTER_BOARD_CONTROL_COST_CONTROL}`, body);
  return new Promise( (resolve, reject) => resolve(response.data));
}


export const getCasesByStatuses = async function (statuses, pageNumber, params = []) {
  let response = await axios.get(`${GET_ALL_TODAY_STATUS}?statuses=${statuses}&page=${pageNumber || ''}${params.toString().replaceAll(',', '')}`);
  let data = getResponseFormat(response);
  return new Promise( (resolve, reject) => resolve(data));
}

export const getAllTodayByStatuses = async function (statuses, pageNumber) {
  let response = await axios.get(`${GET_ALL_TODAY_STATUS}?statuses=${statuses}&page=${pageNumber || ''}`);
  let data = getResponseFormat(response);
  return new Promise( (resolve, reject) => resolve(data));
}

export const getCasesLockedByStatus = async function (statuses, pageNumber, params = []) {
  let response = await axios.get(`${GET_ALL_LOCKED_TODAY_STATUS}?statuses=${statuses}&page=${pageNumber || ''}${params.toString().replaceAll(',', '')}`);
  let data = getResponseFormat(response);
  return new Promise( (resolve, reject) => resolve(data));
}

export const getAllToday = async function (pageNumber, params = []) {
  let response = await axios.get(`${GET_ALL_TODAY}?page=${pageNumber || ''}${params.toString().replaceAll(',', '')}`);
  let data = getResponseFormat(response);
  return new Promise( (resolve, reject) => resolve(data));
}

export const getAllByFilter = async function (body, statuses, page, params = []) {
  let response = await axios.post(`${GET_ALL_FILTER}?statuses=${statuses}&page=${page}${params.toString().replaceAll(',', '')}`, body);
  let data = getResponseFormat(response);
  return new Promise( (resolve, reject) => resolve(data));
}

export const getModulesCounter = async function () {
  var params = [];

  if (IfHasRoleByLocalStorage("ADMIN_REGION_CELULA")) {
    params = ['?byRegion=true', '&byCell=true'];
  }

  let response = await axios.get(`${COUNT_CASES}${params.toString().replaceAll(',', '')}`);
  return response.data;
}

const getResponseFormat = (response) => {
  return {
    data: response.data.content,
    totalPages: response.data.totalPages,
    totalElements: response.data.totalElements,
    size: response.data.size
  };
}
