import { shallowEqual, useSelector } from "react-redux";

export const IfHasPermission = (permission) => {
    const {permissions} = useSelector(
        ({auth}) => ({
            permissions: auth.permissions,
        }),
        shallowEqual
    );
    return permissions?.some(item => item.name === permission);
}

export const IfHasRole = (role) => {
    const {roleStored} = useSelector(
        ({auth}) => ({
            roleStored: auth.user.role,
        }),
        shallowEqual
    );
    return roleStored.name === role;
}

export const IfHasRoleByLocalStorage = (role) => {
  return JSON.parse(localStorage.getItem("persist:atcplus-auth"))?.roleName?.replaceAll('"', '') === role;
}

export const GetUser = () => {
    const {user} = useSelector(
        ({auth}) => ({
            user: auth,
        }),
        shallowEqual
    );
    return user;
}
