//React, Bootstrap & Others
import React from "react";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import swal from 'sweetalert';
import {Button, Modal, Form, Spinner} from "react-bootstrap";

//Services
import {saveConcierge, updateConcierge} from "@services/conciergeService";

//Components
import AlertError from "@components/AlertError"

//Utils
import {getInputClasses} from "@utils/getInputclasses";

const FormConcierge = (props) => {
    const validationSchema = Yup.object().shape({
        conciergeName: Yup.string()
            .required("El campo es requerido"),
    });

    const onSubmit = (values, { setStatus, setSubmitting }) => {
        setTimeout(() => {
            if (props.shouldUpdate) {
                updateConcierge(values, props.concierge.id).then(response => {
                    props.getConcierges();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al actualizar");
                });
            } else {
                values.isActive = 1;
                saveConcierge(values).then(response => {
                    props.getConcierges();
                    props.handleClose();
                    swal("Guardado Exitoso", "Se ha guardado", "success");
                }).catch( () => {
                    setSubmitting(false);
                    setStatus("Ocurrio algun error al guardar");
                });
            }
        }, 1000);
    };

    return (
        <>
            <Modal show={props.showModal} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.shouldUpdate ? "Editar" : "Agregar"} concierge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik initialValues={{...props.concierge}} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({values, errors, touched, handleChange, handleSubmit, status, isSubmitting}) => (
                            <>
                                <AlertError status={status} />
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="conciergeName">
                                        <Form.Label>Nombre completo (apellidos primero) *</Form.Label>
                                        <Form.Control type="text"
                                                      value={values.conciergeName}
                                                      onChange={handleChange}
                                                      name="conciergeName"
                                                      placeholder="Escribe el nombre"
                                                      className={`${getInputClasses(touched, errors, "conciergeName")}`} />
                                        <ErrorMessage name="conciergeName">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="phone">
                                        <Form.Label>Telefono</Form.Label>
                                        <Form.Control type="text"
                                                      value={values.phone || ''}
                                                      onChange={handleChange}
                                                      name="phone"
                                                      placeholder="Escribe el telefono"
                                                      className={`${getInputClasses(touched, errors, "phone")}`} />
                                        <ErrorMessage name="phone">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="mobile">
                                        <Form.Label>Celular</Form.Label>
                                        <Form.Control type="text"
                                                      value={values.mobile || ''}
                                                      onChange={handleChange}
                                                      name="mobile"
                                                      placeholder="Escribe el celular"
                                                      className={`${getInputClasses(touched, errors, "mobile")}`} />
                                        <ErrorMessage name="mobile">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="email">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email"
                                                      value={values.email || ''}
                                                      onChange={handleChange}
                                                      name="email"
                                                      placeholder="Escribe el email"
                                                      className={`${getInputClasses(touched, errors, "email")}`} />
                                        <ErrorMessage name="email">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="city">
                                        <Form.Label>Ciudad</Form.Label>
                                        <Form.Control type="text"
                                                      value={values.city || ''}
                                                      onChange={handleChange}
                                                      name="city"
                                                      placeholder="Escribe la ciudad"
                                                      className={`${getInputClasses(touched, errors, "city")}`} />
                                        <ErrorMessage name="city">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Form.Group controlId="category">
                                        <Form.Label>Categoria</Form.Label>
                                        <Form.Control as="select"
                                                      value={values.category || ''}
                                                      onChange={handleChange}
                                                      name="category"
                                                      className={`${getInputClasses(touched, errors, "category")}`}>
                                            <option value="">Selecciona una opcion...</option>
                                            <option value="VIP">VIP</option>
                                        </Form.Control>
                                        <ErrorMessage name="category">
                                            { msg => (
                                                <Form.Control.Feedback type="invalid">
                                                    {msg}
                                                </Form.Control.Feedback>
                                            )}
                                        </ErrorMessage>
                                    </Form.Group>

                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting && <Spinner as="span" animation="border" variant="dark" size="sm" />}
                                        Guardar
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default FormConcierge;
