import moment from "moment";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { ModalInsuranceMedicalFee } from "./ModalInsuranceMedicalFee";

export const ListMedicalFees = ({ medicalFees, getMedicalFees, hideBtnInsurance = true, ...props }) => {

  const [showModal, setShowModal] = useState(false);
  const [medicalFee, setMedicalFee] = useState({});
  
  const handleEditMedicalFee = (e, currMedicalFee) => {
    e.preventDefault();
    setMedicalFee(currMedicalFee);
    setShowModal(true)
  }

  const handleClose = () => setShowModal(false);

  return (
    <>
      <ModalInsuranceMedicalFee
        showModal={showModal}
        handleCloseModal={handleClose}
        medicalFee={medicalFee}
        getMedicalFees={getMedicalFees}
        {...props}
      />
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th hidden={hideBtnInsurance}>Opciones</th>
            <th>Nombre del medico</th>
            <th>Especialidad</th>
            <th>Rol</th>
            <th>Fecha del procedimiento / visita</th>
            <th>Numero de visitas</th>
            <th hidden={props.hideCptTabulate}>CPT a tabular</th>
            <th>Fecha Hora (DD/MM/AAAA HH:MM:SS)</th>
            <th hidden={props.hideStatus}>Estatus</th>
          </tr>
        </thead>
        <tbody>
          {medicalFees?.map( (medicalFee, idx) => (
            <tr key={idx} hidden={medicalFee.comment === ""}>
              <td hidden={hideBtnInsurance}>
                <Button variant="success" onClick={(e) => handleEditMedicalFee(e, medicalFee)}>
                  <i className="fas fa-eye"></i>
                </Button>
              </td>
              <td>{medicalFee.medicName}</td>
              <td>{medicalFee.speciality}</td>
              <td>{medicalFee.rol}</td>
              <td>{medicalFee.processDate}</td>
              <td>{medicalFee.visitsNumber}</td>
              <td hidden={props.hideCptTabulate}>{medicalFee.cptTabulate}</td>
              <td>{moment(medicalFee.createdAt).format('DD/MM/YYYY HH:mm:ss A')}</td>
              <td hidden={props.hideStatus}>{medicalFee.statusTab}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
