export const getInputClasses = (touched, errors, fieldname) => {
    if (touched[fieldname] && errors[fieldname]) {
        return "is-invalid";
    }

    if (touched[fieldname] && !errors[fieldname]) {
        return "is-valid";
    }

    return "";
};
