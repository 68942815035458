import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Form,
  Card,
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import * as Yup from "yup";
import axios from "axios";
import moment from "moment";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { update } from "@services/hospitalizationcrud";
import { getAll } from "@services/axaService";
import {AxaCategoriesOthersNotVisible, AxaCategoriesVip} from "@helpers/constants";

export const InssuredData = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Datos Asegurado
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
          <i className={`fa ${props.icone}`} style={{position:"absolute", right:"0", marginRight:"35px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentInssuredData {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentInssuredData = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center"
        style={{ fontSize: "14px" }}
      >
        Datos Asegurado
      </Alert>

      <Card>
        <Card.Body>
          <FormInssuredData {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

export const FormInssuredData = (props) => {
  const [initialValues, setInitialValues] = useState ({
    assuredFlastname: props.data.assuredFlastname || "",
    policy: props.data.policy || "",
    assuredMlastname: props.data.assuredMlastname || "",
    clientCertificate: props.data.clientCertificate || "",
    assuredName: props.data.assuredName || "",
    atentionIden: props.data.atentionIden || "",
    assuredEmail: props.data.assuredEmail || "",
    identificator: props.data.identificator || "",
    assuredBrthdate: props.data.assuredBrthdate || "",
    opinionIden: props.data.opinionIden || "",
    incomeAreaId: props.data.incomeAreaId || "",
    incomeDatetime: props.data.incomeDatetime || "",
    hospitalIden: props.data.hospitalIden || "",
    room: props.data.room || "",
    symptom: props.data.symptom || "",
    diagnosis: props.data.diagnosis || "",
    vip: props.data.vip || "",
    axaCategories: props.data.axaCategories || '',
  });

  const [axas, setAxas] = useState([]);
  const [axaCategories] = useState([]);
  const [timestamp, setTimestamp] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [vip, setVip] = useState("No");
  const [categoryAxaEnabled, setCategoryAxaEnabled]= useState(true);
  const { id } = props.data;
  const { bindSubmitForm } = props;


  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");

  let curr = new Date();
  let todayDate = curr.toISOString().substr(0, 10);

  const handleSubmitForm = (values, { setStatus, setSubmitting }) => {
    update({ ...props.data, ...values }, id).then((response) =>
      console.log("Form inssured data inside saved")
    );
  };

  useEffect(() => {
    setInitialValues({
      assuredFlastname: props.data.assuredFlastname || "",
      policy: props.data.policy || "",
      assuredMlastname: props.data.assuredMlastname || "",
      clientCertificate: props.data.clientCertificate || "",
      assuredName: props.data.assuredName || "",
      atentionIden: props.data.atentionIden || "",
      assuredEmail: props.data.assuredEmail || "",
      identificator: props.data.identificator || "",
      assuredBrthdate: props.data.assuredBrthdate || "",
      opinionIden: props.data.opinionIden || "",
      incomeAreaId: props.data.incomeAreaId || "",
      incomeDatetime: props.data.incomeDatetime || "",
      hospitalIden: props.data.hospitalIden || "",
      room: props.data.room || "",
      symptom: props.data.symptom || "",
      diagnosis: props.data.diagnosis || "",
      vip: props.data.vip || "",
      axaCategories: props.data.axaCategories || '',
    });

    getAll().then((response) =>  setAxas(response.data?.filter(category => AxaCategoriesVip.includes(category.axaCatDescription) || AxaCategoriesOthersNotVisible.includes(category.axaCatDescription) )));
    axios.get('/cases/getTimestamp').then(response => setTimestamp(response.data));
    getPolicyVip();
    
    if (props.data.policy)
      setInput(props.data.policy);
  }, [props.data]);

  const validationSchema = Yup.object().shape({
    assuredFlastname: Yup.string()
        .required("El campo es requerido"),
    assuredName: Yup.string()
      .required("El campo es requerido"),
    assuredEmail: Yup.string()
      .required("El campo es requerido")
      .email("El campo no parece ser un email"),
    incomeDatetime: Yup.string()
      .required("El campo es requerido"),
    hospitalIden: Yup.string()
      .required("El campo es requerido"),
    diagnosis: Yup.string()
      .required("El campo es requerido"),
  });

  const getPolicyVip = async () => {
      let data = await axios.get(`/vip_policy`);
      setSuggestions(data.data);
  }

  const SuggestionsListComponent = ({ setFieldValue }) => {
    return filteredSuggestions.length ? (
      <ul className="suggestions">
        {filteredSuggestions.map((suggestion, index) => {
          let className;
  
          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active";
          }
  
          return (
            <li className={className} key={index}  onClick={e => {
              onClick(e, suggestion.value);
              setFieldValue("axaCategories", suggestion.source);
            }}>
              {suggestion.label}
            </li>
          );
        })}
      </ul>
    ) : (
      <div className="no-suggestions"></div>
    );
  }

  const onChange = (e) => {
    const userInput = e.target.value;
    
    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    setVip("No");
  }

  const onClick = (e, suggestion) => {
    setFilteredSuggestions([]);
    setInput(suggestion);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setVip("Si");
    setCategoryAxaEnabled(true);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ ...initialValues, axaCategoriesArray: axaCategories }}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        status,
        isSubmitting,
        submitForm,
        setFieldValue,
      }) => {
        bindSubmitForm(submitForm);
        return (
          <>
            <AlertError status={status} />
            <Form id="formu2" onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group id="" controlId="assuredFlastname">
                    <Form.Label>Apellido Paterno *</Form.Label>
                    <Form.Control
                      type="assuredFlastname"
                      name="assuredFlastname"
                      value={values.assuredFlastname}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "assuredFlastname"
                      )}`}
                    />
                    <ErrorMessage name="assuredFlastname">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="policy">
                    <input type="hidden" value={vip} name="vip" />
                    <Form.Label>Póliza </Form.Label>
                    <Form.Control
                      type="text"
                      name="policy"
                      value={input}
                      onChange={ e => {
                        onChange(e);
                        setFieldValue("axaCategories", "");
                      }}
                      disabled={props.disabledAll}
                      autoComplete="off"
                      maxLength="8"
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "policy"
                      )}`}
                    />
                    {showSuggestions && input && input.length >= 7 && <SuggestionsListComponent setFieldValue={setFieldValue} />}
                    <ErrorMessage name="policy">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="assuredMlastname">
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control
                      type="text"
                      name="assuredMlastname"
                      value={values.assuredMlastname}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "assuredMlastname"
                      )}`}
                    />
                    <ErrorMessage name="assuredMlastname">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="axaCategories">
                    <Form.Label>Tipo de poliza</Form.Label>
                    <Form.Control
                      as="select"
                      name="axaCategories"
                      value={values.axaCategories}
                      onChange={handleChange}
                      disabled={props.disabledAll || categoryAxaEnabled}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "axaCategories"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      {axas?.map((axa, idx) => (
                        <option key={idx} value={axa.axaCatDescription}>
                          {axa.axaCatDescription}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="axaCategories">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="assuredName">
                    <Form.Label>Nombre(s) *</Form.Label>
                    <Form.Control
                      type="name"
                      name="assuredName"
                      value={values.assuredName}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "assuredName"
                      )}`}
                    />
                    <ErrorMessage name="assuredName">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="assuredEmail">
                    <Form.Label>Correo Electrónico Asegurado *</Form.Label>
                    <Form.Control
                      type="email"
                      name="assuredEmail"
                      value={values.assuredEmail}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "assuredEmail"
                      )}`}
                    />
                    <ErrorMessage name="assuredEmail">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="assuredBrthdate">
                    <Form.Label>Fecha de Nacimiento</Form.Label>
                    <Form.Control
                      type="date"
                      name="assuredBrthdate"
                      value={values.assuredBrthdate}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      max={todayDate}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "assuredBrthdate"
                      )}`}
                    />
                    <ErrorMessage name="assuredBrthdate">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="incomeAreaId">
                    <Form.Label>Área de Ingreso</Form.Label>
                    <Form.Control
                      as="select"
                      name="incomeAreaId"
                      value={values.incomeAreaId}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "incomeAreaId"
                      )}`}
                    >
                      <option value="">SELECCIONA UNA OPCIÓN...</option>
                      <option value="1">Urgencias</option>
                      <option value="2">HOSPITALIZACIÓN</option>
                      <option value="3">Ambulatorio</option>
                    </Form.Control>
                    <ErrorMessage name="incomeAreaId">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="incomeDatetime">
                    <Form.Label>Fecha/ Hora Ingreso *</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      name="incomeDatetime"
                      value={values.incomeDatetime.substring(0, 19)}
                      onBlur={(e) => {
                        let min = moment(timestamp).locale("es").subtract(30, "days").format().substring(0, 19);
                        let max = moment(timestamp).locale("es").add(1, "days").format().substring(0, 19);
                        let currentDateSelected = moment(e.target.value);
                        if (currentDateSelected.isSameOrAfter(min) && currentDateSelected.isSameOrBefore(max)) {
                          handleChange(e);
                        } else {
                          alert("Ingresa una fecha valida");
                          e.target.value = "";
                          handleChange(e);
                        }
                      }}
                      onChange={(e) => handleChange(e)}
                      min={moment(timestamp).locale("es").subtract(30, "days").format().substring(0, 19)}
                      max={moment(timestamp).locale("es").format().substring(0, 19)}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "incomeDatetime"
                      )}`}
                    />
                    <ErrorMessage name="incomeDatetime">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="hospitalIden">
                    <Form.Label>Folio Hospital / Episodio *</Form.Label>
                    <Form.Control
                      type="text"
                      name="hospitalIden"
                      value={values.hospitalIden}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "hospitalIden"
                      )}`}
                    />
                    <ErrorMessage name="hospitalIden">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="room">
                    <Form.Label>Cuarto</Form.Label>
                    <Form.Control
                      type="text"
                      name="room"
                      value={values.room}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(touched, errors, "room")}`}
                    />
                    <ErrorMessage name="room">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="symptom">
                    <Form.Label>Síntoma</Form.Label>
                    <Form.Control
                      type="text"
                      name="symptom"
                      value={values.symptom}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "symptom"
                      )}`}
                    />
                    <ErrorMessage name="symptom">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="diagnosis">
                    <Form.Label>DIAGNÓSTICO Presuntivo *</Form.Label>
                    <Form.Control
                      type="text"
                      name="diagnosis"
                      value={values.diagnosis}
                      onChange={handleChange}
                      disabled={props.disabledAll}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "diagnosis"
                      )}`}
                    />
                    <ErrorMessage name="diagnosis">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
