import axios from "axios";

export const GET_ALL = "hospitals";
export const SAVE = "hospitals";
export const UPDATE = "hospitals";
export const DELETE = "hospitals";


export let getAll = function () {
    return axios.get(GET_ALL);
}

export let save = function (data) {
    return axios.post(SAVE, data);
}

export function update(data, id) {
    return axios.put(`${UPDATE}/${id}`, data);
}

export function destroy(id) {
    return axios.delete(`${DELETE}/${id}`);
}