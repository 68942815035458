import axios from "axios";

export const GET_ALL = "binnacles";
export const GET_ALL_BY_CASE = `${GET_ALL}/getAllByCase`;
export const GET_ALL_BY_CASE_PAGEABLE = `${GET_ALL}/getAllByCasePageable`;
export const SAVE = "binnacles";


export let getAll = function () {
    return axios.get(GET_ALL);
}

export let getAllByCase = function (caseId) {
    return axios.get(`${GET_ALL_BY_CASE}/${caseId}`);
}

export let getAllByCasePageable = function (caseId, pageNumber) {
    return axios.get(`${GET_ALL_BY_CASE_PAGEABLE}/${caseId}?size=5&page=${pageNumber || ''}`);
}

export let save = function (data, slug) {
    return axios.post(`${SAVE}?slug=${slug}`, data);
}

